import { numComma } from "../../../../utils";

import { Button, Col, Row } from "react-bootstrap";

import { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../../../env";
import OrderItem from "./OrerItem";
import { useSelector } from "react-redux";

export default function Orders() {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const accessToken = useSelector((e) => e.user.accessToken);

  const getData = async () => {
    const res = await axios.get(`${apiUrl}/api/v1/purchase/my`, {
      validateStatus: false,
      headers: { Authorization: accessToken },
    });

    // await new Promise((resolve) => setTimeout(resolve, 3000)).then(() => {
    //   console.log("1 second passed");
    // });

    // console.log(res);

    if (res.data) {
      setOrders(res.data.purchaseOrders);
    }
  };

  return (
    <div className="">
      {orders.map((e) => (
        <Order purchase={e} key={e._id} />
      ))}
    </div>
  );
}

const Order = ({ purchase: p }) => {
  return (
    // <div
    //   className="border border-dark rounded p-2 p-md-3 m-1 m-md-2"
    //   style={{ flex: 1 }}
    // >
    //   <div style={{}} className="d-flex gap-2">
    //     <p className="h4" style={{}}>
    //       {p.title}
    //     </p>
    //     <PencilFill style={{ display: "inline" }} />
    //   </div>

    //   <div></div>
    //   <p className="h6 text-muted">id: {p.eid}</p>

    //   <SectionTitle>Summary:</SectionTitle>
    //   <div className="d-flex gap-2 py-2">
    //     <Amount
    //       title="Total:"
    //       disabledAmount={p.cost.grossTotal}
    //       amount={p.cost.netTotal}
    //     />
    //     <Amount title="Paid:" disabledAmount={null} amount={0} />
    //     <Amount title="Due:" disabledAmount={null} amount={p.cost.netTotal} />
    //   </div>
    //   <SectionTitle>Products:</SectionTitle>
    //   <div className="">
    //     <div>
    //       {p.cartItems.map((cartItem) => (
    //         <Product cartItem={cartItem} />
    //       ))}
    //     </div>
    //   </div>
    //   {/* <SectionTitle>Payments:</SectionTitle>
    //   <Payments>
    //     <Payment id="1" description="delivery charge paid" amount={120} />
    //     <Payment id="2" description="" amount={1200} />
    //     <Payment
    //       id="3"
    //       description="sdf aasdf sdfa sdfasdf asdfa sdfasdf asdf asdf asd asfgasg"
    //       amount={1200}
    //     />
    //   </Payments> */}

    //   <SectionTitle>Status:</SectionTitle>
    //   <Status status_name={p.status} />
    //   {/* <SectionTitle>Actions:</SectionTitle>
    //   <div className="gap-1" style={{ display: "flex", flexWrap: "wrap" }}>
    //     <ActionButton>Change Product</ActionButton>
    //     <ActionButton>Pay Now</ActionButton>
    //     <ActionButton>OrderItem</ActionButton>
    //     <ActionButton>Cancel</ActionButton>
    //   </div> */}
    // </div>
    <div className="" style={{ flex: 1 }}>
      <OrderItem orderData={p} />
    </div>
  );
};

const ActionButton = ({ children }) => {
  return (
    <Button
      style={{ whiteSpace: "nowrap", textAlign: "left" }}
      className="border border-dark flex1"
    >
      {children}
    </Button>
  );
};

const SectionTitle = ({ children }) => {
  const spacing = "1.2rem";
  return (
    <div className="" style={{ paddingTop: spacing }}>
      <div
        className=""
        style={{
          borderTop: "1px solid rgba(0,0,0, 0.1)",
          marginBottom: `-${spacing}`,
        }}
      ></div>

      <p
        style={{ backgroundColor: "white", width: "fit-content" }}
        className="pe-1 h5"
      >
        {children}
      </p>
    </div>
  );
};

const Payments = ({ children }) => {
  if (!children) {
    return <div>The partial payment feature is coming soon</div>;
  }
  return (
    <div className="d-flex gap-1 my-1 " style={{ flexWrap: "wrap" }}>
      {children}
    </div>
  );
};
