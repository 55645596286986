import * as actionNames from "./actionNames";

import { apiUrl } from "../../../env";
import axios from "axios";

export const login = (email, phone, password) => async (dispatch) => {
  dispatch({
    type: actionNames.login.pending,
  });

  const url = `${apiUrl}/api/v1/user/login`;
  const body = { password };
  if (email) body.email = email;
  //   else if (phone) body.phone = phone;
  const res = await axios.post(url, body, { validateStatus: false });
  console.log(res);
  if ((res.status = 200)) {
    return dispatch({
      type: actionNames.login.success,
      payload: {
        user: res.data.user,
        accessToken: res.data.accessToken,
      },
    });
  }
  return dispatch({
    type: actionNames.login.fail,
    payload: {},
  });
};

export const verifyAccessToken = (accessToken) => async (dispatch) => {
  dispatch({
    type: actionNames.verifyAccessToken.pending,
  });

  const url = `${apiUrl}/api/v1/user/me`;

  const res = await axios.get(url, {
    validateStatus: false,
    headers: { Authorization: accessToken },
  });
  console.log(res);
  if ((res.status = 200)) {
    return dispatch({
      type: actionNames.verifyAccessToken.success,
      payload: {
        user: res.data.user,
        accessToken: accessToken,
      },
    });
  }

  return dispatch({
    type: actionNames.verifyAccessToken.fail,
    payload: {},
  });
};

export const register =
  (email, phoneNumber, phoneNumberCountryCode, password) =>
  async (dispatch) => {
    dispatch({
      type: actionNames.register.pending,
    });

    const url = `${apiUrl}/api/v1/user/register`;
    const body = { password };
    if (email) body.email = email;
    if (phoneNumber)
      body.phone = {
        phoneNumber: phoneNumber,
        countryCode: phoneNumberCountryCode,
      };
    const res = await axios.post(url, body, { validateStatus: false });
    console.log(res);
    if ((res.status = 200)) {
      return dispatch({
        type: actionNames.register.success,
        payload: {
          user: res.data.user,
          accessToken: res.data.accessToken,
        },
      });
    }
    return dispatch({
      type: actionNames.register.fail,
      payload: {},
    });
  };
