import axios from "axios";
import * as actionNames from "./actionNames";
import {
  checkCouponValidity,
  getCouponSupportedProducts,
  arrayToObj,
  fetchShippingData,
} from "./utils";
import { wpUrl, consumer_key, consumer_secret, apiUrl } from "../../../env";
import { toast } from "react-toastify";
export const addToCart = (id, slug, productData) => (dispatch) => {
  dispatch({
    type: actionNames.addToCart,
    payload: {
      id,
      productData,
      slug,
    },
  });
};

export const removeFromCart = (id, slug) => (dispatch) => {
  dispatch({
    type: actionNames.removeFromCart,
    payload: {
      id,
      slug,
    },
  });
};

export const updateCartQuantity = (id, slug, quantity) => (dispatch) => {
  dispatch({
    type: actionNames.updateCartQuantity,
    payload: {
      id,
      slug,
      quantity,
    },
  });
};

export const addCoupon =
  (code, fullState, callBack = () => {}) =>
  async (dispatch) => {
    // fetch the coupon from the backend
    dispatch({
      type: actionNames.addCoupon.pending,
      payload: { code },
    });
    const url = `${wpUrl}/wp-json/wc/v3/coupons?consumer_key=${consumer_key}&consumer_secret=${consumer_secret}`;

    const res = await axios.get(url, {
      params: {
        code,
      },
      validateStatus: false,
    });
    console.log(res);
    if (res.data && res.data.length < 1) {
      toast.warn("Invalid Coupon", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // transition: "Bounce",
      });
      return dispatch({
        type: actionNames.addCoupon.fail.invalid_coupon,
        payload: { errorMessage: "invalid coupon", code },
      });
    }
    const coupon = res.data[0];

    // validation - if no coupon found or limit or date expired throw error = return

    // coupon validation
    const validCoupon = checkCouponValidity(coupon);
    if (!validCoupon.success) {
      return dispatch({ type: actionNames.addCoupon.fail.error, payload: {} });
    }

    dispatch({ type: actionNames.addCoupon.success, payload: { coupon } });
    return callBack();
  };

export const unSelectCoupon = (code) => (dispatch) => {
  console.log("unselect coupon");
  dispatch({ type: actionNames.unSelectCoupon, payload: { code } });
};

export const selectCoupon = (couponCode) => (dispatch) => {
  return dispatch({
    type: actionNames.selectCoupon,
    payload: { code: couponCode },
  });
};

export const getShippingAddresses = () => async (dispatch) => {
  dispatch({ type: actionNames.getShippingAddresses.pending });

  const shippingAddresses = await fetchShippingData();

  shippingAddresses.forEach((e, i) => {
    const searchKey = Object.values(e).join("").toLocaleLowerCase();
    shippingAddresses[i].searchKey = searchKey;
  });

  dispatch({
    type: actionNames.getShippingAddresses.success,
    payload: { data: { ...arrayToObj(shippingAddresses, "_id") } },
  });
};

export const selectShippingAddress = (address) => (dispatch) => {
  dispatch({ type: actionNames.selectShippingAddress, payload: { address } });
};

// export const saveShippingAddresses = (query, addressList) => (dispatch) => {
//   dispatch({
//     type: actionNames.saveShippingAddresses,
//     payload: { query, addresses: addressList },
//   });
// };

export const updateCustomShippingAddress = (text) => (dispatch) => {
  dispatch({
    type: actionNames.updateCustomShippingAddress,
    payload: { text },
  });
};

export const updateContactInfo = (obj) => (dispatch) => {
  dispatch({ type: actionNames.updateContactInfo, payload: { ...obj } });
};
const delay = (ms) => new Promise((res) => setTimeout(res, ms));
export const createPurchaseOrder = (store) => async (dispatch) => {
  const body = {
    cartItems: [
      ...Object.values(store.order.cartItems)
        .map((cartItem) => ({
          wcProductId: cartItem.productData.wcid
            ? cartItem.productData.wcid
            : cartItem.productData.id,
          // wcProductId: cartItem.productData._id || cartItem.productData.id,
          quantity: cartItem.quantity,
        }))
        .filter((e) => e.quantity > 0),
    ],
    locationId: "642eadf548ebe39a7680ac1d",
    locationDirection: store.order.shippingAddress.customAddress,
    coupon:
      store.order.charges.totalCouponDiscount > 0
        ? store.order.coupon.selectedCouponCode
        : undefined,
    contactInfo: {
      ...store.order.contactInfo,
      name: store.order.contactInfo.fullName,
    },
  };

  dispatch({
    type: actionNames.createPurchaseOrder.pending,
    payload: { purchaseOrderData: body },
  });

  // await delay(5000);

  const res = await axios.post(`${apiUrl}/api/v1/purchase/`, body, {
    validateStatus: false,
  });

  console.log("createPurchaseOrder");
  console.log(res);

  if (res.status >= 200 && res.status < 300) {
    return dispatch({
      type: actionNames.createPurchaseOrder.success,
      payload: { purchase: res.data.purchase },
    });
  }

  dispatch({ type: actionNames.createPurchaseOrder.fail, payload: {} });
};

export const updateOrderNote = (text) => (dispatch) => {
  dispatch({ type: actionNames.updateOrderNote, payload: { text } });
};
