const defaultSelectedSort = "default";
const defaultSelectedFilter = "default";

const newOrder = (data, pageNumber) => {
  return {
    time: new Date(),
    noMoreItemsLeft: false,
    selectedPage: 1,
    dataByPage: {
      [pageNumber | 1]: {
        data: data ? [...data] : [],
        fetchedAt: new Date(),
        loading: true,
        pageNumber: pageNumber | 1,
      },
    },
  };
};

const newFilter = (data) => {
  return {
    selectedSort: defaultSelectedSort,
    dataByOrder: {
      [defaultSelectedSort]: newOrder(data, 1),
    },
  };
};

const newQuery = (data) => {
  return {
    selectedFilter: defaultSelectedFilter,
    dataByFilter: {
      [defaultSelectedFilter]: newFilter(data),
    },
  };
};

export const objCreator = {
  newOrder,
  newFilter,
  newQuery,
};

export default { objCreator };
