import * as actionNames from "./actionNames";
import axios from "axios";

import { wpUrl, consumer_key, consumer_secret } from "../../../env";

export const addToVisitedProduct = (productData) => async (dispatch) => {
  dispatch({
    type: actionNames.addToVisitedProduct,
    payload: {
      id: productData.id,
      slug: productData.slug,
      productData: productData,
    },
  });
};

export const getProductWithSlug = (slug) => async (dispatch) => {
  if (!slug) return;
  const url = `${wpUrl}/wp-json/wc/v3/products?consumer_key=${consumer_key}&consumer_secret=${consumer_secret}&slug=${slug}`;

  dispatch({ type: actionNames.getProductWithSlug.pending, payload: { slug } });

  const res = await axios.get(url);
  console.log(res);

  if (res.status == 200) {
    return dispatch({
      type: actionNames.getProductWithSlug.success,
      payload: { productData: res.data[0], slug: slug },
    });
  }

  return dispatch({
    type: actionNames.getProductWithSlug.fail,
    payload: {},
  });
};

export const getRelatedProducts = (productIds, slug) => async (dispatch) => {
  const url = `${wpUrl}/wp-json/wc/v3/products?consumer_key=${consumer_key}&consumer_secret=${consumer_secret}`;

  dispatch({ type: actionNames.getRelatedProducts.pending, payload: { slug } });

  const res = await axios.get(url, {
    params: {
      include: productIds.join(","),
    },
  });
  console.log(res);

  if (res.status == 200) {
    return dispatch({
      type: actionNames.getRelatedProducts.success,
      payload: { products: res.data, slug },
    });
  }

  return dispatch({
    type: actionNames.getRelatedProducts.fail,
    payload: {},
  });
};
