import actions from "../../../store/actions";
import selectors from "../../../store/selectors";

import { useSelector, useDispatch } from "react-redux";

const borderRadius = "3px";

export const AddToCart = ({ product, disabled, className }) => {
  const dispatch = useDispatch();

  const { id, slug } = product;

  return (
    <button
      className={"btn bg-theme text-white " + " " + className}
      style={{ borderRadius }}
      onClick={() => {
        dispatch(actions.order.addToCart(id, slug, product));
        dispatch(actions.productPage.addToVisitedProduct(product));
      }}
      disabled={disabled}
    >
      Add To Cart
    </button>
  );
};

export const AddedToCart = ({
  product,
  remove = {},
  increment = {},
  reduction = {},
  textSize = 1,
  className,
}) => {
  const dispatch = useDispatch();

  const { id, slug } = product;

  const currentQuantity = selectors.order.getCartQuantity(
    useSelector((e) => e),
    slug
  );

  const increase = () => {
    dispatch(actions.order.updateCartQuantity(id, slug, currentQuantity + 1));
  };

  const decrease = () => {
    if (currentQuantity <= 1) {
      dispatch(actions.order.removeFromCart(id, slug));
    } else {
      dispatch(actions.order.updateCartQuantity(id, slug, currentQuantity - 1));
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center CartButton"
      style={{ gap: "20px" }}
    >
      <button
        className={"btn bg-theme text-white " + className}
        style={{ borderRadius }}
        onClick={decrease}
      >
        {currentQuantity <= 1
          ? remove.label || "Remove"
          : reduction.label || "-"}
      </button>
      <p style={{ fontSize: textSize * 1.5 + "rem" }}>{currentQuantity}</p>
      <button
        className={"btn bg-theme text-white " + className}
        style={{ borderRadius }}
        onClick={increase}
      >
        {increment.label || "+"}
      </button>
    </div>
  );
};

export default function CartButton({ product, ...otherProps }) {
  const { id, slug } = product;
  const addedToCart = useSelector(
    (state) =>
      state.order.cartItems[slug] && state.order.cartItems[slug].quantity > 0
  );

  return addedToCart ? (
    <AddedToCart product={product} {...otherProps} />
  ) : (
    <AddToCart product={product} {...otherProps} />
  );
}
