import { Check } from "react-bootstrap-icons";
import { Accordion } from "react-bootstrap";

export default function ({ title = "no title", subTitle = "", status = "" }) {
  return (
    <div>
      <h2 className="text-center mt-3 text-theme">Your Checkout Info</h2>
      <div className="flex gap-1 flex-wrap text-white">
        <div className="w-full border p-2 my-2 bg-theme rounded-md flex justify-between">
          <span>Your selected Location</span> <span>X</span>
        </div>
        <div className="w-full border p-2 my-2 bg-theme rounded-md flex justify-between">
          <span>Your selected Location</span> <span>X</span>
        </div>
        <div className="w-full border p-2 my-2 bg-theme rounded-md flex justify-between">
          <span>Your selected Location</span> <span>X</span>
        </div>
      </div>
    </div>
  );
}
