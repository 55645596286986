import style from "./spinner.module.css";

export default function Spinner({}) {
  return (
    <div className="" style={{ width: `2rem`, height: `2rem` }}>
      <div className={style.one + " "} style={{}}>
        <div className={style.two + " "}>
          <div
            className="BB"
            style={{
              width: `1rem`,
              height: `1rem`,
              left: `1rem`,
              top: "-2px",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
