import { Col, Row } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

import s from "./index.module.css";

import {
  TelephoneFill,
  Facebook,
  Instagram,
  Envelope,
  Whatsapp,
  PersonFill,
  HouseDoorFill,
  BagCheckFill,
  ListColumns,
  ListColumnsReverse,
  CardChecklist,
  CartCheckFill,
} from "react-bootstrap-icons";

import { useNavigate } from "react-router-dom";
export default function Footer() {
  //   const navigate = useNavigate();
  // const topNavBarHeight = 104;
  // const bottomNavBarHeight = 72;

  // console.log(shit.clientHeight);

  return (
    <div className="bg-dark">
      <div className="container pt-3 pb-1">
        <Row className="">
          <Col className="col-12 col-md-12 col-lg-3 pt-4">
            <Heading>About Us</Heading>
            <p
              // className="mt-5"
              style={{ textAlign: "justify", lineHeight: "1.8rem" }}
            >
              Love Gadgets? So do we. Here at Gearmade, we have a passion and
              reputation of selling hundreds of unique and different gadget
              reputation of selling hundreds of unique and different gadget
            </p>
          </Col>

          <Col className="col-12 col-md-6 col-lg-3 pt-4">
            <Heading style={{ textAlign: "" }}>Contact Us</Heading>
            <div className="">
              <div
              //   style={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <LinkItem
                  label="+8801736004336"
                  icon="TelephoneFill"
                  url="tel:+8801736004336"
                  newTab={true}
                />

                <LinkItem
                  label="+8801736004336"
                  icon="Whatsapp"
                  url={`https://wa.me/+8801736004336?text=${encodeURIComponent(
                    "hello"
                  )}`}
                  newTab={true}
                />

                <LinkItem
                  label="/gearmadebd"
                  icon="Facebook"
                  url="https://www.facebook.com/gearmadebd"
                  newTab={true}
                />

                <LinkItem
                  label="/gearmadebd"
                  icon="Instagram"
                  url="https://www.instagram.com/gearmadebd/"
                  newTab={true}
                />

                <LinkItem
                  label="gearmadebd@gmail.com"
                  icon="Envelope"
                  url="mailto:gearmadebd@gmail.com"
                  newTab={true}
                />
              </div>
            </div>
          </Col>

          <Col className="col-12 col-md-6 col-lg-3 pt-4">
            <Heading style={{ textAlign: "" }}>Links</Heading>

            <div>
              <LinkItem url="/" icon="HouseDoorFill" label="Home" />
              <LinkItem
                url="/checkout/shipping"
                icon="CartCheckFill"
                label="Checkout"
              />
              <LinkItem url="/orders" icon="BagCheckFill" label="My Orders" />
              <LinkItem url="/profile" icon="PersonFill" label="Profile" />
              <LinkItem
                url="/terms-and-conditions"
                icon="ListColumnsReverse"
                label="Terms & Conditions"
              />
            </div>
          </Col>

          <Col className="col-12 col-md-12 col-lg-3 pt-4">
            <Heading>Location</Heading>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20646.582532973254!2d90.41442837718722!3d23.824478882581168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c75a3fad180b%3A0x7cb948ffc485062!2sGearmade!5e0!3m2!1sen!2sbd!4v1682413786725!5m2!1sen!2sbd"
              width="100%"
              height="200px"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Col>
        </Row>
        <div
          className="d-flex justify-content-center py-1 container-md"
          style={{ borderTop: "2px solid rgba(150,150,150,0.5)" }}
        >
          <div>
            © 2021 Gearmade BD | All rights reserved | Developed By Ebas Tech
          </div>
        </div>
      </div>
    </div>
  );
}

// const LinkItem = ({ children, url }) => {
//   return (
//     <div>
//       <Link to={url}>{children}</Link>
//     </div>
//   );
// };

const Heading = ({ children }) => {
  return <h3 style={{ fontSize: "1.3rem" }}>{children}</h3>;
};

const LinkItem = ({ label, url, icon, children, newTab }) => {
  const Icons = {
    TelephoneFill: TelephoneFill,
    Facebook: Facebook,
    Instagram: Instagram,
    Envelope: Envelope,
    Whatsapp: Whatsapp,

    PersonFill: PersonFill,
    HouseDoorFill: HouseDoorFill,
    BagCheckFill: BagCheckFill,
    CardChecklist: CardChecklist,
    CartCheckFill: CartCheckFill,
    ListColumns: ListColumns,
    ListColumnsReverse: ListColumnsReverse,
  };
  const IconComponent = Icons[icon];
  const externalUrl = url?.startsWith("http");

  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        // window.open(url, "_blank");
        if (newTab) {
          window.open(url);
        } else if (externalUrl) {
          window.location.href = url;
        } else {
          navigate(url);
        }
      }}
      className={`d-flex align-items-center gap-2 cursor-pointer ${s.contactItem} BB`}
    >
      {IconComponent && <IconComponent />}
      <p className="">{children ?? label}</p>
    </div>
  );
};
