import * as actionNames from "./actionNames";

import { objCreator } from "./utils";
import { localstore as localstoreInit } from "./../../../utils";
// let initialStateFromStorage = localStorage.getItem("store.searchProducts");
// try {
//   initialStateFromStorage = JSON.parse(initialStateFromStorage);
// } catch (ex) {
//   initialStateFromStorage = null;
// }

const localstore = localstoreInit("store__searchProducts");
let initialStateFromStorage = localstore.getItem();
// const saveItem = (newSate) => {
//   try {
//     localStorage.setItem("store.searchProducts", JSON.stringify(newSate));
//   } catch (ex) {
//     localStorage.removeItem("store.searchProducts");
//   }
// };

// const saveItem = (newState) => {
//   localstore.saveItem(newState);
// };

const initialState = {
  products: {
    selectedQuery: "",
    productsByQuery: {
      // "<query>": {
      //   selectedFilter: "",
      //   dataByFilter: {
      //     "<filter>": {
      //       selectedSort: "",
      //       dataByOrder: {
      //         "<sort-name>": {
      //           time: "<date>",
      //           noMoreLeft: false,
      //           pageSize: null,
      //           selectedPage: 1,
      //           dataByPage: {
      //             "<page-number>": {
      //               data: [],
      //               fetchedAt: "<date>",
      //               loading: false,
      //             },
      //           },
      //         },
      //       },
      //     },
      //   },
      // },
    },
  },
  // shit: true,
  searchLogs: [
    {
      keyword: "",
      time: "",
    },
  ],
  redirectOnBlank: {
    path: "",
    redirect: false,
  },
};

export default function (
  state = initialStateFromStorage || initialState,
  // state = initialStateFromStorage,
  action = { payload: {}, type: null }
) {
  // console.log(action);
  const newState = { ...state };

  switch (action.type) {
    case actionNames.search.pending:
      var { query, pageNumber, data } = action.payload;
      // return { ...initialState };

      if (
        state.products.productsByQuery[query]?.dataByFilter["default"]
          ?.dataByOrder["default"].dataByPage[pageNumber]
      ) {
        const currentPageData =
          newState.products.productsByQuery[query].dataByFilter["default"]
            .dataByOrder["default"].dataByPage[pageNumber];

        // currentPageData.data = currentPageData.data;
        currentPageData.pageNumber = pageNumber;
        currentPageData.fetchedAt = new Date();

        localstore.saveItem({ ...newState });
        return {
          ...newState,
        };
      } else if (
        state.products.productsByQuery[query]?.dataByFilter["default"]
          ?.dataByOrder["default"]
      ) {
        const currentOrderData =
          newState.products.productsByQuery[query].dataByFilter["default"]
            .dataByOrder["default"];

        currentOrderData.dataByPage[pageNumber] = {
          data: [],
          fetchedAt: new Date(),
          loading: true,
          pageNumber: pageNumber | 1,
        };

        localstore.saveItem({ ...newState });
        return {
          ...newState,
        };
      } else if (
        state.products.productsByQuery[query]?.dataByFilter["default"]
          ?.dataByOrder["default"]
      ) {
        // when sorting functionality will be added
      } else if (
        state.products.productsByQuery[query]?.dataByFilter["default"]
      ) {
        // when filtering functionality will be added
      } else if (!state.products.productsByQuery[query]) {
        newState.products.productsByQuery[query] = {
          ...objCreator.newQuery(),
        };

        localstore.saveItem({ ...newState });
        return {
          ...newState,
        };
      }
    case actionNames.search.success:
      var { query, pageNumber, data, noMoreItemsLeft } = action.payload;
      if (
        state.products.productsByQuery[query]?.dataByFilter["default"]
          ?.dataByOrder["default"]?.dataByPage[pageNumber]
      ) {
        const currentOrderData =
          newState.products.productsByQuery[query].dataByFilter["default"]
            ?.dataByOrder["default"];

        if (!currentOrderData.noMoreItemsLeft) {
          currentOrderData.noMoreItemsLeft = noMoreItemsLeft;
        }
        const currentPageData = currentOrderData?.dataByPage[pageNumber];

        currentPageData.data = [...data];
        currentPageData.fetchedAt = new Date();
        currentPageData.loading = false;
        currentPageData.pageNumber = pageNumber;

        // current;

        localstore.saveItem({ ...newState });
        return {
          ...newState,
        };
      } else if (
        state.products.productsByQuery[query]?.dataByFilter["default"]
          ?.dataByOrder["default"]
      ) {
        const currentOrderData =
          newState.products.productsByQuery[query].dataByFilter["default"]
            .dataByOrder["default"];

        currentOrderData.dataByPage[pageNumber] = {
          data: [...data],
          fetchedAt: new Date(),
          loading: false,
          pageNumber: pageNumber,
        };

        localstore.saveItem({ ...newState });
        return {
          ...newState,
        };
      }

    default:
      return {
        ...state,
      };
  }
}
