import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";
import { AnimatePresence } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  // browserHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as pages from "./components/pages";

import Footer from "./components/layout/Footer";
import Navbar from "./components/layout/MainNavbar";
// import Navbar from "./components/layout/Header";

import actions from "./store/actions";
import {
  Shipping,
  Coupon,
  UserInfo,
  Payment,
} from "./components/pages/Checkout";

// import TopNavbar from "./components/layout/TopNavbar";

function App() {
  const accessToken = useSelector((state) => state.user.accessToken);
  // console.log("{accessToken}");
  // console.log({ accessToken });

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(actions.init(accessToken));
  }, []);

  const [deviceType, setDeviceType] = React.useState("desktop");

  // const bottomNavBarHeight =
  //   document.getElementById("bottom-nav")?.clientHeight ?? 0;

  const [bottomNavBarHeight, setBottomNavBarHeight] = React.useState(0);
  // console.log({ bottomNavBarHeight });

  React.useEffect(() => {
    // Function to check the device type based on the window width
    setBottomNavBarHeight(
      document.getElementById("bottom-nav")?.clientHeight ?? 0
    );

    const checkDeviceType = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth < 768) {
        setDeviceType("mobile");
      } else if (windowWidth < 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };
    checkDeviceType();
    window.addEventListener("resize", checkDeviceType);
    return () => {
      window.removeEventListener("resize", checkDeviceType);
    };
  }, []);

  return (
    <div
      className="App"
      style={{
        // paddingTop: topNavBarHeight,
        paddingBottom: bottomNavBarHeight,
      }}
    >
      {/* <StyleRoot> */}
      <ToastContainer />
      <Router>
        <Navbar />
        {/* <TopNavbar /> */}
        <div className={"content-area"}>
          <AnimatePresence mode="wait">
            <Routes>
              <Route path="/" element={<pages.Home />} />
              <Route path="/gearskin" element={<pages.Gearskin />} />
              <Route path="/checkout" element={<pages.CheckoutMain />}>
                <Route path="shipping" element={<Shipping />} />
                <Route path="coupon" element={<Coupon />} />
                <Route path="userInfo" element={<UserInfo />} />
                <Route path="payment" element={<Payment />} />
              </Route>
              {/* <Route path="/checkout" element={<pages.Checkout />} /> */}
              <Route path="/hot-products" element={<pages.HotProducts />} />
              <Route path="/search/:query" element={<pages.Search />} />
              <Route path="/product/:slug" element={<pages.ProductPage />} />
              <Route
                path="/product-category/:slug"
                element={<pages.ProductCategory />}
              />

              <Route path="/orders" element={<pages.Profile />} />
              <Route path="/order/:orderId" element={<pages.Profile />} />
              <Route path="/payments" element={<pages.Profile />} />
              <Route path="/payment/:paymentId" element={<pages.Profile />} />
              <Route path="/profile" element={<pages.Profile />} />
              {/* <Route path="/invoice" element={<pages.Invoice />} /> */}
            </Routes>
          </AnimatePresence>
        </div>
        <Footer />
      </Router>
      {/* </StyleRoot> */}
    </div>
  );
}

export default App;
