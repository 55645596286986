import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Coupon from "./Coupon";
import Shipping from "./Shipping";
import UserInfo from "./UserInfo";
import Payment from "./Payment";
import NavigationTabs from "./NavigationTabs";
import { MdOutlineLocalShipping, MdOutlinePayment } from "react-icons/md";
import { RiCoupon2Line } from "react-icons/ri";
import { IoIosContact } from "react-icons/io";
import { useState, useEffect } from "react";
import OrderDetails from "./OrderDetail";
import YourCheckoutInfo from "./YourCheckoutInfo";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../common/Loading";
import actions from "../../../store/actions";

export default function CheckoutMain() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();

  const orderReducer = useSelector((s) => s.order);

  const [steps, setSteps] = useState({
    shipping: {
      name: "shipping",
      label: "Shipping",
      Icon: MdOutlineLocalShipping,
      url: "/checkout/shipping",
      completed: false,
      isActive: false,
      stepNumber: 1,
    },
    coupon: {
      name: "coupon",
      label: "Coupon",
      Icon: RiCoupon2Line,
      url: "/checkout/coupon",
      completed: false,
      isActive: false,
      stepNumber: 2,
    },
    customer: {
      name: "customer",
      label: "Customer",
      Icon: IoIosContact,
      url: "/checkout/userInfo",
      completed: false,
      isActive: false,
      stepNumber: 3,
    },
    payment: {
      name: "payment",
      label: "Payment",
      Icon: MdOutlinePayment,
      url: "/checkout/payment",
      completed: false,
      isActive: false,
      stepNumber: 4,
    },
  });

  const currentStep = Object.values(steps).find(
    (page) => page.url == location.pathname
  );
  const nextStep = Object.values(steps).find(
    (p) => p.stepNumber == currentStep?.stepNumber + 1
  );
  const prevStep = Object.values(steps).find(
    (p) => p.stepNumber == currentStep?.stepNumber - 1
  );

  useEffect(() => {
    const updatedSteps2 = { ...steps };

    updatedSteps2.shipping.isActive = false;
    updatedSteps2.coupon.isActive = false;
    updatedSteps2.customer.isActive = false;
    updatedSteps2.payment.isActive = false;

    if (updatedSteps2[currentStep.name]) {
      updatedSteps2[currentStep.name].isActive = true;
    }

    setSteps(updatedSteps2);
  }, [location]);

  useEffect(() => {
    const updatedSteps = { ...steps };

    // check for shipping step ----
    const shipping = orderReducer.shippingAddress;
    if (
      shipping.selectedAddressId &&
      shipping.allAddresses[shipping.selectedAddressId] &&
      shipping.customAddress
    ) {
      // console.log("step shipping completed");
      updatedSteps.shipping.completed = true;
    } else {
      updatedSteps.shipping.completed = false;
    }

    // check for coupon step ----
    const coupon = orderReducer.coupon;
    if (
      coupon.selectedCouponCode &&
      coupon.allCoupons[coupon.selectedCouponCode]
    ) {
      // console.log("step shipping completed");
      updatedSteps.coupon.completed = true;
    } else {
      updatedSteps.coupon.completed = false;
    }

    // check for customer step ----
    const user = orderReducer.contactInfo;
    if (user.fullName && (user.email || user.phone)) {
      // console.log("step shipping completed");
      updatedSteps.customer.completed = true;
    } else {
      updatedSteps.customer.completed = false;
    }

    setSteps(updatedSteps);
  }, [orderReducer]);

  // place order button
  const placingOrder = useSelector(
    (state) => state.order.actions.createPurchaseOrder.loading
  );
  const store = useSelector((e) => e);
  const cartItems = useSelector((state) =>
    Object.values(state.order.cartItems)
  ).sort((a, b) => (a.addedAt > b.addedAt ? -1 : 1));

  return (
    <div className="md:flex lg:flex p-4 gap-4 shadow-lg rounded-lg container">
      <div className="md:w-7/12 lg:w-7/12 mb-3">
        <div className="flex justify-center">
          <NavigationTabs steps={steps} />
        </div>

        <div className="my-4">
          <Outlet />
        </div>

        <div className="flex justify-between mt-4">
          {prevStep ? (
            <button
              className="px-4 py-2 rounded bg-theme text-white text-lg"
              onClick={() => {
                navigate(prevStep?.url);
              }}
            >
              {"<"} Prev
            </button>
          ) : (
            <div></div>
          )}
          {nextStep ? (
            <button
              className="px-4 py-3 rounded bg-theme text-white text-lg"
              onClick={() => {
                navigate(nextStep?.url);
              }}
            >
              Next {">"}
            </button>
          ) : (
            <div className="flex-1 ps-5">
              {placingOrder ? (
                <button
                  className="d-flex justify-content-center px-4 py-3 rounded bg-theme text-white w-full text-lg"
                  disabled={true}
                >
                  Placing your order
                  <Loading size="22px" color="white" />
                </button>
              ) : (
                <button
                  className="d-flex justify-content-center gap-1 px-4 py-3 rounded bg-theme text-white w-full text-lg"
                  onClick={() => {
                    dispatch(actions.order.createPurchaseOrder(store));
                  }}
                  disabled={cartItems.length == 0}
                >
                  Place Order
                  <span>&#x2713;</span>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className="flex-1 bg-slate-100 rounded-lg overflow-y-scroll px-3"
        style={{ height: "24rem" }}
      >
        <OrderDetails />
        <YourCheckoutInfo />
      </div>
    </div>
  );
}

export { Shipping, Coupon, UserInfo, Payment };
