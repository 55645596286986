import React from "react";
import Category from "./../../common/Category";
import { useDispatch, useSelector } from "react-redux";

import { params } from "./../../../utils";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Categories() {
  const featuredCategories = useSelector(
    (state) => state.categories.allCategories.data
  );

  return (
    <div className="px-3 row">
      {featuredCategories?.map((cat) => {
        const wide = cat.name.length > 20;
        return (
          <div className={`col-6 p-0`}>
            <Category.Item key={cat.name} category={cat} />
          </div>
        );
      })}
    </div>
  );
}
