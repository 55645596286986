import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import actions from "./../../../../store/actions";

import QuantityButton from "./Quantity";
import { numComma } from "../../../../utils";

export default function CartItem({ cartObj }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    quantity,
    grossTotal,
    totalCouponDiscount,
    netTotal,
    isCouponApplied,
    productData: product,
  } = cartObj;

  return (
    <div
      className={`d-flex p-1 m-1`}
      // style={{ width: "25%", boxSizing: "border-box" }}
      style={{
        zIndex: 1001,
        height: "130px",
        borderRadius: "5px",
        border: `1px solid ${
          isCouponApplied ? "#5cb4c2" : "rgba(200, 200, 200, 1)"
        }`,
      }}
    >
      <div className="d-flex" style={{ flex: 1 }}>
        <img
          src={product.images && product.images[0] && product.images[0].src}
          className=""
          alt=""
          height="100"
          width="100"
          style={{
            height: "100%",
            width: "auto",
            objectFit: "cover",
            aspectRatio: "1",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(`/product/${product.slug}`);
          }}
        />
        <div className="d-flex flex-column ms-1" style={{ flex: 1 }}>
          <div
            className=""
            style={{ flex: 1, marginBottom: "-15px" }}
            onClick={() => {
              navigate(`/product/${product.slug}`);
            }}
          >
            <p style={{ fontSize: "88%" }}>{product.name}</p>
          </div>
          <div className="d-flex justify-content-between align-items-end">
            <div className="">
              <div className="d-flex justify-content-between">
                <p style={{}} className="small">
                  Pay:{" "}
                  {isCouponApplied && grossTotal != 0 && (
                    <span
                      style={{
                        textDecoration: "line-through",
                        color: "red",
                        fontSize: "90%",
                      }}
                    >
                      {numComma(grossTotal)}
                    </span>
                  )}
                </p>
              </div>
              <div className="">
                <p
                  className=""
                  style={{
                    fontWeight: "bold",
                    fontSize: "120%",
                    lineHeight: "70%",
                  }}
                >
                  {numComma(netTotal)}{" "}
                  <span style={{ fontSize: "60%" }}>&#2547;</span>
                </p>
              </div>
            </div>

            <div className="d-flex" style={{ flexDirection: "column" }}>
              <div className="" style={{ fontSize: "70%" }}>
                <p>
                  Each{" "}
                  {product.on_sale && (
                    <span
                      className=""
                      style={{
                        textDecoration: "line-through",
                        color: "rgba(120,120,120,1)",
                      }}
                    >
                      {numComma(product.regular_price)}
                    </span>
                  )}{" "}
                  <span className="mark">
                    {numComma(product.price)} &#2547;
                  </span>{" "}
                </p>
              </div>
              <QuantityButton
                value={quantity}
                onQuantityChange={(value) => {
                  dispatch(
                    actions.order.updateCartQuantity(
                      product.id,
                      product.slug,
                      value
                    )
                  );
                }}
                onRemove={() => {
                  dispatch(
                    actions.order.removeFromCart(product.id, product.slug)
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
