import React from "react";
import utils from "../../../utils";
import actions from "../../../store/actions";
import * as selectors from "../../../store/selectors";
import axios from "axios";
import { apiUrl } from "../../../env";
import { useSelector, useDispatch } from "react-redux";
import TypingDot from "../../common/Search/TypingDot";
import Spinner from "../../layout/MainNavbar/Search/Spinner";

const Shipping = () => {
  const [location, setlocation] = React.useState(null);

  const dispatch = useDispatch();
  const [searchText, setSearchText] = React.useState(null);
  const [addressSearchText, setAddressSearchInput] = React.useState("");

  const [isWaiting, setIsWaiting] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [waitingTimer, setWaitingTimer] = React.useState(null);

  const waitingTime = 1000;
  const [addresses, setAddresses] = React.useState([]);

  const selectedAddress = selectors.order.getSelectedAddress(
    useSelector((s) => s)
  );
  console.log({ selectedAddress });
  const searchLocation = async () => {
    console.log("search location");
    if (!searchText || searchText == "") return;
    setIsWaiting(false);
    setIsLoading(true);
    const res = await axios.get(
      `${apiUrl}/api/v1/address/?search=${searchText}&per_page=100&page_number=1`,
      { validateStatus: false }
    );
    console.log(res);

    if (!res.status == 200) {
      console.log("error");
      return;
    }

    setIsLoading(false);
    setAddresses(res.data?.addresses);
  };

  React.useEffect(() => {
    if (!searchText || searchText == "") return;

    setIsWaiting(true);
    setWaitingTimer(setTimeout(() => searchLocation(), waitingTime));
    console.log("shit");

    return () => {
      clearTimeout(waitingTimer);
    };
  }, [searchText]);

  const onClickSearch = () => {
    if (isLoading) return;

    clearTimeout(waitingTimer);
    searchLocation();
  };

  const selectedShippingAddressId = useSelector(
    (state) => state.order.shippingAddress.selectedAddressId
  );

  const customShippingAddress = selectors.order.getCustomShippingAddress(
    useSelector((s) => s)
  );

  const selectedShippingAddress = "";

  return (
    <div className="w-full">
      <h3 className="my-4 text-gray-600">Select Shipping Address</h3>
      <div className="d-flex mb-4">
        <label
          htmlFor="fullName"
          className="text-center flex items-center p-2 px-3 font-medium text-md bg-gray-300 whitespace-nowrap rounded-l-md"
        >
          Search Address:
        </label>
        <input
          type="text"
          className="bg-[#F8F8FA] w-full px-2 rounded-r-lg"
          value={searchText}
          placeholder={
            selectedAddress &&
            !searchText &&
            `${selectedAddress.suburb}, ${selectedAddress.city}, ${selectedAddress.district}, ${selectedAddress.division}`
          }
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <p>{isWaiting && <TypingDot />}</p>
        <p>{isLoading && <Spinner />}</p>
        <button
          className="btn btn-theme text-white"
          style={{ borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
          onClick={onClickSearch}
        >
          Search
        </button>
      </div>
      <div
        className="scrollBar my-1 pe-1"
        style={{
          maxHeight: "200px",
          overflowY: "scroll",
          transition: "height 0.5s ease",
          height: "auto",
          // borderBottom: "1px solid black",
        }}
      >
        {addresses &&
          addresses.map((address) => (
            <LocationItem
              address={address}
              key={address._id}
              isSelected={selectedShippingAddressId == address._id}
              onSelect={() => {
                if (selectedAddress?._id == address._id) {
                  setAddresses([]);
                  setSearchText("");
                }
                dispatch(actions.order.selectShippingAddress(address));
              }}
            />
          ))}
      </div>
      <div className="d-flex mb-4">
        <label
          htmlFor="fullName"
          className="text-center flex items-center p-2 px-3 font-medium text-md bg-gray-300 whitespace-nowrap rounded-l-md"
        >
          House:
        </label>
        <input
          type="text"
          className="bg-[#F8F8FA] w-full px-2 rounded-r-lg"
          placeholder="ex: a 59/c, amar house | road 16, house 13"
          onChange={(e) => {
            dispatch(actions.order.updateCustomShippingAddress(e.target.value));
          }}
          value={customShippingAddress}
        />
      </div>
    </div>
  );
};

const LocationItem = ({ address, isSelected, onSelect }) => {
  return (
    <div
      className="d-flex justify-content-between border pointer p-1 px-2 hover"
      style={{
        // backgroundColor: isSelected && "rgba(200,200,200,1)",
        backgroundColor: isSelected
          ? "rgba(200,200,200,1)"
          : "rgba(100,100,100,0)",
      }}
      onClick={onSelect}
    >
      <div className="d-flex align-items-center">
        <div
          style={{
            borderStyle: "solid",
            borderWidth: "2px",
            width: "1rem",
            height: "1rem",
            minWidth: "1rem",
          }}
          className={`me-1  rounded-circle ${
            isSelected ? "border-theme" : "border-transparent"
          }`}
        >
          <div
            style={{
              boxSizing: "border-box",
              borderStyle: "solid",
              borderWidth: "2px",
              width: "100%",
              height: "100%",
            }}
            className={`me-1  rounded-circle ${
              isSelected ? "border-theme" : "border-dark"
            }`}
          ></div>
        </div>
        <p value={address.id} key={address.id}>
          {`${address.suburb}, ${address.city}, ${address.district}, ${address.division}`}
        </p>
      </div>
      <p>{address.deliveryCharge}</p>
    </div>
  );
};

export default Shipping;
