import React from "react";
import ProductItem from "../../common/ProductItem";
import ProductContainer from "../../common/ProductContainer";
import Loading from "./../../common/Loading";
import * as selectors from "../../../store/selectors";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import actions from "../../../store/actions";
import NextButton from "../../common/NextButton";
import { params as paramActions } from "./../../../utils";

export default function () {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const searchText = params.query;

  const selectedPages = paramActions
    .qStringToObj(location.search)
    ?.pages?.split(",")
    .map((e) => Number(e)) || [1];

  const loading = useSelector(
    (store) =>
      store.searchProducts.products.productsByQuery[searchText]?.dataByFilter[
        "default"
      ]?.dataByOrder["default"]?.dataByPage["1"]?.loading
  );

  const noPageInQParams = paramActions
    .qStringToObj(location.search)
    ?.pages?.split(",")?.length
    ? false
    : true;

  const nextPage = () => {
    const targetPage = selectedPages
      ? Number(selectedPages[selectedPages.length - 1]) + 1
      : 1;

    const linkForNewPage = paramActions.addParamToUrl(
      location.pathname + location.search,
      {
        pages: noPageInQParams ? `1,${targetPage}` : targetPage,
      }
    );
    dispatch(actions.searchProducts.search(searchText, targetPage));

    navigate(linkForNewPage, { replace: true });
  };

  const showableProducts = useSelector((state) => {
    const dataByPage =
      state.searchProducts.products.productsByQuery[searchText]?.dataByFilter[
        "default"
      ]?.dataByOrder["default"]?.dataByPage;

    const dataPageArrOfArr =
      dataByPage &&
      Object.values(dataByPage)
        ?.filter((e) => selectedPages?.includes(e.pageNumber))
        ?.sort((a, b) => (a.pageNumber > b.pageNumber ? 1 : -1))
        ?.map((e) => e.data);

    const allProductsCombined = [];
    dataPageArrOfArr?.forEach((arr) => {
      arr.forEach((ele) => allProductsCombined.push(ele));
    });
    return allProductsCombined;
  });

  const anyLoading = useSelector(
    (s) =>
      s.searchProducts.products.productsByQuery[searchText]?.dataByFilter[
        "default"
      ]?.dataByOrder["default"]?.dataByPage[
        selectedPages[selectedPages?.length - 1]
      ]?.loading
  );

  const lastPageNumber = selectedPages[selectedPages.length - 1];

  const noMoreItemsLeft = useSelector(
    (state) =>
      state.searchProducts.products.productsByQuery[searchText]?.dataByFilter[
        "default"
      ]?.dataByOrder["default"]?.noMoreItemsLeft
  );
  const pageNumbersInReducer = useSelector((state) => {
    const dataByPage =
      state.searchProducts.products.productsByQuery[searchText]?.dataByFilter[
        "default"
      ]?.dataByOrder["default"]?.dataByPage;
    if (!dataByPage) {
      return [];
    }
    return Object.keys(dataByPage).map((e) => Number(e));
  });

  const lastPageHasData =
    useSelector(
      (state) =>
        state.searchProducts.products.productsByQuery[searchText]?.dataByFilter[
          "default"
        ]?.dataByOrder["default"]?.dataByPage[lastPageNumber]?.data
    )?.length > 0;

  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        // top: document.documentElement.scrollHeight,
        top: -100000,
        // behavior: "smooth",
        // behavior: "no",
      });
    };
    scrollToTop();
  }, []);

  return (
    <div className="container">
      {loading && <Loading size="100px" />}
      <ProductContainer>
        {showableProducts?.map((product) => {
          // console.log(product);
          return <ProductItem product={product} key={product.id} />;
        })}
      </ProductContainer>
      {!noMoreItemsLeft ? (
        selectedPages?.length > 1 ? (
          <NextButton
            loading={anyLoading && !lastPageHasData}
            onClick={nextPage}
          />
        ) : lastPageHasData ? (
          <NextButton
            loading={anyLoading && !lastPageHasData}
            onClick={nextPage}
          />
        ) : null
      ) : // last page
      pageNumbersInReducer.length - 1 ==
        pageNumbersInReducer?.indexOf(lastPageNumber) ? null : (
        <NextButton
          loading={anyLoading && !lastPageHasData}
          onClick={nextPage}
        />
      )}
    </div>
  );
}
