import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import Orders from "./Orders";
import ComingSoonPage from "../../common/ComingSoonPage";
import Login from "./LoginFrom";
import Profile from "./UserDetails";
import { useSelector } from "react-redux";
export default function () {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("orders");

  const { user, loadings, isLoggedIn } = useSelector((s) => s.user);

  useEffect(() => {
    console.log({ location, params });
    if (location.pathname.split("/")[1] == "payments") {
      setActiveTab("payments");
    } else if (location.pathname.split("/")[1] == "payment") {
      const paymentId = params.paymentId;
      setActiveTab("payments");
    } else if (location.pathname.split("/")[1] == "orders") {
      setActiveTab("orders");
    } else if (location.pathname.split("/")[1] == "order") {
      setActiveTab("orders");
      const orderId = params.orderId;
    } else if (location.pathname.split("/")[1] == "profile") {
      setActiveTab("profile");
    }
  }, [location]);

  // return <ComingSoonPage />;

  return (
    <div className="container container-md">
      <Tabs
        onSelect={(tabId) => {
          console.log("select");
          console.log(tabId);
          setActiveTab(tabId);
          let path = "";
          if (tabId == "orders") {
            path = "/orders";
          } else if (tabId == "payments") {
            path = "/payments";
          } else if (tabId == "profile") {
            path = "/profile";
          }
          navigate(path);
        }}
        activeKey={activeTab}
        defaultActiveKey={activeTab}
      >
        <Tab eventKey="orders" title="orders">
          {isLoggedIn && <Orders />}
          {!isLoggedIn && (
            <>
              <LoginMessage>Please login to see orders</LoginMessage>
              <Login />
            </>
          )}
        </Tab>
        <Tab eventKey="payments" title="payments">
          {isLoggedIn && "payments payments"}
          {!isLoggedIn && (
            <>
              <LoginMessage>Please login to see payments</LoginMessage>
              <Login />
            </>
          )}
        </Tab>
        <Tab eventKey="profile" title="profile">
          {isLoggedIn && <Profile />}
          {!isLoggedIn && (
            <>
              <LoginMessage>Please login to see profile info</LoginMessage>
              <Login />
            </>
          )}
        </Tab>
      </Tabs>
    </div>
  );
}

const LoginMessage = ({ children }) => {
  return <div className="my-2">{children}</div>;
};
