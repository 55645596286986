import ldb from "localdata";
export default function localstore(key) {
  function isJson(input) {
    if (Array.isArray(input)) {
      return true;
    } else if (typeof input === "object" && input !== null) {
      return true;
    } else {
      return false;
    }
  }

  function parseIfJson(input) {
    let parsedInput;
    try {
      parsedInput = JSON.parse(input);
    } catch (ex) {
      parsedInput = input;
    }
    return parsedInput;
  }
  return {
    saveItem: function (data) {
      try {
        isJson(data)
          ? localStorage.setItem(key, JSON.stringify(data))
          : localStorage.setItem(key, data);
        // isJson(data) ? ldb.set(key, JSON.stringify(data)) : ldb.set(key, data);
      } catch (ex) {
        console.log("Error saving item in local storage");
        console.log(key);
        console.log(ex);
      }
      // ldb.set(key, data);
    },
    getItem: function () {
      // return ldb.get(key);
      try {
        const outputData = localStorage.getItem(key);
        // const outputData2 = ldb.get(key);
        if (!outputData) return null;
        return parseIfJson(outputData);
      } catch (ex) {
        console.log("Error getting item from local storage");
        console.log(key);
        console.log(ex);
        return null;
      }
    },
    deleteItem: function () {
      localStorage.removeItem(key);
    },
  };
}
