import { motion } from "framer-motion";
import { useEffect } from "react";

export default function Transition(PageComponent) {
  // useEffect(() => {
  //   // Reset the scroll position when navigating to a new page
  //   window.scrollTo(0, 0);
  // }, []);
  return () => (
    <>
      {/* <motion.div
        className="page-blink"
        initial={{ display: "block" }}
        animate={{ display: "none" }}
        exit={{ display: "block" }}
        transition={{ duration: 3, ease: [0.22, 1, 0.35, 1] }}
      /> */}
      <PageComponent />
      <motion.div
        className="slide-in"
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0 }}
        exit={{ scaleY: 1 }}
        transition={{ duration: 0.4, ease: [0.22, 1, 0.35, 1] }}
      ></motion.div>
      {/* <motion.div
        className="slide-in"
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 1 }}
        exit={{ scaleY: 1 }}
        transition={{ duration: 3, ease: [0.22, 1, 0.35, 1] }}
      />
      <motion.div
        className="slide-out"
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 1 }}
        exit={{ scaleY: 0 }}
        transition={{ duration: 3, ease: [0.22, 1, 0.35, 1] }}
      /> */}
    </>
  );
}
