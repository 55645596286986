import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../common/Loading";
import { Link } from "react-router-dom";
import actions from "../../../store/actions";

const Payment = () => {
  return (
    <div className="order-details">
      <div>
        <h3 className="text-2xl font-normal mb-3">Payment methods</h3>
        <div className="flex gap-4 flex-wrap">
          <div className="bg-gray-200 w-64 p-1 px-4 pt-3 mb-2 rounded-md">
            <input
              type="radio"
              id="cash_on_delivery"
              name="fav_language"
              value="cash_on_delivery"
              className="me-1 mb-3"
              checked
            />
            <label for="cash_on_delivery">Cash on delivery</label>
          </div>
          <div className="bg-gray-200 w-64 p-1 px-4 pt-3 mb-2 rounded-md">
            <input
              type="radio"
              id="card_on_delivery"
              name="fav_language"
              value="card_on_delivery"
              className="me-1 mb-3"
              checked
            />
            <label for="card_on_delivery">Payment By Card</label>
          </div>
          <div className="bg-gray-200 w-64 p-1 px-4 pt-3 mb-2 rounded-md">
            <input
              type="radio"
              id="bKash_on_delivery"
              name="fav_language"
              value="bKash_on_delivery"
              className="me-1 mb-3"
              checked
            />
            <label for="bKash_on_delivery">bKash Payment</label>
          </div>
        </div>
      </div>
      <div className="flex gap-4 mt-3">
        <input type="radio" className="mb-2 ml-1" />
        <h4 className="text-xl font-normal">
          I agree with the{" "}
          <Link to="terms-and-conditions" className="text-gray-800">
            terms and conditions
          </Link>
        </h4>
      </div>

      {/* <div className="mt-3">
        {placingOrder ? (
          <button
            className="btn btn-theme d-flex justify-content-center gap-1"
            style={{ color: "white", width: "100%" }}
            disabled={true}
          >
            Placing your order
            <Loading size="22px" color="white" />
          </button>
        ) : (
          <button
            className="btn btn-theme d-flex justify-content-center gap-1 text-white w-full"
            onClick={() => {
              dispatch(actions.order.createPurchaseOrder(store));
            }}
            disabled={cartItems.length == 0}
          >
            Place Order
            <span>&#x2713;</span>
          </button>
        )}
      </div> */}
    </div>
  );
};

export default Payment;
