import React, { useEffect, useState, useRef } from "react";
import InvoiceItem from "./CartItem";
import { CaretDownFill } from "react-bootstrap-icons";
// import invoiceData from "./orderDataDummy";

import { useReactToPrint } from "react-to-print";
import Policies from "./Policies";
import Amount from "./Amount";
import { getReadableTime, numComma } from "../../../../../utils";
import OrderSummary from "./OrderSummary";

// import style from "./../";
export default function ({ orderData }) {
  // const location = useLocation();
  // const params = useParams();
  // const navigate = useNavigate();
  const componentRef = useRef();

  // useEffect(() => {
  //   if (componentRef.current) {
  //     const contentHeight = componentRef.current.scrollHeight;
  //     componentRef.current.style.height = `${contentHeight}px`;
  //   }
  // }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [collapsed, setCollapsed] = useState(true);

  if (!orderData) return;
  return (
    <div
      className="border rounded px-2 mb-3 shadow-mini container"
      style={{ margin: "auto" }}
    >
      <div
        ref={componentRef}
        className={`order-item-container px-0 py-2 ${collapsed && "collapsed"}`}
      >
        {/* <OrderHeader /> */}
        <div className="d-flex justify-content-between">
          <div>
            <OrderStatus status_code={orderData.status.summary} />
          </div>
          <div
            className="rotate_on_collapse p-1 px-2"
            // style={{ paddingBottom: "-15px" }}
            onClick={() => setCollapsed(!collapsed)}
          >
            <CaretDownFill size="25" />
          </div>
        </div>
        <OrderDetails
          title={orderData.title}
          invoiceId={orderData.eid}
          date={orderData.createdAt}
          collapsed={collapsed}
          netTotal={orderData.cost.netTotal}
          // customer={orderData.contactInfo.name}
          // contact={
          //   orderData.contactInfo.phone ?? orderData.contactInfo.email
          // }
        />

        <OrderSummary
          grossTotal={orderData.cost.grossTotal}
          totalSaleDiscount={orderData.cost.totalSaleDiscount}
          totalCouponDiscount={orderData.cost.totalCouponDiscount}
          shippingCharge={orderData.cost.shipping}
          netTotal={orderData.cost.netTotal}
          collapsed={collapsed}
        />

        <div>
          {orderData.cartItems.map((obj) => (
            <InvoiceItem cartObj={obj} collapsed={collapsed} />
          ))}
        </div>

        <div className="mb-1 hide_on_collapse">
          <Policies />
        </div>
      </div>
    </div>
  );
}

const OrderStatus = ({ status_code }) => {
  const status_to_readable_title = {
    summary: {
      pending: {
        title: "✔️  Order Received",
        bgColorCode: "#FFEB3B", // Light Yellow
        textColorCode: "#000000", // Black
      },
      processing: {
        title: "📦  Packaging and Processing",
        bgColorCode: "#FF9800", // Orange
        textColorCode: "#FFFFFF", // White
      },
      shipped: {
        title: "🚛  Out for Delivery",
        bgColorCode: "#03A9F4", // Light Blue
        textColorCode: "#FFFFFF", // White
      },
      delivered: {
        title: "👍  Completed",
        bgColorCode: "#4CAF50", // Green
        textColorCode: "#FFFFFF", // White
      },
    },
  };

  const selectedObj = status_to_readable_title.summary[status_code];
  return (
    <div
      className="px-2 py-1 rounded"
      style={{
        backgroundColor: selectedObj.bgColorCode,
        color: selectedObj.textColorCode,
      }}
    >
      {selectedObj.title}
    </div>
  );
};

const OrderDetails = ({
  invoiceId,
  date,
  customer,
  contact,
  title,
  collapsed,
  netTotal,
}) => (
  <div className="align-items-center justify-content-between d-flex">
    <div className="d-flex flex-column">
      <div className="h4 mb-0 mt-1">id: {invoiceId}</div>
      {/* <table className="flex1">
      <colgroup>
        <col style={{ width: "" }} />
        <col style={{ width: "" }} />
        <col style={{ width: "500px" }} />
      </colgroup>
      <Row label="Invoice No" value={invoiceId} />
      <Row label="Invoice Date" value={getTimeInGMT(date, 6)} />
    </table> */}
      <div
        className="flex1 d-flex justify-content-start"
        style={{ fontStyle: "italic", fontWeight: "900", fontSize: "0.9rem" }}
      >
        {/* {invoiceId} */}
        {/* <div className="px-2 B"></div> */}
        <span className="ps-0 pe-2">at</span>
        {getReadableTime(date, 6)}
      </div>
    </div>
    <div className="hide_on_collapse_reverse-">
      <Amount
        // title="total"
        amount={numComma(netTotal)}
        // disabledAmount={regular_price}
        amountPrefix={"৳ "}
      />
    </div>
  </div>
);
