import { useSelector } from "react-redux";
import Loading from "../../common/Loading";
import ProductItem from "../../common/ProductItem";
import ProductContainer from "../../common/ProductContainer";
export default function HotProducts() {
  const { loading, products } = useSelector(
    (state) => state.homePage.featuredProducts
  );

  if (loading && (!products || products.length == 0)) {
    return <Loading size="100px" />;
  }

  return (
    <div className="container">
      <div className="my-5">
        <ProductContainer>
          {products?.map((product) => {
            return <ProductItem product={product} />;
          })}
        </ProductContainer>
      </div>
    </div>
  );
}
