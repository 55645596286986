import React from "react";
import { FaFacebook } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";
import { BsTwitterX } from "react-icons/bs";
import { SiGmail } from "react-icons/si";

const ShereLinkWithSocalMediaIcons = ({ productUrl }) => {
  return (
    <>
      Share:
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${productUrl}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-theme"
      >
        <FaFacebook />
      </a>
      <a
        href={`https://twitter.com/intent/tweet?url=${productUrl}&text=Check%20out%20this%20product!`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-theme"
      >
        <BsTwitterX />
      </a>
      <a
        href={`https://wa.me/?text=${encodeURIComponent(
          `Check out this product: ${productUrl}`
        )}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-theme"
      >
        <ImWhatsapp />
      </a>
      <a
        href={`mailto:?subject=Check%20this%20product&body=${productUrl}`}
        className="text-theme"
      >
        <SiGmail />
      </a>
    </>
  );
};

export default ShereLinkWithSocalMediaIcons;
