import order from "./modules/order/selectors";
import categories from "./modules/categories/selectors";
import categoryPage from "./modules/categoryPage/selectors";

export * as productPage from "./modules/productPage/selector";
// export * as productSearch from "./modules/productSearch/selector";
export * as order from "./modules/order/selectors";
export * as categories from "./modules/categories/selectors";
export * as categoryPage from "./modules/categoryPage/selectors";

export default { order, categories, categoryPage };
