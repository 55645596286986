const browserStorage = (store) => (next) => (action) => {
  // console.group(action.type);
  // console.info("dispatching", action);
  let result = next(action);
  // console.log("next state", store.getState());
  // console.log("shitted");
  // console.groupEnd();
  // console.log({ store: store.getState() });
  // localStorage.setItem("store", JSON.stringify(store.getState()));

  // localStorage.setItem(
  //   "store.categories",
  //   JSON.stringify(store.getState().categories)
  // );
  // localStorage.setItem(
  //   "store.homePage",
  //   JSON.stringify(store.getState().homePage)
  // );
  // localStorage.setItem("store.order", JSON.stringify(store.getState().order));
  // localStorage.setItem(
  //   "store.productPage",
  //   JSON.stringify(store.getState().productPage)
  // );

  return result;
};

export default browserStorage;
