import s from "./typingDot.module.css";
export default function () {
  return (
    <div className={s.typing}>
      <div className={s.typing__dot}></div>
      <div className={s.typing__dot}></div>
      <div className={s.typing__dot}></div>
    </div>
  );
}
