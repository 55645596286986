import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

export default function NavigationTabs({ steps }) {
  return (
    <div className="flex-1 relative">
      <div className="flex justify-between flex-1 NavItemContainer">
        <NavItem
          label={steps.shipping.label}
          Icon={steps.shipping.Icon}
          url={steps.shipping.url}
          completed={steps.shipping.completed}
          isActive={
            steps.shipping.isActive ||
            steps.coupon.isActive ||
            steps.customer.isActive ||
            steps.payment.isActive
          }
        />
        <NavItem
          label={steps.coupon.label}
          Icon={steps.coupon.Icon}
          url={steps.coupon.url}
          completed={steps.coupon.completed}
          isActive={
            steps.coupon.isActive ||
            steps.customer.isActive ||
            steps.payment.isActive
          }
        />
        <NavItem
          label={steps.customer.label}
          Icon={steps.customer.Icon}
          url={steps.customer.url}
          completed={steps.customer.completed}
          isActive={steps.customer.isActive || steps.payment.isActive}
        />
        <NavItem
          label={steps.payment.label}
          Icon={steps.payment.Icon}
          url={steps.payment.url}
          completed={steps.payment.completed}
          isActive={steps.payment.isActive}
        />
      </div>

      <div className="flex absolute w-full B" style={{ top: "35%", flex: 1 }}>
        <Danda
          flex="2"
          isActive={
            steps.shipping.completed &&
            (steps.coupon.isActive ||
              steps.customer.isActive ||
              steps.payment.isActive)
          }
          completed={false}
        />
        <Danda
          flex="2"
          isActive={
            // steps.coupon.completed &&
            steps.customer.isActive || steps.payment.isActive
          }
          completed={false}
        />
        <Danda
          flex="2"
          isActive={steps.customer.completed && steps.payment.isActive}
          completed={false}
        />
      </div>
    </div>
  );
}

const Danda = ({ flex, completed, isActive }) => {
  return (
    <div
      className=""
      style={{
        border: completed
          ? "3px solid red"
          : isActive
          ? "2px solid #578088"
          : "1px solid grey",
        flex: flex,
        zIndex: 1,
      }}
    ></div>
  );
};

const NavItem = ({ label, Icon, url, completed, isActive }) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-col itemss-center text-2xl NavItem"
      style={{ zIndex: 2 }}
      onClick={() => {
        navigate(url);
      }}
    >
      <div style={{}} className="B flex flex-center">
        <div
          style={{ display: "inline-block" }}
          className={`px-3 py-2 rounded-md
            ${
              // index <= activeTabIndex
              isActive ? "bg-theme text-white" : "bg-gray-200 text-[#578088]"
            }
          `}
        >
          {" "}
          {Icon && <Icon />}
        </div>
      </div>
      <span
        className={`text-sm font-medium ${
          // index <= activeTabIndex
          isActive ? "text-theme" : "text-gray-500"
        }`}
      >
        {label} {completed && "✔️"}
      </span>
    </div>
  );
};
