import axios from "axios";
import * as actionNames from "./actionNames";
import { wpUrl, consumer_key, consumer_secret } from "../../../env";

export const getFeaturedCategories = () => async (dispatch) => {
  const url = `${wpUrl}/wp-json/wc/v3/products/categories?consumer_key=${consumer_key}&consumer_secret=${consumer_secret}&per_page=50`;

  dispatch({
    type: actionNames.getFeaturedCategories.pending,
    payload: {},
  });

  const res = await axios.get(url);

  if (res.status == 200) {
    return dispatch({
      type: actionNames.getFeaturedCategories.success,
      payload: { categories: res.data },
    });
  }

  return dispatch({
    type: actionNames.getFeaturedCategories.fail,
    payload: {},
  });
};

export const getFeaturedEarphones = () => async (dispatch) => {
  const url = `${wpUrl}/wp-json/wc/v3/products?consumer_key=${consumer_key}&consumer_secret=${consumer_secret}&per_page=6&category=16`;

  dispatch({
    type: actionNames.getFeaturedEarphones.pending,
    payload: {},
  });

  const res = await axios.get(url);

  if (res.status == 200) {
    return dispatch({
      type: actionNames.getFeaturedEarphones.success,
      payload: { earphones: res.data },
    });
  }

  return dispatch({
    type: actionNames.getFeaturedEarphones.fail,
    payload: {},
  });
};

export const getFeaturedSmartwatches = () => async (dispatch) => {
  const url = `${wpUrl}/wp-json/wc/v3/products?consumer_key=${consumer_key}&consumer_secret=${consumer_secret}&per_page=6&category=59`;

  dispatch({
    type: actionNames.getFeaturedSmartwatches.pending,
    payload: {},
  });

  const res = await axios.get(url);
  console.log(res);

  if (res.status == 200) {
    return dispatch({
      type: actionNames.getFeaturedSmartwatches.success,
      payload: { smartwatches: res.data },
    });
  }

  return dispatch({
    type: actionNames.getFeaturedSmartwatches.fail,
    payload: {},
  });
};

export const getFeaturedProducts = () => async (dispatch) => {
  const url = `${wpUrl}/wp-json/wc/v3/products?consumer_key=${consumer_key}&consumer_secret=${consumer_secret}&featured=true`;

  dispatch({
    type: actionNames.getFeaturedProducts.pending,
    payload: {},
  });

  const res = await axios.get(url);

  if (res.status == 200) {
    return dispatch({
      type: actionNames.getFeaturedProducts.success,
      payload: { products: res.data },
    });
  }

  return dispatch({
    type: actionNames.getFeaturedProducts.fail,
    payload: {},
  });
};
