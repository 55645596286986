import React from "react";

function ComingSoonPage() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        backgroundColor: "#f0f0f0",
      }}
    >
      <div
        style={{
          textAlign: "center",
        }}
      >
        <h1
          style={{
            fontSize: "48px",
            fontWeight: "bold",
          }}
        >
          Coming Soon
        </h1>
        <p
          style={{
            fontSize: "24px",
            color: "#666",
          }}
        >
          We're working hard to bring you an amazing experience.
        </p>
      </div>
    </div>
  );
}

export default ComingSoonPage;
