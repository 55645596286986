import axios from "axios";
import * as actionNames from "./actionNames";
import { apiUrl, wpUrl, consumer_key, consumer_secret } from "../../../env";
const pageSize = 10;

export const search =
  (query, pageNumber = 1, filter, order) =>
  async (dispatch) => {
    // console.log("----------");
    dispatch({
      type: actionNames.search.pending,
      payload: { pageSize, pageNumber, query },
    });

    const url = `${apiUrl}/api/v1/products`;

    const res = await axios.get(url, {
      params: {
        search: query,
        per_page: pageSize,
        page: pageNumber,
      },
      validateStatus: false,
    });
    console.log(res);
    if (res.status !== 200) {
      dispatch({
        type: actionNames.search.fail,
        payload: { errorMessage: "failled to load data", pageNumber, pageSize },
      });
    }

    dispatch({
      type: actionNames.search.success,
      payload: {
        pageSize,
        pageNumber,
        data: res.data.products,
        query: query,
        totalCount: res.data.totalCount,
        noMoreItemsLeft: res.data.length < pageSize,
      },
    });
  };

export default {
  search,
};
