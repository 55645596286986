import { applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./rootReducer";
import monitorReducerEnhancer from "./middlewares/monitorReducerEnhancer.js";
import loggerMiddleware from "./middlewares/logger.js";
import browserStorageMiddlewere from "./middlewares/browserStorage.js";

import versionChange from "./versionChange.ts";
versionChange();

const middlewareEnhancer = applyMiddleware(
  loggerMiddleware,
  thunkMiddleware,
  browserStorageMiddlewere
);

const composedEnhancers = compose(
  middlewareEnhancer,
  monitorReducerEnhancer,
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (noop) => noop
);

export const configureStore = () =>
  createStore(rootReducer, {}, composedEnhancers);

const store = configureStore();
export default store;
