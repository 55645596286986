import axios from "axios";
import * as actionNames from "./actionNames";
import { wpUrl, consumer_key, consumer_secret } from "../../../env";
import { modifyObject, nestMenuItems } from "./../../../utils";

export const fetchAllCategories = () => async (dispatch) => {
  const url = `${wpUrl}/wp-json/wc/v3/products/categories?consumer_key=${consumer_key}&consumer_secret=${consumer_secret}&per_page=50`;

  dispatch({
    type: actionNames.fetchAllCategories.pending,
    payload: {},
  });

  const res = await axios.get(url);

  if (res.status == 200) {
    return dispatch({
      type: actionNames.fetchAllCategories.success,
      payload: { categories: res.data },
    });
  }

  return dispatch({
    type: actionNames.fetchAllCategories.fail,
    payload: {},
  });
};

export const getOrderedCategories = () => async (dispatch) => {
  const allCategoriesUrl = `${wpUrl}/wp-json/wc/v3/products/categories?consumer_key=${consumer_key}&consumer_secret=${consumer_secret}&per_page=50`;

  dispatch({
    type: actionNames.getOrderedCategories.pending,
    payload: {},
  });

  // fetch nested menu items which are specially set by store owner for specific place.
  const categoryOrderData = await axios
    .get("https://gearmadebd.com/wp-json/custom/v1/menus/")
    .then((res) => res.data)
    .then(
      (data) =>
        data.find((menu) => menu.name == "default_category_order")?.items
    )
    .then((items) =>
      // console.log({ items });
      items.map((i) =>
        modifyObject(i, {
          ID: "id",
          menu_item_parent: "parent",
          object_id: "wc_category_id",
          post_title: "title",
        })
      )
    );
  // console.log({ categoryOrderData });

  // fetch all categories which are nested in default order.
  const allCategories = await axios
    .get(allCategoriesUrl)
    .then((res) => res.data)
    .then((categories) =>
      categories.map((cat) =>
        modifyObject(cat, {
          id: "wc_category_id",
          // name: "name",
          slug: "slug",
          image: "image",
        })
      )
    );

  const catOrderDataWithCatData = categoryOrderData.map((catODItem) => ({
    ...catODItem,
    ...allCategories.find(
      (catItem) => catItem.wc_category_id == catODItem.wc_category_id
    ),
  }));

  // console.log({ allCategories });
  // console.log({ catOrderDataWithCatData });

  const nestedCategories = nestMenuItems(
    catOrderDataWithCatData,
    "parent",
    "id"
  );
  console.log({ nestedCategories });

  if (nestedCategories) {
    return dispatch({
      type: actionNames.getOrderedCategories.success,
      payload: {
        orderedCategories: nestedCategories,
        allCategoriesData: allCategories,
      },
    });
  }

  return dispatch({
    type: actionNames.getOrderedCategories.fail,
    payload: {},
  });
};
