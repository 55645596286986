const baseName = "order";
// cart
export const addToCart = `${baseName}/addToCart`;
export const removeFromCart = `${baseName}/removeFromCart`;
export const updateCartQuantity = `${baseName}/updateCartQuantity`;

// coupon
export const addCoupon = {
  // also add logic to change coupon
  pending: `${baseName}/addCoupon/pending`,
  success: `${baseName}/addCoupon/success`,
  fail: {
    invalid_coupon: `${baseName}/addCoupon/fail/invalid_coupon`,
    limit_expired: `${baseName}/addCoupon/fail/limit_expired`,
    limit_per_user_expired: `${baseName}/addCoupon/fail/limit_per_user_expired`,
    date_expired: `${baseName}/addCoupon/fail/date_expired`,
    error: `${baseName}/addCoupon/fail/error`,
  },
};
export const selectCoupon = `${baseName}/selectCoupon`;
export const unSelectCoupon = `${baseName}/unSelectCoupon`;

// Address
export const getShippingAddresses = {
  pending: `${baseName}/getShippingAddresses/pending`,
  success: `${baseName}/getShippingAddresses/success`,
  fail: `${baseName}/getShippingAddresses/fail`,
};

export const saveShippingAddresses = `${baseName}/saveShippingAddresses`;
export const selectShippingAddress = `${baseName}/selectShippingAddress`;
export const updateCustomShippingAddress = `${baseName}/updateCustomShippingAddress`;

export const updateContactInfo = `${baseName}/updateContactInfo`;

export const createPurchaseOrder = {
  pending: `${baseName}/createPurchaseOrder/pending`,
  success: `${baseName}/createPurchaseOrder/success`,
  fail: `${baseName}/createPurchaseOrder/fail`,
};

export const updateOrderNote = `${baseName}/updateOrderNote`;

export default {
  addToCart,
  removeFromCart,
  updateCartQuantity,
  addCoupon,
  selectCoupon,
  unSelectCoupon,
  getShippingAddresses,
  selectShippingAddress,
  updateCustomShippingAddress,
  updateContactInfo,
  createPurchaseOrder,
  saveShippingAddresses,
  updateOrderNote,
};
