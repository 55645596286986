import React from "react";
import s from "./index.module.css";
import * as actions from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import H2 from "./../../../components/common/SectionHeading";

import { Row, Col } from "react-bootstrap";
import { Box, CaretDown } from "react-bootstrap-icons";

import category from "./../../common/Category";
import { useNavigate } from "react-router-dom";
import { nestMenuItems } from "./../../../utils";

export default function () {
  const dispatch = useDispatch();
  // const allCategories = useSelector(
  //   (state) => state.categories.allCategories.data
  // );
  const orderedCategories = useSelector(
    (state) => state.categories.orderedCategories
  );

  console.log({ orderedCategories });

  // const nestedJson = nestMenuItems(inputJson);
  // console.log(JSON.stringify(nestedJson, null, 2));

  return (
    <div className="mt-5" id="categories">
      <H2 align="">Categories</H2>
      <Row
        className="my-3"
        style={
          {
            //  gap: "0.5rem"
          }
        }
      >
        {orderedCategories &&
          orderedCategories.map((p_cat) => (
            <Col xs={12} sm={12} lg={4} className="mb-3">
              <ParentCategory category={p_cat}>
                {p_cat.childs.map((child_cat) => (
                  <ChildCategory category={child_cat} />
                ))}
              </ParentCategory>
            </Col>
          ))}
      </Row>
      {/* <H2 align="center">Categories</H2>
      <div className={`d-flex flex-wrap`}>
        {featuredCategories?.map((cat) => (
          <div className="col-6 col-md-4 col-lg-3">
            <category.Item category={cat} key={cat.name} />
          </div>
        ))}
      </div> */}
    </div>
  );
}

const ParentCategory = ({ category, children }) => {
  const navigate = useNavigate();
  return (
    <div
      className="border rounded border-dark px-2 py-2 hover"
      style={{ height: "100%" }}
    >
      <div className="d-flex justify-content-between">
        <div
          className="h5 p-1 pointer"
          style={{ whiteSpace: "nowrap" }}
          onClick={() => {
            navigate(`/product-category/${category.slug}`);
          }}
        >
          {category.title} <Box />
        </div>
        {/* <div className="small pointer">
          All Categories <CaretDown />
        </div> */}
      </div>
      <div className="d-flex flex-wrap" style={{ gap: "5px" }}>
        {children}
      </div>
    </div>
  );
};

const ChildCategory = ({ category }) => {
  // console.log("ChildCategory");
  // console.log(category.title);
  const navigate = useNavigate();
  return (
    <div
      className="border rounded px-2 py-1 gap-2 hover pointer"
      style={{
        flex: category.title.length,
        whiteSpace: "nowrap",
      }}
      onClick={() => {
        navigate(`/product-category/${category.slug}`);
      }}
    >
      {category.title}
      {/* <Box color={"rgba(170,170,170,1)"} /> */}
    </div>
  );
};
