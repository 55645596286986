// export const apiUrl = process.env.REACT_APP_API_URL;
// export const apiUrl = "https://uat.gearupapi.globalport.online"
// export const apiUrl = "http://192.168.0.201:5000";
// export const apiUrl = "http://localhost:5000";
// export const apiUrl = "https://apiuat.gearmadebd.com";
export const apiUrl = process.env.REACT_APP_API_URL;
// export const apiUrl = process.env.REACT_APP_API_URL;
// export const wpUrl = process.env.REACT_APP_PRODUCT_SERVICE_URL;
export const wpUrl = process.env.REACT_APP_PRODUCT_SERVICE_URL;

export const consumer_key = process.env.REACT_APP_CONSUMER_KEY;

export const consumer_secret = process.env.REACT_APP_CONSUMER_SECRET;

export const category_order_menu_name =
  process.env.REACT_APP_CATEGORY_ORDER_MENU_NAME;
