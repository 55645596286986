const baseName = "user";

export const login = {
  _: "login",
  pending: `${baseName}/login/pending`,
  success: `${baseName}/login/success`,
  fail: `${baseName}/login/fail`,
};

login[login.pending] = "login";
login[login.success] = "login";
login[login.fail] = "login";

export const register = {
  pending: `${baseName}/register/pending`,
  success: `${baseName}/register/success`,
  fail: `${baseName}/register/fail`,
};

export const verifyAccessToken = {
  _: "verifyAccessToken",
  pending: `${baseName}/verifyAccessToken/pending`,
  success: `${baseName}/verifyAccessToken/success`,
  fail: `${baseName}/verifyAccessToken/fail`,
};
verifyAccessToken[verifyAccessToken.pending] = "verifyAccessToken";
verifyAccessToken[verifyAccessToken.success] = "verifyAccessToken";
verifyAccessToken[verifyAccessToken.fail] = "verifyAccessToken";
