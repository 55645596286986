import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { getLocationFullName, numComma } from "../../../utils";
import Form from "react-bootstrap/Form";

import actions from "../../../store/actions";
import * as selectors from "../../../store/selectors";
import axios from "axios";
import { Accordion } from "react-bootstrap";
import Loading from "../../common/Loading";
import { Link } from "react-router-dom";

export default function ({ itemRef, handleClick, eventKey }) {
  const dispatch = useDispatch();
  const {
    grossTotal,
    totalCouponDiscount,
    netTotal,
    shippingCharge,
    totalSaleDiscount,
  } = useSelector((s) => s.order.charges);

  const cartItems = useSelector((state) =>
    Object.values(state.order.cartItems)
  ).sort((a, b) => (a.addedAt > b.addedAt ? -1 : 1));
  const orderNote = useSelector((state) => state.order.orderNote);

  const { selectedCouponCode, selectedCouponDiscount } = useSelector(
    (s) => s.order.coupon
  );

  const placingOrder = useSelector(
    (state) => state.order.actions.createPurchaseOrder.loading
  );

  const store = useSelector((e) => e);
  return (
    <div className="order-details mt-4 text-theme p-2 pt-3 mb-2 rounded-lg bg-white">
      <h2 className="text-center">Products Info</h2>
      {true ? (
        <div className="">
          <div className="row mb-3">
            <div className="column1">
              <p className="h6">Regular Price</p>
            </div>
            <div className="column2">{numComma(grossTotal)}</div>
          </div>
          <div className="row mb-3">
            <div className="column1">
              <p className="h6">Sale Discount</p>
            </div>
            <div className="column2">
              {totalSaleDiscount && "-"}
              {numComma(totalSaleDiscount)}
            </div>
          </div>
          <div className="row mb-3">
            <div className="column1">
              <p className="h6">Coupon Discount</p>
            </div>
            <div className="column2">
              {totalCouponDiscount && "-"}
              {numComma(totalCouponDiscount)}
            </div>
          </div>
          <div className="row mb-3">
            <div className="column1">
              <p className="h6">Shipping Charge</p>
            </div>
            <div className="column2">{numComma(shippingCharge)}</div>
          </div>
          <div className="row mb-3">
            <div className="column1">
              <p className="h5">Net Total</p>
            </div>
            <div className="column2 h5">{numComma(netTotal)}</div>
          </div>
        </div>
      ) : (
        <div>Select Products</div>
      )}
    </div>
  );
}
