import React from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../store/actions";

const UserInfo = () => {
  const dispatch = useDispatch();
  const { fullName, email, phone } = useSelector(
    (state) => state.order.contactInfo
  );

  const [validPhone, setValidPhone] = React.useState(false);
  const [validEmail, setValidEmail] = React.useState(false);

  React.useEffect(() => {
    function checkPhoneNumber(number) {
      if (String(number).length != (11 || 14)) return false;
      const cleanNumber = number.replace(/\D/g, "");

      const bangladeshiNumberPattern = /^(?:\+8801|\b01)(?:\d{9})$/;

      return bangladeshiNumberPattern.test(cleanNumber);
    }
    setValidPhone(checkPhoneNumber(phone));

    function checkEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      return emailRegex.test(email);
    }
    setValidEmail(checkEmail(email));
  }, [phone, email]);

  return (
    <div className="w-full">
      <h3 className="my-4 text-gray-600">Select Shipping Address</h3>
      <div className="d-flex mb-4">
        <label
          htmlFor="fullName"
          className="text-center flex items-center p-2 px-3 font-medium text-md bg-gray-300 whitespace-nowrap rounded-l-md"
        >
          Name:
        </label>
        <input
          type="text"
          className="bg-[#F8F8FA] w-full px-2 rounded-r-lg"
          id="fullName"
          value={fullName}
          onChange={(e) => {
            dispatch(
              actions.order.updateContactInfo({
                fullName: e.target.value,
              })
            );
          }}
          placeholder="Full Name"
        />
      </div>
      <div className="d-flex mb-4">
        <label
          htmlFor="fullName"
          className="text-center flex items-center p-2 px-3 font-medium text-md bg-gray-300 whitespace-nowrap rounded-l-md"
        >
          Email:
        </label>
        <input
          className="bg-[#F8F8FA] w-full px-2 rounded-r-lg"
          id="email"
          value={email}
          onChange={(e) => {
            dispatch(
              actions.order.updateContactInfo({ email: e.target.value })
            );
          }}
          placeholder="Email"
        />
      </div>
      <div className="d-flex mb-4">
        <label
          htmlFor="fullName"
          className="text-center flex items-center p-2 px-3 font-medium text-md bg-gray-300 whitespace-nowrap rounded-l-md"
        >
          Phone: {phone != "" && !validPhone && "invalid"}
        </label>
        <input
          className="bg-[#F8F8FA] w-full px-2 rounded-r-lg"
          type="tel"
          id="phone"
          value={phone}
          placeholder="Phone"
          onChange={(e) => {
            dispatch(
              actions.order.updateContactInfo({ phone: e.target.value })
            );
          }}
        />
      </div>
    </div>
  );
};

export default UserInfo;
