// import { useNavigate } from "react-router-dom";

// import { useDispatch } from "react-redux";
import Amount from "./Amount";
import React, { useState } from "react";
import TermItem from "./TermItem";
import { numComma } from "../../../../../utils";
export default function CartItem({ cartObj, collapsed }) {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  const {
    quantity,
    grossTotal,
    totalCouponDiscount,
    netTotal,
    isCouponApplied,
    productData: product,
    images,
    on_sale,
    price,
    regular_price,
    slug,
    sale_price,
    terms,
    warranty,
  } = cartObj;

  const { warrantyPeriod, returnPeriod, refundPeriod } = cartObj.terms || {};

  return (
    <div
      className={`d-flex p-1 my-1`}
      // style={{ width: "25%", boxSizing: "border-box" }}
      style={{
        zIndex: 1001,
        // height: "105px",
        borderRadius: "5px",
        border: `1px solid ${
          isCouponApplied ? "#5cb4c2" : "rgba(200, 200, 200, 1)"
        }`,
        // fontSize: "100%",
      }}
    >
      <div className="d-flex gap-1 p-fs-md" style={{ flex: 1 }}>
        <img
          src={images[0]?.src}
          className={`order-item-img`}
          alt=""
          style={{
            // height: "100%",
            width: "auto",
            maxHeight: "80px",
            objectFit: "cover",
            aspectRatio: "1",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            // navigate(`/product/${slug}`);
          }}
        />
        <div className="d-flex flex-column ms-1" style={{ flex: 1 }}>
          <div
            className="flex1"
            style={{
              fontSize: "110%",
            }}
            onClick={() => {
              // navigate(`/product/${slug}`);
            }}
          >
            <p className="p-fs-md" style={{ fontSize: "88%" }}>
              {slug}
            </p>
          </div>

          <div className="d-flex flex-wrap" style={{ gap: "5px" }}>
            {warranty?.value > 0 && (
              <TermItem
                period={warranty?.value}
                unit={warranty?.unit}
                iconName="warranty"
              />
            )}
            {/* {terms?.return.period && (
              <TermItem period={terms?.return.period} iconName="return" />
            )} */}
          </div>

          <div className="d-flex justify-content-end ">
            <div
              className={`d-flex justify-content-end align-items-end amount-container hide_on_collapse`}
            >
              <Amount
                //   title=""
                amount={numComma(netTotal / quantity)}
                disabledAmount={regular_price}
              />
              <div className="p-0">
                <div className="" style={{ marginBottom: "-3px" }}>
                  &#xd7;
                </div>
              </div>
              <Amount
                // title="qty"
                amountPrefix={quantity < 10 && "0"}
                amount={quantity}
                disabledAmount={""}
                // minWidth={"30px"}
              />
              <div className="p-0">
                <div className="" style={{ marginBottom: "-3px" }}>
                  &#61;
                </div>
              </div>
              <Amount
                // title="total"
                amount={netTotal}
                disabledAmount={""}
                minWidth={"40px"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
