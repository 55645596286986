export default function ({ children, align = "center" }) {
  return (
    <div
      style={{ height: "50px", paddingTop: "25px", color: "#588086" }}
      className=""
    >
      <div
        className=""
        style={{ border: "1px solid rgba(150,150,150,0.5)" }}
      ></div>
      <div style={{ marginTop: "-1.5rem", textAlign: align }}>
        <h2
          style={{
            // marginTop: "-calc(1.325rem + .9vw)",
            display: "inline",
            backgroundColor: "white",
            marginLeft: "-1rem",
            marginRight: "-1rem",
          }}
          className="px-3"
        >
          {children}
        </h2>
      </div>
    </div>
  );
}
