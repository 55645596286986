import store from "./index";

import * as homePage from "./modules/homePage/actions";
import * as productPage from "./modules/productPage/actions";
// import * as productSearch from "./modules/productSearch/actions";
import * as order from "./modules/order/actions";
import * as categories from "./modules/categories/actions";
import * as categoryPage from "./modules/categoryPage/actions";
import * as searchProducts from "./modules/searchProducts/actions";
import * as nabvar from "./modules/navbar/actions";
import * as user from "./modules/user/actions";

export * as nabvar from "./modules/navbar/actions";
export * as homePage from "./modules/homePage/actions";
export * as productPage from "./modules/productPage/actions";
// export * as productSearch from "./modules/productSearch/actions";
export * as order from "./modules/order/actions";
export * as categories from "./modules/categories/actions";
export * as categoryPage from "./modules/categoryPage/actions";
export * as searchProducts from "./modules/searchProducts/actions";
export * as user from "./modules/user/actions";

export const init = (accessToken) => (dispatch) => {
  console.log(store.getState());
  console.log(new Date());
  console.log(new Date().getTime());
  if (accessToken) {
    dispatch(user.verifyAccessToken(accessToken));
  }

  dispatch(categories.fetchAllCategories());
  dispatch(homePage.getFeaturedProducts());
  dispatch(categories.getOrderedCategories());
};

export default {
  homePage,
  productPage,
  // productSearch,
  order,
  categories,
  init,
  categoryPage,
  searchProducts,
  nabvar,
  user,
};
