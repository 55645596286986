import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions";
import * as selectors from "../../../../store/selectors";
import { useEffect, useState } from "react";

import TypingDot from "./TypingDot";
import Spinner from "./Spinner";

export default function () {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const waitingTime = 500;

  const [isWaiting, setIsWaiting] = useState(false);
  const [searchText, setSearchText] = useState("");

  const searchProducts = () => {
    setIsWaiting(false);
    dispatch(actions.searchProducts.search(searchText));
    navigate(`/search/${searchText}`);
    // console.log("--should start loading--");
  };

  const alreadyHasDataForTheQuery = useSelector(
    (store) =>
      store.searchProducts.products.productsByQuery[searchText]?.dataByFilter[
        "default"
      ]?.dataByOrder["default"]?.dataByPage["1"]?.data?.length > 0
  );

  //   useEffect(() => {
  //     const expactedLocation = `/search/${searchText}`;
  //     console.log({ params, location, expactedLocation });
  //     if (location.pathname != expactedLocation) {
  //       //   navigate(`/search/${params.query}`);
  //       setSearchText(params.query);
  //     }
  //   }, [location]);

  const loading = useSelector(
    (store) =>
      store.searchProducts.products.productsByQuery[searchText]?.dataByFilter[
        "default"
      ]?.dataByOrder["default"]?.dataByPage["1"]?.loading
  );

  // console.log({ loading });

  useEffect(() => {
    if (!searchText || searchText == "") {
      setIsWaiting(false);
      return;
    }

    if (alreadyHasDataForTheQuery) {
      searchProducts();
      return;
    }

    setIsWaiting(true);
    let waitingTimer = setTimeout(() => searchProducts(), waitingTime);
    console.log("shit");

    return () => {
      clearTimeout(waitingTimer);
    };
  }, [searchText]);

  return (
    <div
      style={{ flex: 1 }}
      className="d-flex flex align-items-center justify-content-center px-2 py-1 mt-1"
    >
      <input
        className=""
        style={{
          flex: 1,
          borderRight: 0,
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
          // borderRadius: "0",
          padding: "3px",
          paddingLeft: "8px",
          outlineRight: "0",
          border: "1px solid #ccc",
          borderRight: "0",
        }}
        type="text"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />

      <div
        className="d-flex"
        style={{
          height: "100%",
          border: "1px solid #ccc",
          borderRight: "0",
          borderLeft: "0",
        }}
      >
        {isWaiting && <TypingDot />}
        {loading && <Spinner />}
      </div>
      <Link
        className="d-flex flex-center px-2"
        style={{
          borderRadiusLeft: "10px",
          height: "28px",
          textDecoration: "none",
          backgroundColor: "#eeeeee",
          color: "black",
          height: "32px",
          borderLeft: 0,
          borderTopRightRadius: "5px",
          borderBottomRightRadius: "5px",
          border: "1px solid #ccc",
          borderLeft: "0",
        }}
        to={`/search/${searchText}`}
      >
        <div>search</div>
      </Link>
    </div>
  );
}
