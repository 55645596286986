import axios from "axios";
import * as actionNames from "./actionNames";
import { wpUrl, consumer_key, consumer_secret, apiUrl } from "../../../env";

export const fetchProducts =
  (category, pageNumber = 1) =>
  async (dispatch) => {
    // const url = `${wpUrl}/wp-json/wc/v3/products?consumer_key=${consumer_key}&consumer_secret=${consumer_secret}`;
    const url = `${apiUrl}/api/v1/products`;

    dispatch({
      type: actionNames.fetchProducts.pending,
      payload: { category, pageNumber },
    });

    const pageSize = 10;

    const res = await axios.get(url, {
      params: { per_page: pageSize, category: category.id, page: pageNumber },
    });
    console.log(res);

    if (res.status == 200) {
      console.log({ noMoreItemsLeft: res.data.products.length < pageSize });
      return dispatch({
        type: actionNames.fetchProducts.success,
        payload: {
          products: res.data.products,
          category,
          pageNumber,
          noMoreItemsLeft: res.data.products.length < pageSize,
        },
      });
    }

    return dispatch({
      type: actionNames.fetchProducts.fail,
      payload: {},
    });
  };

export const getProductCount = () => {};
