import React from "react";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../../store/actions";
import { XCircle } from "react-bootstrap-icons";
import Loading from "../../common/Loading";
import { Form } from "react-bootstrap";

const Coupon = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.order.loadings.addCoupon);

  const couponItemsObj = useSelector((state) => state.order.coupon.allCoupons);

  const couponItems = Object.values(couponItemsObj).sort(
    (a, b) => new Date(a.addedAt) > new Date(b.addedAt)
  );

  const { netTotal, grossTotal, totalCouponDiscount } = useSelector(
    (s) => s.order.charges
  );

  const { allCoupons, selectedCouponCode } = useSelector(
    (state) => state.order.coupon
  );
  const selectedCoupon =
    allCoupons[selectedCouponCode] && allCoupons[selectedCouponCode].couponData;
  const fullState = useSelector((s) => s);

  return (
    <div className="w-full">
      <h3 className="my-4 text-gray-600">Select Coupon</h3>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          const code = e.target[0].value;
          dispatch(
            actions.order.addCoupon(code, fullState, () => {
              e.target[0].value = "";
            })
          );
        }}
      >
        <div className="d-flex mb-2">
          <label
            htmlFor="fullName"
            className="text-center flex items-center p-2 px-3 font-medium text-md bg-gray-300 whitespace-nowrap rounded-l-md"
          >
            Select Coupon:
          </label>
          <input
            type="text"
            className="bg-[#F8F8FA] w-full px-2 rounded-r-lg"
            disabled={loading}
            placeholder="Coupon Code..."
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
          <button
            className="btn btn-theme text-white d-flex"
            style={{
              borderTopLeftRadius: "0",
              borderBottomLeftRadius: "0",
            }}
            type="submit"
            disabled={loading}
          >
            Add
            {loading ? <Loading size="20px" /> : <></>}
          </button>
        </div>
      </Form>
      <div style={{ display: "flex", flexWrap: "wrap" }} className="py-3">
        {couponItems.map((e, i) => (
          <div className="flex ">
            <CouponItem couponItemObj={e} key={i}></CouponItem>
          </div>
        ))}
      </div>
    </div>
  );
};

const CouponItem = ({ couponItemObj }) => {
  const dispatch = useDispatch();

  const { code, amount, discount_type, description } = couponItemObj.couponData;

  const { allCoupons, selectedCouponCode } = useSelector(
    (state) => state.order.coupon
  );

  return (
    <div
      className={`d-flex p-1`}
      // style={{ width: "25%", boxSizing: "border-box" }}
      style={{
        // zIndex: 1001,
        maxHeight: "110px",
        borderRadius: "5px",
        flex: 1,
        // backgroundColor: isCouponApplied && "rgba(0,0,0,0.3)",
        // boxShadow: "0px 0px 15px -5px rgba(0,0,0,0.75)",
        border: `2px solid ${
          selectedCouponCode == code ? "#5cb4c2" : "rgba(200, 200, 200, 1)"
        }`,
      }}
      onClick={() => {
        dispatch(actions.order.selectCoupon(code));
      }}
    >
      <div className="d-flex " style={{ flex: 1 }}>
        <img
          src={"https://picsum.photos/seed/picsum/200/400"}
          className=""
          alt=""
          height="100"
          width="100"
          style={{
            height: "100%",
            width: "auto",
            objectFit: "cover",
            aspectRatio: "3/2",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            // navigate(`/product/${product.slug}`);
          }}
        />
        <div className="d-flex flex-column ms-1" style={{ flex: 1 }}>
          <div
            className=""
            style={{ flex: 1, marginBottom: "-15px" }}
            onClick={() => {
              // navigate(`/product/${product.slug}`);
            }}
          >
            <div className="d-flex justify-content-between">
              <h3 className="h4 m-0">{code}</h3>
              {selectedCouponCode == code && (
                <div
                  className="rounded-circle px-1 pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(actions.order.unSelectCoupon(code));
                  }}
                >
                  <XCircle />
                </div>
              )}
            </div>

            <p
              className=""
              style={{
                fontSize: "75%",
                letterSpacing: "0rem",
                wordSpacing: "0.1rem",
                lineHeight: "0.8rem",
              }}
            >
              {description}
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-end">
            <div className="d-flex gap-1">
              <div className="d-flex justify-content-between">
                <p style={{}} className="h6">
                  Total Discount:
                </p>
              </div>
              <div className="">
                <p className="h6" style={{ fontWeight: "bold" }}>
                  {couponItemObj.totalDiscount} &#2547;
                </p>
              </div>
            </div>

            <div className="d-flex" style={{ flexDirection: "column" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coupon;
