// import { Row } from "react-bootstrap";
import { numComma } from "./../../../../../utils/index";

export default function OrderSummary({
  grossTotal,
  totalSaleDiscount,
  totalCouponDiscount,
  shippingCharge,
  netTotal,
  collapsed,
}) {
  return (
    <div className="d-flex hide_on_collapse">
      <table className="flex1" style={{}}>
        <colgroup>
          <col style={{ width: "" }} />
          <col style={{ width: "" }} />
          <col style={{ width: "500px" }} />
          {/* <col style={{ width: "" }} /> */}
        </colgroup>
        <Row
          label="Regular Price"
          value={numComma(grossTotal)}
          float={"right"}
        />
        <Row
          label="Sale Discount"
          value={"- " + numComma(totalSaleDiscount)}
          float={"right"}
        />
        <Row
          label="Coupon Discount"
          value={"- " + numComma(totalCouponDiscount)}
          float={"right"}
        />
        <Row
          label="Shipping Charge"
          value={numComma(shippingCharge)}
          float={"right"}
        />

        <Row
          label="Net Payable"
          value={"৳ " + numComma(netTotal)}
          bold={true}
          float={"right"}
        />
      </table>
    </div>
  );
}

const Row = ({ label, value, bold, float }) => {
  return (
    <tr className="" style={{ flex: 1, borderBottom: "1px solid #eee" }}>
      <td style={{ textWrap: "nowrap" }}>{label}</td>
      <td style={{ padding: "0 5px" }}>:</td>
      <td>
        {" "}
        <p style={{ fontWeight: bold && "bold", float: float }}>{value}</p>
      </td>
    </tr>
  );
};
