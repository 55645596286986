import axios from "axios";
import * as actionNames from "./actionNames";

export const showCartModal = () => async (dispatch) => {
  return dispatch({
    type: actionNames.showCartModal,
  });
};

export const hideCartModal = () => async (dispatch) => {
  return dispatch({
    type: actionNames.hideCartModal,
  });
};
