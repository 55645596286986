import actionNames from "./actionNames";

// const categoryItem = {
// categoryData: {},
// ..other data related to ui
// };

const initialState = {
  allCategories: {
    time: "<date>",
    data: [],
    loading: false,
  },

  orderedCategories: [],

  featuredCategories: {
    time: "<date>",
    data: [],
    loading: false,
  },

  visitedCategories: {
    categoryItems: {
      "<cat-id>": {
        categoryData: {},
        time: "",
        countOfVisits: 0,
      },
    },
  },

  userBasedSuggestedCategories: {
    time: "date",
    categoryItems: {
      "<cat-id>": "<categoryItem>",
    },
  },

  products: {
    "<cat>": {
      "<filter>": {
        "<sort>": {
          time: "<date>",
          data: [],
        },
      },
      default: {
        default: {
          time: "<date>",
          data: [],
        },
      },
    },
  },
};

let initialStateFromStorage = localStorage.getItem("store.categories");
try {
  initialStateFromStorage = JSON.parse(initialStateFromStorage);
} catch (ex) {
  initialStateFromStorage = null;
}

const saveItem = (newSate) => {
  localStorage.setItem("store.categories", JSON.stringify(newSate));
};

const catArrToObj = (array) => {
  const newObj = {};

  array.forEach((e) => {
    newObj[e.slug] = { ...e };
  });

  return {};
};

export default function (
  state = initialStateFromStorage || initialState,
  action
) {
  let newState = { ...state };
  switch (action.type) {
    case actionNames.fetchAllCategories.success:
      newState = {
        ...state,
        allCategories: {
          ...state.allCategories,
          time: new Date(),
          data: [...action.payload.categories],
          // data: catArrToObj([...action.payload.categories]),
          loading: false,
        },
      };
      saveItem(newState);
      return newState;

    case actionNames.getOrderedCategories.success:
      newState = {
        ...state,
        orderedCategories: action.payload.orderedCategories,
      };
      saveItem(newState);
      return newState;
    default:
      return {
        ...state,
      };
  }
}
