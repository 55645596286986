import actionNames from "./actionNames";
import { localstore as localstoreInit } from "./../../../utils";
// const categoryItem = {
// categoryData: {},
// ..other data related to ui
// };

import { objCreator } from "./utils";

const localstore = localstoreInit("store__categoryPage");

let initialStateFromStorage = localstore.getItem();

const initialState = {
  config: {
    pageSize: 20,
  },
  products: {
    selectedCat: "",
    productsByCat: {
      // "<cat-slug>": {
      //   selectedFilter: "",
      //   dataByFilter: {
      //     "<filter-query>": {
      //       selectedSort: "",
      //       dataBySort: {
      //         "<sort-name>": {
      //           time: "<date>",
      //           noMoreLeft: false,
      //           pageSize: null,
      //           selectedPage: 1,
      //           dataByPage: {
      //             "<page number>": {
      //               data: [],
      //               fetchedAt: "<date>",
      //               loading: false,
      //             },
      //           },
      //         },
      //       },
      //     },
      //   },
      // },
    },
  },
};

export default function (
  state = initialStateFromStorage || initialState,
  // state = initialState,
  // state = initialStateFromStorage,
  action = { payload: {}, type: null }
) {
  const newState = { ...state };

  // console.log(action);
  // console.log(state);
  // let { category, pageNumber, products: data } = action.payload;
  switch (action.type) {
    case actionNames.fetchProducts.pending:
      var {
        category,
        pageNumber,
        products: data,
        noMoreItemsLeft,
      } = action.payload;
      // return { ...initialState };

      if (
        state.products.productsByCat[category.slug]?.dataByFilter["default"]
          ?.dataBySort["default"].dataByPage[pageNumber]
      ) {
        const currentPageData =
          newState.products.productsByCat[category.slug].dataByFilter["default"]
            .dataBySort["default"].dataByPage[pageNumber];

        // currentPageData.data = [];
        // currentPageData.pageNumber = pageNumber;
        // currentPageData.fetchedAt = new Date();
        currentPageData.loading = true;

        localstore.saveItem(newState);
        return {
          ...newState,
        };
      } else if (
        state.products.productsByCat[category.slug]?.dataByFilter["default"]
          ?.dataBySort["default"]
      ) {
        const currentOrderData =
          newState.products.productsByCat[category.slug].dataByFilter["default"]
            .dataBySort["default"];

        currentOrderData.dataByPage[pageNumber] = {
          data: [],
          fetchedAt: new Date(),
          loading: true,
          pageNumber: pageNumber | 1,
        };

        localstore.saveItem(newState);
        return {
          ...newState,
        };
      } else if (
        state.products.productsByCat[category.slug]?.dataByFilter["default"]
          ?.dataBySort["default"]
      ) {
        // when sorting functionality will be added
      } else if (
        state.products.productsByCat[category.slug]?.dataByFilter["default"]
      ) {
        // when filtering functionality will be added
      } else if (!state.products.productsByCat[category.slug]) {
        newState.products.productsByCat[category.slug] = {
          ...objCreator.newCategory(),
        };

        localstore.saveItem(newState);
        return {
          ...newState,
        };
      }
    case actionNames.fetchProducts.success:
      var {
        category,
        pageNumber,
        products: data,
        noMoreItemsLeft,
      } = action.payload;
      if (
        state.products.productsByCat[category.slug]?.dataByFilter["default"]
          ?.dataBySort["default"].dataByPage[pageNumber]
      ) {
        const currentPageData =
          newState.products.productsByCat[category.slug].dataByFilter["default"]
            .dataBySort["default"].dataByPage[pageNumber];

        const currentNoMoreItemsLeft =
          newState.products.productsByCat[category.slug].dataByFilter["default"]
            .dataBySort["default"].noMoreItemsLeft;

        if (currentNoMoreItemsLeft == false) {
          newState.products.productsByCat[category.slug].dataByFilter[
            "default"
          ].dataBySort["default"].noMoreItemsLeft = noMoreItemsLeft;
        }

        currentPageData.data = [...data];
        currentPageData.fetchedAt = new Date();
        currentPageData.loading = false;
        currentPageData.pageNumber = pageNumber;

        localstore.saveItem(newState);
        return {
          ...newState,
        };
      } else if (
        state.products.productsByCat[category.slug]?.dataByFilter["default"]
          ?.dataBySort["default"]
      ) {
        const currentOrderData =
          newState.products.productsByCat[category.slug].dataByFilter["default"]
            .dataBySort["default"];

        newState.products.productsByCat[category.slug].dataByFilter[
          "default"
        ].dataBySort["default"].noMoreItemsLeft = noMoreItemsLeft;

        currentOrderData.dataByPage[pageNumber] = {
          data: [...data],
          fetchedAt: new Date(),
          loading: false,
          pageNumber: pageNumber,
        };

        localstore.saveItem(newState);
        return {
          ...newState,
        };
      }

    default:
      return {
        ...state,
      };
  }
}
