const defaultSelectedSort = "default";
const defaultSelectedFilter = "default";

const newSort = (data, pageNumber) => {
  return {
    time: new Date(),
    noMoreItemsLeft: false,
    selectedPage: 1,
    dataByPage: {
      [pageNumber | 1]: {
        data: data ? [...data] : [],
        fetchedAt: new Date(),
        loading: true,
        pageNumber: pageNumber | 1,
      },
    },
  };
};

const newFilter = (data) => {
  return {
    selectedSort: defaultSelectedSort,
    dataBySort: {
      [defaultSelectedSort]: newSort(data, 1),
    },
  };
};

const newCategory = (data) => {
  return {
    selectedFilter: defaultSelectedFilter,
    dataByFilter: {
      [defaultSelectedFilter]: newFilter(data),
    },
  };
};

export const objCreator = {
  newSort,
  newFilter,
  newCategory,
};

export default { objCreator };
