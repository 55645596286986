import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Banner({
  title,
  description,
  buttonText,
  buttonUrl,
  bannerImgUrl,
  bgColor,
  bgOpacity,
}) {
  const [isHovered, setIsHovered] = useState();
  const navigation = useNavigate();
  return (
    <div
      onMouseOver={() => {
        // console.log("in");
        setIsHovered(true);
      }}
      onMouseOut={() => {
        // console.log("out");
        setIsHovered(false);
      }}
      className="my-2"
      style={{
        position: "relative",
        overflow: "hidden",
        color: "#fff",
        padding: "50px 20px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "300px",
        borderRadius: "5px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div
        className=""
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${bannerImgUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          transition: "transform 0.5s ease",
          zIndex: 1,
          transform: isHovered ? "scale(1.3)" : "scale(1.0)",
        }}
      ></div>

      <div
        className=""
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: bgColor,
          opacity: bgOpacity,

          zIndex: 2,
        }}
      ></div>

      <div style={{ zIndex: 2, color: "white" }}>
        {" "}
        <h3 style={{ fontSize: "1.6rem", marginBottom: "10px" }}>{title}</h3>
        <h4 style={{ fontSize: "1rem", marginBottom: "20px" }}>
          {description}
        </h4>
        <button
          className="bg-theme-light-on-hover bg-theme"
          onClick={() => navigation(buttonUrl)}
          style={{
            padding: "10px 20px",
            fontSize: "1.2rem",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            transition: "background-color 0.3s",
          }}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
}

// export default function Banner({
//   title,
//   description,
//   buttonText,
//   buttonUrl,
//   bannerImgUrl,
// }) {
//   const navigation = useNavigate();
//   return (
//     <div
//       className="banner my-2"
//       style={{
//         position: "relative", // To position the overlay
//         overflow: "hidden", // To contain the zoom effect within the banner
//         color: "#fff", // White text color
//         padding: "50px 20px", // Padding for spacing
//         textAlign: "center", // Center-align text
//         display: "flex", // Flexbox for centering content
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//         minHeight: "300px", // Minimum height for the banner
//         borderRadius: "5px", // Rounded corners
//         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
//       }}
//     >
//       <div
//         className="banner-background"
//         style={{
//           position: "absolute",
//           top: 0,
//           left: 0,
//           width: "100%",
//           height: "100%",
//           backgroundImage: `url(${bannerImgUrl})`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           //   backgroundColor: "rgba(0, 0, 0, 0.5)",
//           opacity: 0.7, // Adjust the opacity
//           transition: "transform 0.5s ease", // Smooth transition for zoom
//           //   zIndex: -1, // Behind the content
//         }}
//       ></div>

//       <div style={{ zIndex: 2, color: "black" }}>
//         {" "}
//         <h3 style={{ fontSize: "1.6rem", marginBottom: "10px" }}>{title}</h3>
//         <h4 style={{ fontSize: "1rem", marginBottom: "20px" }}>
//           {description}
//         </h4>
//         <button
//           className="bg-theme-light-on-hover bg-theme"
//           onClick={() => navigation(buttonUrl)}
//           style={{
//             padding: "10px 20px",
//             fontSize: "1.2rem",
//             // backgroundColor: "#ff6600", // Button color
//             color: "#fff", // White text
//             border: "none",
//             borderRadius: "5px",
//             cursor: "pointer",
//             transition: "background-color 0.3s",
//           }}
//           // onMouseOver={(e) => (e.target.style.backgroundColor = "#ff4500")}
//           // onMouseOut={(e) => (e.target.style.backgroundColor = "#ff6600")}
//         >
//           {buttonText}
//         </button>
//       </div>
//     </div>
//   );
// }
