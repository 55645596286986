import actionNames from "../actionNames";

export const checkCouponCompatibility = (coupon, product) => {
  let success = true;
  let message = null;

  if (coupon.product_ids && coupon.product_ids.length > 0) {
    // the coupon only supports specifc products
    if (coupon.product_ids.includes(product.id)) {
      success = true;
    } else {
      success = false;
      message = `this couopn does not support the product`;
    }
  } else if (
    coupon.excluded_product_ids &&
    coupon.excluded_product_ids.length > 0
  ) {
    // the coupon only ecludes specific products
    if (coupon.excluded_product_ids.includes(product.id)) {
      success = false;
      message = "the coupon do not support this product";
    }
  } else if (
    coupon.product_categories &&
    coupon.product_categories.length > 0
  ) {
    // the coupon only supports specifc product categories
    product.categories.forEach((category) => {
      if (coupon.product_categories.includes(category.id)) {
        success = true;
      } else {
        success = false;
        message = `this couopn does not support the follwing category: ${category.name}`;
      }
    });
  } else if (
    coupon.excluded_product_categories &&
    coupon.excluded_product_categories.length > 0
  ) {
    // the coupon only ecludes specific products categories
    product.categories.forEach((category) => {
      if (coupon.excluded_product_categories.includes(category.id)) {
        success = false;
        message = `this coupon does not support the following category: ${category.name}`;
      }
    });
  } else if (coupon.exclude_sale_items) {
    // the coupon does not support for sale items
  } else {
    // the coupon supports everything
  }
  return { success, message };
};

export const getCouponCheckedCartList = (coupon, cartItemsObj) => {
  // add coupon discount related fields in every cart item
  //   console.log({ coupon, cartItemsObj });
  // checkCouponCompatibility

  //   if (!coupon) return cartItemsObj;

  const newCartItemsObj = {};

  Object.values(cartItemsObj).forEach((cartItem) => {
    const validityCheck = coupon
      ? checkCouponCompatibility(coupon.couponData, cartItem.productData)
      : { success: false };
    // console.log("----validityCheck----");
    // console.log(validityCheck);

    const productData = { ...cartItem.productData };
    const slug = productData.slug;

    const grossTotal = cartItem.quantity * productData.regular_price;
    let totalCouponDiscount = 0;
    let totalSaleDiscount = 0;
    let netTotal = 0;
    const couponVerificationMessage = validityCheck.message;
    let isCouponApplied = false;

    totalSaleDiscount = grossTotal - cartItem.quantity * productData.price;

    if (validityCheck.success && coupon.couponData.discount_type == "percent") {
      totalCouponDiscount =
        (cartItem.quantity *
          productData.price *
          Number(coupon.couponData.amount)) /
        100;
      isCouponApplied = true;
    } else if (
      validityCheck.success &&
      coupon.couponData.discount_type == "fixed_product"
    ) {
      // no logic added for coupon type fixed_product
    } else if (
      validityCheck.success &&
      coupon.couponData.discount_type == "fixed_cart"
    ) {
      totalCouponDiscount = 0;
      isCouponApplied = true;
    }
    netTotal = grossTotal - totalCouponDiscount - totalSaleDiscount;
    // netTotal = grossTotal - 100;

    newCartItemsObj[slug] = {
      ...cartItem,
      // productData: { ...cartItemsObj[slug].productData },

      grossTotal,

      totalSaleDiscount,
      totalCouponDiscount,

      netTotal,

      isCouponApplied,
      couponVerificationMessage,
    };
  });

  return { ...newCartItemsObj };
};

export const checkCouponValidity = (coupon, currentUser, cartGrossTotal) => {
  const usageByCurrentUser = 0;

  // coupon total limit
  if (coupon.usage_limit && coupon.usage_count >= coupon.usage_limit) {
    return {
      success: false,
      type: actionNames.addCoupon.fail.limit_expired,
      payload: { errorMessage: "coupon limit expired", code: coupon.code },
    };
  }

  // coupon usage limit per user
  else if (
    coupon.usage_limit_per_user &&
    usageByCurrentUser >= coupon.usage_limit_per_user
  ) {
    return {
      success: false,
      type: actionNames.addCoupon.fail.limit_per_user_expired,
      payload: {
        errorMessage: `The maximum quota per user to use this coupon is ${coupon.usage_limit_per_user} which are already exceeded`,
        code: coupon.code,
      },
    };
  }

  // date expiration check
  else if (new Date(coupon.dateExpires) > new Date()) {
    return {
      success: false,
      type: actionNames.addCoupon.fail.date_expired,
      payload: {
        errorMessage: `The coupon was valid until ${new Date(
          coupon.dateExpires
        ).toDateString()}`,
        code: coupon.code,
      },
    };
  } else {
    return { success: true };
  }
};

export default {
  checkCouponCompatibility,
  getCouponCheckedCartList,
  checkCouponValidity,
};
