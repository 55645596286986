import React from "react";
import { Link } from "react-router-dom";

function CategoryContainer({ children }) {
  return <div className={`categoryItemContainer B`}>{children}</div>;
}

function CategoryItem({ category }) {
  return (
    <div className={`d-flex`}>
      <Link
        to={`/product-category/${category.slug}`}
        className="d-flex align-items-center m-1 p-1 rounded hover CategoryItem"
        style={{
          flex: 1,
          cursor: "pointer",
          border: "1px solid #eee",
          textDecoration: "none",
        }}
      >
        <img
          src={category.image?.src}
          className="rounded"
          alt=""
          style={{
            height: "40px",
            width: "40px",
            objectFit: "cover",
            marginRight: "10px",
          }}
        />
        <p style={{ color: "black" }}>{category.name}</p>
      </Link>
    </div>
  );
}

export default {
  Item: CategoryItem,
  Container: CategoryContainer,
};
