import params from "./params";
import ldb from "localdata";

export { default as params } from "./params";
export { default as localstore } from "./localstore";
export { default as getReadableTime } from "./readableTime";
// export {
//   getCouponSupportedProducts,
//   // checkCouponValidity,
//   getCouponTotalDiscount,
//   // getCouponCheckedCartList,
// } from "./coupon";

export const calculateCouponDiscounts = (coupon, cartItems) => {
  let subTotal = 0;
  Object.values(cartItems).forEach((obj) => {
    // @ts-ignore
    subTotal = subTotal + Number(obj.productData.price) * obj.quantity;
  });

  let discount = 0;

  if (coupon.discount_type == "percent") {
    discount = subTotal * (Number(coupon.amount) / 100);
  } else if (coupon.discount_type == "fixed_cart") {
    discount = Number(coupon.amount);
    if (discount > subTotal) {
      discount = subTotal;
    }
  }

  return discount;
};

export const getLocationFullName = ({
  district,
  division,
  city,
  suburb,
  postcode,
}) => {
  let title = "";

  // suburb
  title = title + suburb;

  // "city": "Natrokuna Sadar",
  if (city.toLowerCase() != suburb.toLowerCase()) {
    title = `${title}, ${city}`;
  }

  // "coverage": true,
  // "delivery_type": "Home Delivery",
  // "pickup": true,

  // "division" & "district"
  if (district.toLowerCase() != division.toLowerCase()) {
    title = `${title}, ${district}, ${division}`;
  } else {
    title = `${title}, ${division}`;
  }

  if (postcode) {
    title = `${title} - ${postcode}`;
  }

  return title;
};
// export const getLocationFullName = (address) => {
//   if (address.full_address) {
//     return address.full_address;
//   }

//   if (address.area_type == "division") {
//     return address.name;
//   } else if (address.area_type == "district") {
//     return `${address.name}, ${address.division}`;
//   } else if (address.area_type == "suburban_area") {
//     return `${address.name}, ${address.district}, ${address.division}`;
//   } else {
//     if (address.district == address.division) {
//       return `${address.name}, ${address.district}`;
//     } else {
//       if (!address.sub_district || address.sub_district == address.district) {
//         return `${address.name}, ${address.district}, ${address.division}`;
//       } else {
//         return `${address.name}, ${address.sub_district}, ${address.district}, ${address.division}`;
//       }
//     }
//   }
// };

export const numComma = (num, decimals = 0) => {
  const commaLessNum = num.toString().split(",").join("");

  // console.log({
  //   num,
  //   com: Number(commaLessNum)
  //     ?.toFixed(decimals)
  //     ?.toString()
  //     .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  // });
  return (
    // num &&
    Number(commaLessNum)
      // commaLessNum
      ?.toFixed(decimals)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
};

export function nestMenuItems(menuItems, parentKey, pk) {
  const nestedMenu = [];

  // Create a map to easily find items by ID
  const itemMap = {};
  menuItems.forEach((item) => {
    item.childs = [];
    itemMap[item[pk]] = { ...item };
  });

  // Iterate through the items to build the nested structure
  menuItems.forEach((item) => {
    if (item[parentKey] !== "0") {
      // If it's a child, add it to its parent's "childs" array
      itemMap[item[parentKey]].childs.push(item);
    } else {
      // If it's a top-level parent, add it to the nestedMenu array
      nestedMenu.push(item);
    }
  });

  return nestedMenu;
}

export function modifyObject(originalObject, keyMappings) {
  // Create a new object to store the shortened version
  const shortenedObject = {};

  // Iterate through the key mappings and copy the values with new keys
  for (const originalKey in keyMappings) {
    // Check if the original key exists in the original object before copying
    if (originalObject.hasOwnProperty(originalKey)) {
      const newKey = keyMappings[originalKey];
      shortenedObject[newKey] = originalObject[originalKey];
    }
  }

  // Return the shortened object
  return shortenedObject;
}

// export function localstore(key) {
//   function isJson(input) {
//     if (Array.isArray(input)) {
//       return true;
//     } else if (typeof input === "object" && input !== null) {
//       return true;
//     } else {
//       return false;
//     }
//   }

//   function parseIfJson(input) {
//     let parsedInput;
//     try {
//       parsedInput = JSON.parse(input);
//     } catch (ex) {
//       parsedInput = input;
//     }
//     return parsedInput;
//   }
//   return {
//     saveItem: function (data) {
//       isJson(data) ? ldb.set(key, JSON.stringify(data)) : ldb.set(key, data);
//       // ldb.set(key, data);
//     },
//     getItem: function () {
//       // return ldb.get(key);
//       try {
//         const outputData = localStorage.getItem(key);
//         if (!outputData) return null;
//         return parseIfJson(outputData);
//       } catch (ex) {
//         return null;
//       }
//     },
//     deleteItem: function () {
//       ldb.delete(key);
//     },
//   };
// }

export default {
  // localstore,
  nestMenuItems,
  getLocationFullName,
  calculateCouponDiscounts,
  params,
  numComma,
  modifyObject,
};
