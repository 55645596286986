import React, { useEffect, useRef } from "react";

import style from "../index.module.css";
import { useSelector, useDispatch } from "react-redux";
// import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
// import { useNavigate } from "react-router-dom";
import actions, { order } from "./../../../../store/actions";
// import * as selectors from "../../../../store/selectors";
// import CartTotal from "./CartTotal";

import CartItem from "./CartItem";

// import ShippingInfoSection from "./ShippingInfoSection";
// import CouponSection from "./CouponSection";
// import UserInfo from "./UserInfoSection";
// import SummarySection from "./SummarySection";
import { numComma } from "../../../../utils";
import { Link, useNavigate } from "react-router-dom";

export default function CartPopup({ handleModalClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [currentAccordion, setCurrentAccordion] = React.useState(null);
  // const [allAccordionsCompleted, setAllAccordionsCompleted] =
  //   React.useState(false);
  // console.log({ allAccordionsCompleted });
  // const productAccordion = useRef(null);
  // const shippingAccordion = useRef(null);
  // const couponAccordion = useRef(null);
  // const userInfoAccordion = useRef(null);
  // const summaryAccordion = useRef(null);

  // const clickNext = () => {
  //   if (
  //     !shippingAccordion.current ||
  //     !couponAccordion.current ||
  //     // !productAccordion.current ||
  //     !userInfoAccordion.current
  //   )
  //     return;
  //   console.log({ currentAccordion });
  //   let accordionToOpen;
  //   let selectedAccordionName;

  //   if (!currentAccordion) {
  //     selectedAccordionName = "shipping";
  //     accordionToOpen = shippingAccordion;
  //   } else if (currentAccordion == "product") {
  //     selectedAccordionName = "shipping";
  //     accordionToOpen = shippingAccordion;
  //   } else if (currentAccordion == "shipping") {
  //     selectedAccordionName = "coupon";
  //     accordionToOpen = couponAccordion;
  //   } else if (currentAccordion == "coupon") {
  //     selectedAccordionName = "userInfo";
  //     accordionToOpen = userInfoAccordion;
  //   } else if (currentAccordion == "userInfo") {
  //     setAllAccordionsCompleted(true);
  //     selectedAccordionName = null;
  //     accordionToOpen = summaryAccordion;
  //   } else if (currentAccordion == "Summary") {
  //     selectedAccordionName = null;
  //     accordionToOpen = summaryAccordion;
  //   }

  //   let event = document.createEvent("HTMLEvents");
  //   event.initEvent("click", true, false);
  //   accordionToOpen.current
  //     ?.querySelector(".accordion-header")
  //     .querySelector("button")
  //     .dispatchEvent(event);
  //   setCurrentAccordion(selectedAccordionName);
  // };

  // React.useEffect(() => {
  //   setCurrentAccordion(null);
  // }, []);

  const cartItems = useSelector((state) =>
    Object.values(state.order.cartItems)
  ).sort((a, b) => (a.addedAt > b.addedAt ? -1 : 1));

  const { netTotal, grossTotal, totalCouponDiscount, shippingCharge } =
    useSelector((s) => s.order.charges);

  const shippingAddresses = useSelector(
    (state) => state.order.shippingAddress.allAddresses
  );

  // const placingOrder = useSelector(
  //   (state) => state.order.actions.createPurchaseOrder.loading
  // );

  const { cartItemCount, actions: orderActions } = useSelector(
    (state) => state.order
  );
  React.useEffect(() => {
    if (!shippingAddresses || Object.values(shippingAddresses).length == 0) {
      dispatch(actions.order.getShippingAddresses());
    }
  }, []);

  // const selectedAddress = selectors.order.getSelectedAddress(
  //   useSelector((s) => s)
  // );
  // // console.log({ selectedAddress });

  // const customShippingAddress = selectors.order.getCustomShippingAddress(
  //   useSelector((s) => s)
  // );

  const orderDetailsElement = useRef(null);

  // console.log(orderDetailsElement);

  // useEffect(() => {
  //   if (orderDetailsElement?.current) {
  //     console.log(orderDetailsElement.current.classList.remove("inactive"));
  //   }
  // }, []);

  const onDetailsIconClicked = () => {
    if (orderDetailsElement?.current) {
      // console.log(orderDetailsElement.current.classList.includes("inactive"));

      const isActive = [...orderDetailsElement.current.classList].includes(
        "active"
      );

      if (isActive) {
        orderDetailsElement.current.classList.remove("active");
      } else {
        orderDetailsElement.current.classList.add("active");
      }
    }
  };

  return (
    <div
      className={`container shadow bg-light d-flex flex-column cart-popup ${style.cartPopupModal}`}
      style={{}}
    >
      <div
        className="d-flex p-1 mt-1"
        style={{ cursor: "default", alignItems: "end" }}
      >
        <div style={{ cursor: "pointer" }}>
          <button
            className="btn btn-theme "
            style={{ color: "white" }}
            onClick={handleModalClose}
          >
            &#10005; Close
          </button>
        </div>
        <div className="px-2" style={{ flex: 1 }}>
          {cartItemCount > 0 && (
            <div className="">
              <div className="d-flex justify-content-between">
                <p style={{}} className="small">
                  {/* Pay:{" "} */}
                  Subtotal:{" "}
                  {grossTotal + shippingCharge != netTotal && (
                    <span
                      className="small"
                      style={{ textDecoration: "line-through", color: "red" }}
                    >
                      {numComma(grossTotal + shippingCharge)}
                    </span>
                  )}
                </p>
              </div>
              <div className="">
                <p className="h5" style={{ fontWeight: "bold" }}>
                  {numComma(netTotal)} &#2547;
                </p>
              </div>
            </div>
          )}
        </div>
        <div
          className="me-1 px-2 B d-none"
          style={{ cursor: "pointer" }}
          onClick={onDetailsIconClicked}
        >
          <div className="d-flex flex-column mb-1">
            <div className="d-flex flex-center " style={{}}>
              <i
                className=""
                style={{
                  marginBottom: "-10px",
                  marginTop: "",
                  fontSize: "140%",
                }}
              >
                &#x2139;
              </i>
            </div>
            <p style={{ fontSize: "70%", flex: 1 }} className="">
              details
            </p>
          </div>
        </div>
        {/* <div className="" style={{ cursor: "pointer", height: "40px" }}>
          {currentAccordion == "summary" || allAccordionsCompleted ? (
            <button
              className="btn btn-theme"
              style={{ color: "white" }}
              onClick={() => {
                console.log("confirm");
              }}
            >
              Place Order &#x2713;
            </button>
          ) : (
            <button
              className="btn btn-theme"
              style={{ color: "white" }}
              onClick={clickNext}
            >
              next &#x3e;
            </button>
          )}
        </div> */}
      </div>

      <div
        className="mx-1 d-flex order-details B gap-1"
        ref={orderDetailsElement}
      >
        <PriceItem title={`Gross Total`} value={grossTotal} prefix="" />
        <PriceItem title="Sale Discount" value={12345} prefix="-" />
        <PriceItem title="Coupon Discount" value={12345} prefix="-" />
        <PriceItem title="Shipping Charge" value={12345} prefix="" />
      </div>

      <div className="pe-1" style={{ overflowY: "scroll" }}>
        {cartItemCount > 0 ? (
          cartItems.map((cartObj, i) => (
            <CartItem
              quantity={cartObj.quantity}
              price={cartObj.productData.price}
              product={cartObj.productData}
              cartObj={cartObj}
              key={i}
            >
              {cartObj.productData.name}
            </CartItem>
          ))
        ) : cartItems.length == 0 &&
          orderActions.createPurchaseOrder.currentPurchase?.eid &&
          orderActions.createPurchaseOrder.currentPurchase?.createdAt &&
          new Date(
            orderActions.createPurchaseOrder.currentPurchase?.createdAt
          ).getTime() +
            1000 * 60 * 10000 >
            new Date().getTime() ? (
          <div>
            <>thank for your order</>
            <div>track your order</div>
            <Link
              to={`order/${orderActions.createPurchaseOrder.currentPurchase?.eid}`}
            >
              track your order
            </Link>
          </div>
        ) : (
          <>please selact product</>
        )}
      </div>

      <div className="py-2">
        <button
          className="btn btn-theme "
          style={{ color: "white", width: "100%" }}
          onClick={() => {
            handleModalClose();
            navigate(`/checkout/shipping`);
          }}
        >
          Checkout
        </button>
      </div>

      {/* <Accordion defaultActiveKey="00" style={{}}>
        <ShippingInfoSection
          handleClick={() => {
            if (currentAccordion == "shipping") {
              setCurrentAccordion(null);
            } else {
              setCurrentAccordion("shipping");
            }
          }}
          eventKey="10"
          itemRef={shippingAccordion}
        />

        <CouponSection
          handleClick={() => {
            if (currentAccordion == "coupon") {
              setCurrentAccordion(null);
            } else {
              setCurrentAccordion("coupon");
            }
          }}
          eventKey="20"
          itemRef={couponAccordion}
        />

        <UserInfo
          handleClick={() => {
            if (currentAccordion == "userInfo") {
              setCurrentAccordion(null);
            } else {
              setCurrentAccordion("userInfo");
            }
          }}
          eventKey="30"
          itemRef={userInfoAccordion}
        />
        {cartItemCount > 0 && (
          <SummarySection
            handleClick={() => {
              if (currentAccordion == "summary") {
                setCurrentAccordion(null);
              } else {
                setCurrentAccordion("summary");
              }
            }}
            eventKey="40"
            itemRef={summaryAccordion}
          />
        )}
      </Accordion> */}
    </div>
  );
}

const PriceItem = ({ title = "title", value = 123456, prefix }) => {
  return (
    <div
      className="px-2 pt-1 d-flex flex-column justify-content-between flex1"
      style={{}}
    >
      {/* <div className="d-flex justify-content-between">
      </div> */}
      <p style={{ fontSize: "80%", lineHeight: "1rem" }} className="flex1">
        <div className="" style={{ width: "10px" }}>
          {title}:
        </div>
      </p>
      <div className="">
        <p className="" style={{ fontWeight: "", fontStyle: "" }}>
          {prefix}
          {value}
          <span className="bold" style={{ fontSize: "70%" }}>
            {" "}
            &#2547;
          </span>
        </p>
      </div>
    </div>
  );
};
