export default function ({ imageObj, onSelectImage = () => {} }) {
  return (
    <img
      style={{
        aspectRatio: "1/1",
        maxHeight: "150px",
        width: "auto",
      }}
      key={imageObj.id}
      src={imageObj.src}
      alt={`Thumbnail ${imageObj.id}`}
      className="w-full object-cover cursor-pointer transition-all hover:opacity-75 lg:flex-1"
      onClick={onSelectImage}
      //   onClick={() => setSelectedImage(imageObj)}
    />
  );
}
