import React from "react";
import { numComma } from "../../../../../utils";

export default function Amount({
  title = "",
  subTitle,
  amount = 0,
  disabledAmount,
  amountPrefix,
  minWidth,
}) {
  return (
    <div
      className="borderr px-1 d-flex align-items-end"
      style={{ minWidth: minWidth, justifyContent: "end" }}
    >
      <div className="d-flex" style={{}}>
        {(title || disabledAmount) && (
          <div
            style={{ fontSize: "100%", float: "right", marginBottom: "" }}
            className="small p-0 m-0 pe-1 d-flex"
          >
            {title}
            {disabledAmount && (
              <div
                className="small p-0"
                style={{
                  textDecoration: "line-through",
                  color: "red",
                  marginBottom: "-5px",
                }}
              >
                {numComma(disabledAmount)}
              </div>
            )}
          </div>
        )}
      </div>
      {subTitle && (
        <p
          className="text-muted"
          style={{ lineHeight: "90%", fontSize: "80%" }}
        >
          {subTitle}
        </p>
      )}
      <div className="" style={{ float: "right" }}>
        <p
          className="h5"
          style={{
            fontWeight: "bold",
            whiteSpace: "nowrap",
            float: "right",
            fontSize: "100%",
          }}
        >
          {amountPrefix}
          {numComma(amount)}
        </p>
      </div>
    </div>
  );
}
