const baseName = "categoryPage";

export const fetchProducts = {
  pending: `${baseName}/fetchProducts/pending`,
  success: `${baseName}/fetchProducts/success`,
  fail: `${baseName}/fetchProducts/fail`,
};

export const selectCategory = `${baseName}/selectCategory`;
export const selectPageNumber = `${baseName}/selectPageNumber`;

export default { fetchProducts, selectCategory, selectPageNumber };
