import {
  getFeaturedCategories,
  getFeaturedEarphones,
  getFeaturedSmartwatches,
  getFeaturedProducts,
} from "./actionNames";
const initialState = {
  featuredCategories: [],
  featuredEarphones: {
    products: [],
    // featuredCategories: [],
  },
  featuredSmartwatches: {
    products: [],
    // featuredCategories: [],
  },

  featuredProducts: {
    products: null,
  },

  banner: {
    images: [],
  },
  newArrival: {
    products: [],
  },

  usedMobilePhone: {
    products: [],
  },
  newMobilePhones: {
    products: [],
  },
};

let initialStateFromStorage = localStorage.getItem("store.homePage");
try {
  initialStateFromStorage = JSON.parse(initialStateFromStorage);
} catch (ex) {
  initialStateFromStorage = null;
}

const saveItem = (newSate) => {
  localStorage.setItem("store.homePage", JSON.stringify(newSate));
};

export default function (
  state = initialStateFromStorage || initialState,
  action
) {
  let newState = { ...state };
  switch (action.type) {
    case getFeaturedCategories.success:
      return { ...state, featuredCategories: [...action.payload.categories] };
    case getFeaturedEarphones.success:
      newState = {
        ...state,
        featuredEarphones: {
          products: [...action.payload.earphones],
        },
      };
      saveItem(newState);
      return { ...newState };
    case getFeaturedSmartwatches.success:
      newState = {
        ...state,
        featuredSmartwatches: { products: [...action.payload.smartwatches] },
      };
      saveItem(newState);
      return { ...newState };

    case getFeaturedProducts.pending:
      newState = {
        ...state,
        featuredProducts: {
          ...state.featuredProducts,
          loading: true,
        },
      };
      saveItem(newState);
      return { ...newState };

    case getFeaturedProducts.success:
      newState = {
        ...state,
        featuredProducts: {
          ...state.featuredProducts,
          products: [...action.payload.products],
          loading: false,
        },
      };
      saveItem(newState);
      return { ...newState };

    default:
      return { ...state };
  }
}
