const baseName = "productPage";

export const addToVisitedProduct = `${baseName}/addToVisitedProduct`;

export const getProductWithSlug = {
  pending: `${baseName}/getProductWithSlug/pending`,
  success: `${baseName}/getProductWithSlug/success`,
  fail: `${baseName}/getProductWithSlug/fail`,
};

export const getRelatedProducts = {
  pending: `${baseName}/getRelatedProducts/pending`,
  success: `${baseName}/getRelatedProducts/success`,
  fail: `${baseName}/getRelatedProducts/fail`,
};
