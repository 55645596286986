import Showcase from "./Showcase";
import Section2 from "./Section2";
import ComingSoonPage from "../../common/ComingSoonPage";
export default function GearskinPage() {
  return (
    // <div className="">
    //   <Showcase />
    //   <Section2 />
    // </div>
    <ComingSoonPage />
  );
}
