import Carousel from "react-bootstrap/Carousel";

import dummyImg from "./dummy-banner.png";
import { useEffect, useState } from "react";
import axios from "axios";

const desktopSlides = [
  {
    imageUrl:
      "https://www.gearmadebd.com/wp-content/uploads/2022/09/Gearmade-web-banner.jpg",
  },
  {
    imageUrl:
      "https://www.gearmadebd.com/wp-content/uploads/2021/12/banner_wave-1.jpg",
  },
  {
    imageUrl:
      "https://www.gearmadebd.com/wp-content/uploads/2021/12/Black-Flat-Minimalist-Accounting-Business-Website-1.png",
  },
];

const mobileSlides = [
  {
    imageUrl:
      "https://www.gearmadebd.com/wp-content/uploads/2022/09/Gearmade-web-banner.jpg",
  },
  {
    imageUrl:
      "https://www.gearmadebd.com/wp-content/uploads/2021/12/banner_wave-1.jpg",
  },
  {
    imageUrl:
      "https://www.gearmadebd.com/wp-content/uploads/2021/12/Black-Flat-Minimalist-Accounting-Business-Website-1.png",
  },
];

export default function ({ deviceType }) {
  const [slides, setSlides] = useState();

  const fetchBanners = async () => {
    let slidesData = await axios
      .get("https://impactzone2.naymkhan.com/wp-json/wp/v2/slider")
      .then((res) => res.data.map((e) => ({ ...e.acf })));
    // console.log({ slidesData });
    const imageUrlsByIds = {};
    await axios
      .get("https://impactzone2.naymkhan.com/wp-json/wp/v2/media", {
        params: {
          include: slidesData.map((e) => e.background_image).join(","),
        },
      })
      .then((res) =>
        res.data.forEach((e) => {
          imageUrlsByIds[e.id] = e.link;
        })
      );
    // console.log({ media });
    // console.log({ imageUrlsByIds });

    slidesData = slidesData.map((e) => ({
      ...e,
      background_image: imageUrlsByIds[e.background_image],
    }));
    console.log({ slidesData });
    setSlides(slidesData);
  };

  console.log({ slides });
  useEffect(() => {
    fetchBanners();
  }, []);

  return (
    <Carousel style={{ height: deviceType == "mobile" && "80vh" }} className="">
      {deviceType == "mobile"
        ? mobileSlides.map(({ imageUrl }) => (
            <Carousel.Item>
              <img
                className="d-block w-100 sliderImg"
                // src={dummyImg}
                src={imageUrl}
                alt="First slide"
                style={{ height: "80vh" }}
              />
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          ))
        : desktopSlides.map(({ imageUrl }) => (
            <Carousel.Item>
              <img
                className="d-block w-100 sliderImg"
                // src={dummyImg}
                src={imageUrl}
                alt="First slide"
                style={{
                  //  height: "60vh"
                  maxHeight: "600px",
                }}
              />
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          ))}
      {/* <Carousel.Item>
        <img
          className="d-block w-100 sliderImg"
          src={dummyImg}
          alt="First slide"
          style={{ height: "80vh" }}
        />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 sliderImg"
          src={dummyImg}
          alt="First slide"
          style={{ height: "80vh" }}
        />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 sliderImg"
          src={dummyImg}
          alt="First slide"
          style={{ height: "80vh" }}
        />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item> */}

      {/* {deviceType == "mobile"
        ? mobileSlides.map((e, i) => <MobileSlide key={i} img={e.imageUrl} />)
        : desktopSlides.map((e, i) => (
            <DesktopSlide key={i} img={e.imageUrl} />
          ))} */}
    </Carousel>
  );
}

const DesktopSlide = ({ img }) => {
  return (
    <Carousel.Item>
      <img
        className="d-block w-100 sliderImg"
        src="https://picsum.photos/seed/picsum/200/300"
        alt="First slide"
        style={{ height: "80vh" }}
      />
      <Carousel.Caption></Carousel.Caption>
    </Carousel.Item>
  );
};

const MobileSlide = ({ img }) => {
  return (
    <Carousel.Item>
      <img
        className="d-block w-100 sliderImg"
        src="https://picsum.photos/seed/picsum/200/300"
        alt="First slide"
        style={{ height: "80vh" }}
      />
      <Carousel.Caption></Carousel.Caption>
    </Carousel.Item>
  );
};
