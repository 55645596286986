import React from "react";
import logo from "./logo.png";

import SearchBar from "./Search";

import Cart from "./Cart";

import { Link } from "react-router-dom";
import { useNavigate, useLocation, useHistory } from "react-router-dom";
import NavItem from "./NavItem";

import BottomNav from "./BottomNav";

import {
  Fire,
  MenuButtonFill,
  ClockHistory,
  Bezier,
  Person,
  List as DrawerIcon,
} from "react-bootstrap-icons";

import NavPopup from "../NavPopup";

function Top() {
  const navigate = useNavigate();
  return (
    <nav
      id="header"
      className=""
      style={{
        // flex: 1,
        // minHeight: "50px",
        backgroundColor: "#fff",
        width: "100%",
        boxShadow: "0 1px 12px rgb(0 0 0/8%)",
        // zIndex: 100,
        // bottom: 0,
      }}
    >
      <div className="px-1 d-flex flex-column container ">
        <div
          className="d-flex flex-direction-column px-2"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            // maxWidth: "70%",
          }}
        >
          <div
            className="d-flex align-items-center py-1 flex1"
            style={{ maxWidth: "65%" }}
          >
            <Link to="/" className="" style={{ maxWidth: "100%" }}>
              <img
                src={logo}
                alt=""
                style={{
                  width: "auto",
                  // height: "100%",
                  // maxWidth: "250px",
                  // maxWidth: "300px",
                  maxHeight: "60px",
                  // maxWidth: "80%",
                  maxWidth: "100%",
                }}
              />
            </Link>
          </div>

          <div
            className="d-none d-md-block d-lg-block d-xl-block"
            style={{ flex: 3 }}
          >
            <div
              className="d-none d-md-block d-lg-block d-xl-block"
              style={{ flex: 1 }}
            >
              {/* <SearchBar /> */}
              <div
                className="d-flex py-2"
                style={{ flexWrap: "wrap", justifyContent: "space-evenly" }}
              >
                <MenuItem
                  url="/product-category/headphones"
                  title={"Headphones"}
                />
                <MenuItem
                  url="/product-category/smartwatch"
                  title={"Smartwatches"}
                />
                <MenuItem
                  url="/product-category/powerbank"
                  title={"Powerbanks"}
                />

                <MenuItem url="/hot-products" title={"Hot Products"} />
              </div>
            </div>
          </div>
          {/* 
          <div
            className="d-none d-md-block d-lg-block d-xl-block B"
            style={{ flex: 1 }}
          >
            <div
              className="d-none d-md-block d-lg-block d-xl-block"
              style={{ flex: 1 }}
            >
              <SearchBar />
            </div>
          </div> */}

          <div className="d-none d-md-block">{/* <TopNavItems /> */}</div>

          <div
            className="d-flex align-items-center  py-1 flex1"
            style={{ justifyContent: "end" }}
          >
            <Person
              className="mx-2"
              size={25}
              // style={{}}
              onClick={() => navigate("/profile")}
            />
            <Cart className="m-1" />
            <DrawerIcon size="25" className="d-block d-md-none ms-2" />
          </div>
        </div>
        {/* <div className="">
          <div className="d-flex py-2" style={{ flexWrap: "wrap" }}>
            <MenuItem title={"Headphones"} />
            <MenuItem title={"Smartwataches"} />
            <MenuItem title={"Powerbanks"} />
            <MenuItem title={"categories"} />
            <MenuItem title={"geaskin"} />
            <MenuItem title={"Hot Products"} />
          </div>
        </div> */}
        <div className="d-flex d-none d-md-flex">
          <div className="" style={{ flex: 1 }}></div>
          <div className="container-md" style={{ flex: 10 }}>
            <SearchBar />
          </div>
          <div className="" style={{ flex: 1 }}></div>
        </div>

        <div className="d-block d-md-none" style={{ flex: 1 }}>
          <SearchBar />

          {/* <div
            className="d-flex py-2"
            style={{ flexWrap: "wrap", justifyContent: "space-evenly" }}
          >
            <MenuItem title={"Headphones"} />
            <MenuItem title={"Smartwataches"} />
            <MenuItem title={"Powerbanks"} />
          </div> */}
        </div>
      </div>
    </nav>
  );
}

function MenuItem({ title, url }) {
  return (
    <div className="p-1 px-2">
      <Link
        className="h6 "
        to={url}
        style={{
          textDecoration: "none",
          color: "rgba(0,0,0,0.9)",
          fontWeight: "500",
        }}
      >
        {title}
      </Link>
    </div>
  );
}

const TopNavItems = () => {
  return (
    <div className="d-flex flex-column 1" style={{ position: "relative" }}>
      <div className="2" style={{ width: "100%" }}>
        <NavPopup />
      </div>
      {/* <div className="d-none d-md-block">
        <NavPopup />
      </div> */}
      <div className="d-flex flex-direction-column nav-list">
        <NavItem Icon={Fire} target="hot-products" type="link">
          Hot Products
        </NavItem>

        <NavItem Icon={Bezier} target="gearskin" type="link">
          Gearskin
        </NavItem>

        {/* <NavItem Icon={TagsFill} type="popup" target="brands">
          Brands
        </NavItem> */}

        <NavItem Icon={MenuButtonFill} type="popup" target="categories">
          Categories
        </NavItem>
        {/* 
        <NavItem Icon={ClockHistory} type="popup" target="visited-items">
          Recently Visited
        </NavItem> */}

        <NavItem Icon={Person} target="profile" type="link">
          Profile
        </NavItem>
      </div>
    </div>
  );
};

// export default {
//   Top: Top,
//   Bottom: Bottom,
// };

const Bottom = BottomNav;

export default function () {
  return (
    <>
      <Top />
      <Bottom />
    </>
  );
}
