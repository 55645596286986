import { getCouponTotalDiscount } from "./../../../utils";
export const getCartSubTotal = (state) => {
  let subTotal = 0;
  const cartObjs = Object.values(state.order.cartItems);
  cartObjs.forEach((obj) => {
    subTotal += Number(obj.productData.price);
  });
  return subTotal;
};

export const getShippingCost = (state) => {
  return state.order.shippingAddress.allAddresses[
    state.order.shippingAddress.selectedAddressId
  ]
    ? state.order.shippingAddress.allAddresses[
        state.order.shippingAddress.selectedAddressId
      ].flat_charge
    : 0;
};

export const getSelectedAddress = (state) => {
  const { selectedAddressId, allAddresses } = state.order.shippingAddress;

  const selectedAddress = allAddresses[selectedAddressId];
  return selectedAddress;
};

export const getCustomShippingAddress = (state) => {
  return state.order.shippingAddress.customAddress;
};

export const getCartListWithCouponDiscount = (state) => {};

export const getCoupons = (state) => {
  // if selected coupon is flat discount or free shipping then
  // return [
  //   {
  //     couponData: {},
  //     addedAt: null,
  //     totalDiscount: 0, // newly added
  //   },
  // ];

  const coupon = {};
  // getCouponTotalDiscount

  const totalDiscount = "";
};

export const getCartQuantity = (store, slug) => {
  return store.order.cartItems[slug]?.quantity;
};

export default {
  getShippingCost,
  getCartSubTotal,
  getSelectedAddress,
  getCustomShippingAddress,
  getCartListWithCouponDiscount,
  getCoupons,
  getCartQuantity,
};
