export default function Quantity({
  value: quantity,
  onQuantityChange: handleQuantityChange = () => {},
  onRemove: handleRemove = () => {},
}) {
  // console.log({ quantity });
  return (
    <div className="d-flex" style={{}}>
      <div
        className=" flex-center"
        style={{
          width: "40px",
          backgroundColor: quantity == 0 ? "#555" : "#aaa",
          borderRadius: "0",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
        }}
        onClick={() => {
          if (quantity > 0) {
            handleQuantityChange(quantity - 1);
          } else {
            handleRemove();
          }
        }}
      >
        {quantity > 0 ? <p>-</p> : <p>x</p>}
      </div>

      <input
        className="p-2"
        style={{
          width: "48px",
          //   paddingLeft: "10px",
          outline: "0",
          borderRadius: "0",
          border: "1px solid #aaa",
          borderRight: 0,
          borderLeft: 0,
        }}
        type="number"
        onChange={(e) => {
          // console.log({ quantity, e: e.target.value });
          if (quantity == 0) {
            const inputValue = e.target.value;
            // const newNumber = Number(inputValue.toString().replace("0", ""));
            const newNumber = inputValue.toString().split("0").join("");
            handleQuantityChange(newNumber);
          } else {
            handleQuantityChange(Number(e.target.value));
          }
        }}
        value={quantity}
        // onClick={(e) => handleQuantityChange(e.target.value)}
      />

      <div
        className="flex-center"
        style={{
          width: "40px",
          backgroundColor: "#aaa",
          borderRadius: "0",
          borderTopRightRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
        onClick={() => handleQuantityChange(quantity + 1)}
      >
        <p>+</p>
      </div>
    </div>
  );
}
