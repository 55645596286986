const baseName = "homePage";

export const getFeaturedCategories = {
  pending: `${baseName}/getFeaturedCategories/pending`,
  success: `${baseName}/getFeaturedCategories/success`,
  fail: `${baseName}/getFeaturedCategories/fail`,
};

export const getFeaturedEarphones = {
  pending: `${baseName}/getFeaturedEarphones/pending`,
  success: `${baseName}/getFeaturedEarphones/success`,
  fail: `${baseName}/getFeaturedEarphones/fail`,
};

export const getFeaturedSmartwatches = {
  pending: `${baseName}/getFeaturedSmartwatches/pending`,
  success: `${baseName}/getFeaturedSmartwatches/success`,
  fail: `${baseName}/getFeaturedSmartwatches/fail`,
};

export const getFeaturedProducts = {
  pending: `${baseName}/getFeaturedProducts/pending`,
  success: `${baseName}/getFeaturedProducts/success`,
  fail: `${baseName}/getFeaturedProducts/fail`,
};
