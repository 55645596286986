export const getCatWithSlug = (state, slug) => {
  const { data: categories } = state.categories.allCategories;

  const seletecCategory = categories?.find((e) => e.slug == slug);

  return seletecCategory;
};

export default {
  getCatWithSlug,
};
