export const qStringToObj = (queryString) => {
  const obj = {};

  if (queryString.startsWith("?")) {
    queryString = queryString.slice(1);
  }

  const pairs = queryString.split("&");

  pairs.forEach((pair) => {
    const keyValue = pair.split("=");
    const key = decodeURIComponent(keyValue[0]);
    const value = decodeURIComponent(keyValue[1]);

    if (obj.hasOwnProperty(key)) {
      if (Array.isArray(obj[key])) {
        obj[key].push(value);
      } else {
        obj[key] = [obj[key], value];
      }
    } else {
      obj[key] = value;
    }
  });

  delete obj[""];

  return obj;
};

export function objToQString(obj) {
  const keyValuePairs = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      keyValuePairs.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }
  }

  return "?" + keyValuePairs.join("&");
}

export function objToQstring2(obj = {}) {
  let qStrElements = [];

  Object.keys(obj).forEach((key) => {
    const element = `${key}=${obj[key]}`;
    qStrElements.push(element);
  });

  return qStrElements.join("&");
}

export function mergeObjects(obj2, obj1) {
  const result = {};
  for (const key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      result[key] = obj1[key];
    }
  }
  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (result.hasOwnProperty(key) && Array.isArray(obj2[key])) {
        result[key] = [...result[key], ...obj2[key]];
      } else {
        result[key] = obj2[key];
      }
    }
  }
  delete result[""];
  return result;
}

export const mergeObjWithQStr = (obj, qString) => {
  const currentQStringAsObj = qStringToObj(qString);

  const allQStrings = mergeObjects(obj, currentQStringAsObj);

  //   console.log({ allQStrings });

  return "?" + objToQString(allQStrings);
};

export const removeFromQStr = (key, qStr) => {
  const qStrAsObj = qStringToObj(qStr);

  delete qStrAsObj[key];

  const newQStr = objToQString(qStrAsObj);

  return newQStr;
};

export const addToQStr = (key, value, qStr) => {
  const qStrAsObj = qStringToObj(qStr);

  qStrAsObj[key] = value;

  const newQStr = objToQString(qStrAsObj);

  return newQStr;
};

export const addParamToUrl = (
  urlWithQString,
  qStrsToInsert
  // addAsSeperatedIfMatches
) => {
  const url = urlWithQString.split("?")[0];

  const qStrAsStr = urlWithQString.split("?")[1] || "";
  const qStrAsObj = qStringToObj(`${qStrAsStr}`);

  const newQStrsAsObj = {};

  Object.keys(qStrAsObj).forEach((key) => {
    newQStrsAsObj[key] = qStrAsObj[key];
    console.log({ newQStrsAsObj, key });
  });

  Object.keys(qStrsToInsert).forEach((key) => {
    // console.log({ fuck: newQStrsAsObj.pages });
    if (newQStrsAsObj.pages) {
      newQStrsAsObj[key] = `${qStrAsObj[key]},${qStrsToInsert[key]}`;
    } else {
      newQStrsAsObj[key] = qStrsToInsert[key];
    }
  });

  return `${url}?${objToQstring2(newQStrsAsObj)}`;
};

export default {
  qStringToObj,
  objToQString,
  mergeObjWithQStr,
  removeFromQStr,
  addToQStr,
  addParamToUrl,
};
