import { combineReducers } from "redux";

import homePageReducer from "./modules/homePage/reducer.js";
import productPageReducer from "./modules/productPage/reducer";
// import productSearchReducer from "./modules/productSearch/reducer";
import orderReducer from "./modules/order/reducer";

import categoriesReducer from "./modules/categories/reducer";

import categoryPageReducer from "./modules/categoryPage/reducer";
import searchProductsReducer from "./modules/searchProducts/reducer";
import navbarReducer from "./modules/navbar/reducer.js";

import userReducer from "./modules/user/reducer.js";

const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  homePage: homePageReducer,
  productPage: productPageReducer,
  // productSearch: productSearchReducer,
  order: orderReducer,
  categories: categoriesReducer,
  categoryPage: categoryPageReducer,
  searchProducts: searchProductsReducer,
  navbar: navbarReducer,
  user: userReducer,
});

export default rootReducer;
