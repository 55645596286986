import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import actions from "./../../store/actions";
import selectors from "./../../store/selectors";

import { CartButton } from "./Cart";

import ProductComponent from "./Product";

export default function ProductItem({ product }) {
  const dispatch = useDispatch();
  const { name, slug, images } = product;

  const addedToCart = useSelector(
    (state) =>
      state.order.cartItems[slug] && state.order.cartItems[slug].quantity > 0
  );

  return (
    <div
      className="d-flex flex-column hover product_item p-2 pb-3"
      style={{
        // padding: 5,
        cursor: "pointer",
        borderRadius: "5px",
        backgroundColor: "#fff",
        // margin: "5px",
        flex: 1,
        minWidth: "150px",

        border: "1px solid rgba(150,150,150,0.1)",
        borderRadius: "5px",
      }}
    >
      <Link
        to={`/product/${slug}`}
        className="hover-shadow"
        style={{ textDecoration: "none", flex: 1 }}
        onClick={() => {
          dispatch(actions.productPage.addToVisitedProduct(product));
        }}
      >
        <img
          alt=""
          src={images && images[0] && images[0].src}
          className="img_container"
          style={{
            width: "100%",
            // border: "1px solid rgba(150,150,150,0.1)",
            // borderRadius: "5px",
          }}
        />

        <div
          className=""
          style={{
            margin: 2,
            textAlign: "center",
            height: "3rem",
            overflow: "hidden",
          }}
        >
          <a
            className="text-capitalize"
            style={{
              color: "#404040",
              // fontSize: "1rem",
              fontWeight: "500",
              textDecoration: "none",
            }}
          >
            {name}
          </a>
        </div>
        <div className="">{<ProductComponent.Price product={product} />}</div>
      </Link>
      <div style={{}} className="d-flex justify-content-center">
        {<CartButton product={product} />}
      </div>
    </div>
  );
}
