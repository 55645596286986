import React from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  Fire,
  BoxArrowUpRight,
  MenuButtonFill,
  TagsFill,
  CaretUp,
  ClockHistory,
  Bezier,
} from "react-bootstrap-icons";

import {
  mergeObjWithQStr,
  qStringToObj,
  objToQString,
  removeFromQStr,
  addToQStr,
} from "./utils";

const probableParams = {
  // theme_color_mode: "dark",
  selected_nav_popup_element:
    "product-brands/product-categories/recently-visited-items",
};

export default function NavItem({
  children,
  Icon = () => {},
  type = "link",
  target = "",
}) {
  let TypeIcon = () => {};
  if (type == "link") {
    TypeIcon = BoxArrowUpRight;
  } else if (type == "popup") {
    TypeIcon = CaretUp;
  }

  const { search: currentQueryString, pathname } = useLocation();
  const navigate = useNavigate();

  const [isActive, setIsActive] = React.useState(false);

  const pathEqualsTarget = () => {
    // console.log({ currentQueryString, pathname, target });
    // target, type, isActive
    let link = "";
    if (type == "link") {
      if (pathname.replace("/", "") == target) {
        // active
        setIsActive(true);
        link = `/${currentQueryString}`;
        return true;
      } else {
        link = `${target}${currentQueryString}`;
        return false;
      }
    } else if (type == "popup") {
      if (
        qStringToObj(currentQueryString).selected_nav_popup_element == target
      ) {
        // active
        setIsActive(true);
        const newQStr = removeFromQStr(
          "selected_nav_popup_element",
          currentQueryString
        );
        link = `${pathname}${newQStr}`;
        return true;
      } else {
        const newQStr = addToQStr(
          "selected_nav_popup_element",
          target,
          currentQueryString
        );
        link = `${pathname}${newQStr}`;
        return false;
      }
    }
    return link;
  };

  const generateLink = () => {
    // console.log({ currentQueryString, pathname, target });
    // target, type, isActive
    let link = "";

    if (type == "link") {
      if (pathEqualsTarget()) {
        setIsActive(true);
        link = `/${currentQueryString}`;
      } else {
        link = `${target}${currentQueryString}`;
        setIsActive(false);
      }
    } else if (type == "popup") {
      if (pathEqualsTarget()) {
        setIsActive(true);
        const newQStr = removeFromQStr(
          "selected_nav_popup_element",
          currentQueryString
        );
        link = `${pathname}${newQStr}`;
      } else {
        const newQStr = addToQStr(
          "selected_nav_popup_element",
          target,
          currentQueryString
        );
        link = `${pathname}${newQStr}`;
        setIsActive(false);
      }
    }
    return link;
  };

  React.useEffect(() => {
    // checkIfActive();
    generateLink();
  }, [currentQueryString, pathname]);

  return (
    <Link
      style={{
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        flex: 1,
        textDecoration: "none",
        // backgroundColor: "rgb(31, 69, 50)",
      }}
      className="d-flex flex-column flex-md-row align-items-center p-2 nav-list-item"
      onClick={(e) => {
        e.preventDefault();
        navigate(generateLink());
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "end",
          marginRight: -10,
        }}
        className="mr-md-2"
      >
        <Icon
          className="my-1"
          // color={isActive ? "#5cb4c2" : "white"}
          color={isActive ? "#5cb4c2" : "black"}
          size="25"
        />

        <TypeIcon color="white" size="10" />
      </div>

      <h3
        style={{
          fontSize: "calc(10% + 0.6vw + 0.5vh)",
          textDecoration: "none",
          textAlign: "center",
          color: "black",
        }}
        // className={`${isActive ? "text-theme" : "text-light"} text-small mb-1`}
        className={`${isActive && "text-theme "} text-small mb-1`}
      >
        {children}
        {` `}
      </h3>
      <div
        style={{ border: "1px solid white", visibility: !isActive && "hidden" }}
      ></div>
    </Link>
  );
}
