export default function TermItem({ period, iconName, unit }) {
  const icons = {
    warranty: "🛡️ ",
    return: "🔁 ",
  };

  function generateLabel(period) {
    // const periodWithD =
    // period.toLowerCase().split("")[period.split("").length - 1] == "d";

    // console.log({ period });

    let num = "";
    let postfix = "";

    if (unit == "d") {
      let periodDays = period;

      if (periodDays >= 36500) {
        // lifetime
        return "lifetime";
      }
      // if month, day or year
      else if (!(periodDays % 365)) {
        // year
        num = periodDays / 365;
        if (periodDays / 365 == 1) {
          postfix = "year";
        } else {
          postfix = "years";
        }
      } else if (!(periodDays % 30)) {
        // months
        num = periodDays / 30;
        if (periodDays / 30 == 1) {
          postfix = "month";
        } else {
          postfix = "months";
        }
      } else {
        num = periodDays;
        // days
        if (periodDays == 1) {
          postfix = "day";
        } else {
          postfix = "days";
        }
      }
    }

    return num + " " + postfix;
  }

  return (
    <div
      className="border rounded px-1 py-0 gap-2 hover pointer border-dark p-fs-sm d-flex hide_on_collapse"
      style={{
        justifyContent: "center",
        alignItems: "center",
        // flex: period.toString()?.length,
        whiteSpace: "nowrap",

        paddingBottom: "-5px",
      }}
    >
      <div className="p-0 m-0" style={{ fontSize: "13px" }}>
        {iconName && icons[iconName]}
        {generateLabel(period)}
      </div>
    </div>
  );
}
