import actionNames from "./actionNames";

// const categoryItem = {
// categoryData: {},
// ..other data related to ui
// };

const initialState = {
  isCartModalVisible: false,
  // cartModal: {
  //   focusElement: "",
  //   show: false,
  // },
};

let initialStateFromStorage = localStorage.getItem("store.navbar");
try {
  initialStateFromStorage = JSON.parse(initialStateFromStorage);
} catch (ex) {
  initialStateFromStorage = null;
}

// const saveItem = (newSate) => {
//   localStorage.setItem("store.navbar", JSON.stringify(newSate));
// };

export default function (
  state = initialStateFromStorage || initialState,
  action
) {
  let newState = { ...state };
  switch (action.type) {
    case actionNames.showCartModal:
      newState = {
        ...state,
        isCartModalVisible: true,
      };
      // saveItem(newState);
      return newState;
    case actionNames.hideCartModal:
      newState = {
        ...state,
        isCartModalVisible: false,
      };
      // saveItem(newState);
      return newState;
    default:
      return {
        ...state,
      };
  }
}
