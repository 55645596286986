import * as actionNames from "./actionNames";

const initialState = {
  user: null,
  isLoggedIn: false,
  accessToken: null,
  refreshToken: null,
  loadings: {
    // verifyAccessToken: false,
    [actionNames.login._]: false,
    [actionNames.verifyAccessToken._]: false,
  },
};

const saveItem = (newState) => {
  try {
    localStorage.setItem("store.user", JSON.stringify(newState));
  } catch (ex) {
    localStorage.removeItem("store.user");
  }

  return newState;
};

let initialStateFromStorage = localStorage.getItem("store.user");
try {
  initialStateFromStorage = JSON.parse(initialStateFromStorage);
} catch (ex) {
  initialStateFromStorage = null;
}

export default function (
  state = initialStateFromStorage ?? initialState,
  action
) {
  let newState = { ...state };
  switch (action.type) {
    case actionNames.login.pending:
    case actionNames.verifyAccessToken.pending:
      return {
        ...state,
        loadings: { ...state.loadings, [action.type.split("/")[1]]: true },
      };

    case actionNames.login.success:
    case actionNames.verifyAccessToken.success:
      newState = {
        ...state,
        isLoggedIn: true,
        loadings: { ...state.loadings, [action.type.split("/")[1]]: false },
        user: action.payload.user,
        accessToken: action.payload.accessToken,
      };
      saveItem({
        ...newState,
        isLoggedIn: false,
        loadings: initialState.loadings,
      });
      return { ...newState };

    case actionNames.login.fail:
    case actionNames.verifyAccessToken.fail:
      return {
        ...state,
        loadings: { ...state.loadings, [action.type.split("/")[1]]: false },
        user: null,
        accessToken: null,
        refreshToken: null,
      };

    default:
      return { ...state };
  }
}
