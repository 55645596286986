import Loading from "./Loading";
export default function NextButton({ onClick, loading }) {
  return (
    <div style={{ textAlign: "center" }} className="my-3">
      <button
        className="btn btn-theme p-3"
        style={{ color: "white", fontSize: "120%" }}
        onClick={onClick}
        disabled={loading}
      >
        show more
        {loading && (
          <Loading
            size="30px"
            style={{
              paddingLeft: "8px",
              display: "inline",
              float: "right",
            }}
            color="white"
          />
        )}
      </button>
    </div>
  );
}
