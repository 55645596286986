import React from "react";

import Categories from "./Categories";

import Slider from "./Slider";
import Banner from "./Banner";

import pageTransition from "./../pageTranstion";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import ProductContainer from "../../common/ProductContainer";
import H2 from "../../common/SectionHeading";
import ProductItem from "../../common/ProductItem";
function Home() {
  const dispatch = useDispatch();
  const [deviceType, setDeviceType] = React.useState("desktop");

  const featuredEarphones = useSelector(
    (state) => state.homePage.featuredEarphones.products
  );
  const showableEarphones = featuredEarphones?.slice(
    0,
    deviceType === "tablet" || deviceType === "mobile" ? 6 : 4
  );

  const smartwatches = useSelector(
    (state) => state.homePage.featuredSmartwatches.products
  );
  const showableSmartwatches = smartwatches?.slice(
    0,
    deviceType === "tablet" || deviceType === "mobile" ? 6 : 4
  );

  React.useEffect(() => {
    featuredEarphones.length < 1 &&
      dispatch(actions.homePage.getFeaturedEarphones());
    smartwatches.length < 1 &&
      dispatch(actions.homePage.getFeaturedSmartwatches());

    // Function to check the device type based on the window width
    const checkDeviceType = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth < 768) {
        setDeviceType("mobile");
      } else if (windowWidth < 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };

    // Initial check when the component mounts
    checkDeviceType();

    // Add a resize event listener to update the state when the window is resized
    window.addEventListener("resize", checkDeviceType);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkDeviceType);
    };
  }, []);

  return (
    <div className={`container`}>
      <Slider deviceType={deviceType} />
      <Categories />
      <div className="my-5">
        <H2 align="left">Featured Headphones</H2>
        <div className="mt-3">
          <ProductContainer>
            {showableEarphones.map((product) => {
              return <ProductItem product={product} />;
            })}
          </ProductContainer>
        </div>
      </div>
      <div className="my-5">
        <Banner
          title="Latest Grooming Products"
          buttonText={"Check all trimmers"}
          buttonUrl={"/product-category/trimmer"}
          description={"IPX7 certified water resistant trimmer."}
          bannerImgUrl="https://www.gearmadebd.com/wp-content/uploads/2021/12/1-2-1024x372.png"
          bgColor="rgba(0, 0, 0, 0.5)"
          bgOpacity="0.5"
        />
      </div>
      <div className="my-5">
        <H2 align="left">Featured Smartwatches</H2>
        <div className="mt-3">
          <ProductContainer>
            {showableSmartwatches?.map((product) => {
              return <ProductItem product={product} />;
            })}
          </ProductContainer>
        </div>
      </div>
      <div className="my-5">
        <Banner
          title="Get Genuine Apple Products"
          description={"We care for your money."}
          buttonText={"Find out more"}
          buttonUrl={"/product-category/trimmer"}
          bannerImgUrl="https://www.gearmadebd.com/wp-content/uploads/2021/12/2-1024x372.png"
          bgColor="rgba(0, 0, 0, 0.5)"
          bgOpacity="0.0"
        />
      </div>
    </div>
  );
}

export default pageTransition(Home);
