import actionNames from "./actionNames";

import {
  getCouponCheckedCartList,
  getGrossTotal,
  getNetTotal,
  getShippingCost,
  calculateTotalCouponDiscount,
  discountCheckedCouponItems,
  calculateCharges,
  calculateCartQuantity,
} from "./utils";

// NOTE: calculateCharges & getCouponCheckedCartList should be called after you update the state.

const saveItem = (newState) => {
  try {
    localStorage.setItem("store.order", JSON.stringify(newState));
  } catch (ex) {
    localStorage.removeItem("store.order");
  }

  return newState;
};

const initialState = {
  cartItems: {
    // "<product-slug> = cart item object": {
    //   productData: {},
    //   quantity: "",
    //   addedAt:""
    //   grossTotal: ''
    //   netTotal: '', => couponSelector
    //   totalCouponDiscount: '' => couponSelector
    //   couponVerificationMessage: '' => couponSelector
    // },
  },
  cartItemCount: 0,

  contactInfo: {
    fullName: "",
    email: "",
    phone: "",
  },

  charges: {
    totalSaleDiscount: 0,
    grossTotal: 0,
    totalCouponDiscount: 0,
    shippingCharge: 0,
    netTotal: 0,
  },

  coupon: {
    allCoupons: {
      // "<couponcode>": {
      //   couponData: {},
      //   addedAt: null,
      // },
    },
    selectedCouponCode: null,
    // totalCouponDiscount: 0,
  },

  shippingAddress: {
    allAddresses: {},
    selectedAddressId: null,
    lastFetchedAt: null,
    customAddress: "",
  },

  loadings: {
    getShippingAddresses: false,
    addCoupon: false,
  },

  actions: {
    createPurchaseOrder: {
      loading: false,
      currentPurchase: null,
    },
  },

  orderNote: "",
  payment: {
    method: "sslcommerz",
    partial: true,
    partialAmmount: 10,
  },
};

let initialStateFromStorage = localStorage.getItem("store.order");
try {
  initialStateFromStorage = JSON.parse(initialStateFromStorage);
} catch (ex) {
  initialStateFromStorage = null;
}

export default function (
  state = initialStateFromStorage ?? initialState,
  action
) {
  let newState = { ...state };
  switch (action.type) {
    case actionNames.addToCart:
      // var newState = { ...state };
      newState = {
        ...state,
        cartItems: {
          ...state.cartItems,
          [action.payload.slug]: {
            productData: { ...action.payload.productData },
            quantity: 1,
            addedAt: new Date().getTime(),
          },
        },
      };

      return saveItem({
        ...newState,

        charges: calculateCharges(newState),
        cartItems: {
          ...getCouponCheckedCartList(
            newState.coupon.allCoupons[newState.coupon.selectedCouponCode],
            newState.cartItems
          ),
        },
        cartItemCount: calculateCartQuantity(newState.cartItems),
        coupon: {
          ...newState.coupon,
          allCoupons: discountCheckedCouponItems(newState),
        },
      });

    case actionNames.removeFromCart:
      delete state.cartItems[action.payload.slug];

      return saveItem({
        ...state,
        charges: calculateCharges(newState),
        cartItems: {
          ...getCouponCheckedCartList(
            state.coupon.allCoupons[state.coupon.selectedCouponCode],
            state.cartItems
          ),
        },
        cartItemCount: calculateCartQuantity(newState.cartItems),
        coupon: {
          ...newState.coupon,
          allCoupons: discountCheckedCouponItems(newState),
        },
      });

    case actionNames.updateCartQuantity:
      newState = {
        ...state,
        cartItems: {
          ...state.cartItems,
          [action.payload.slug]: {
            ...state.cartItems[action.payload.slug],
            quantity: action.payload.quantity > 0 ? action.payload.quantity : 0,
          },
        },
      };

      return saveItem({
        ...newState,
        charges: calculateCharges(newState),
        cartItems: {
          ...getCouponCheckedCartList(
            newState.coupon.allCoupons[newState.coupon.selectedCouponCode],
            newState.cartItems
          ),
        },
        cartItemCount: calculateCartQuantity(newState.cartItems),
        coupon: {
          ...newState.coupon,
          allCoupons: discountCheckedCouponItems(newState),
        },
      });

    case actionNames.getShippingAddresses.pending:
      return saveItem({
        ...state,
        loadings: {
          ...state.loadings,
          getShippingAddresses: true,
        },
      });
    case actionNames.getShippingAddresses.fail:
      return saveItem({
        ...state,
        loadings: {
          ...state.loadings,
          getShippingAddresses: false,
        },
      });
    case actionNames.getShippingAddresses.success:
      // console.log("action");
      // console.log(action);
      return saveItem({
        ...state,
        loadings: {
          ...state.loadings,
          getShippingAddresses: false,
        },
        shippingAddress: {
          ...state.shippingAddress,
          allAddresses: { ...action.payload.data },
          lastFetchedAt: new Date(),
        },
      });

    case actionNames.selectShippingAddress:
      newState = {
        ...state,
        shippingAddress: {
          ...state.shippingAddress,
          selectedAddressId: action.payload.address._id,
          allAddresses: {
            ...state.shippingAddress.allAddresses,
            [action.payload.address._id]: { ...action.payload.address },
          },
        },
      };

      return saveItem({
        ...newState,
        charges: calculateCharges(newState),
        coupon: {
          ...newState.coupon,
          allCoupons: discountCheckedCouponItems(newState),
        },
      });

    case actionNames.updateCustomShippingAddress:
      return saveItem({
        ...state,

        shippingAddress: {
          ...state.shippingAddress,
          customAddress: action.payload.text,
        },
      });

    case actionNames.addCoupon.pending:
      return saveItem({
        ...state,
        loadings: {
          ...state.loadings,
          addCoupon: true,
        },
      });

    case actionNames.addCoupon.fail.invalid_coupon:
      return saveItem({
        ...state,
        loadings: {
          ...state.loadings,
          addCoupon: false,
        },
      });

    case actionNames.addCoupon.success:
      // step 1
      newState = {
        ...state,
        loadings: {
          ...state.loadings,
          addCoupon: false,
        },
        coupon: {
          ...state.coupon,
          allCoupons: {
            ...state.coupon.allCoupons,
            [action.payload.coupon.code]: {
              couponData: { ...action.payload.coupon },
              addedAt: new Date(),
            },
          },
          selectedCouponCode: action.payload.coupon.code,
        },
      };

      newState = {
        ...newState,
        cartItems: {
          ...getCouponCheckedCartList(
            newState.coupon.allCoupons[newState.coupon.selectedCouponCode],
            newState.cartItems
          ),
        },
        coupon: {
          ...newState.coupon,
          allCoupons: discountCheckedCouponItems(newState),
        },
      };

      return saveItem({
        ...newState,

        charges: calculateCharges(newState),
      });

    case actionNames.unSelectCoupon:
      const code = action.payload.code;
      // delete state.coupon.allCoupons[code];
      if (state.coupon.selectedCouponCode == code) {
        state.coupon.selectedCouponCode = null;
      }

      return saveItem({
        ...state,
        charges: calculateCharges(state),
        cartItems: {
          ...getCouponCheckedCartList(
            state.coupon.allCoupons[state.coupon.selectedCouponCode],
            state.cartItems
          ),
        },
        coupon: {
          ...newState.coupon,
          allCoupons: discountCheckedCouponItems(newState),
        },
      });
    case actionNames.selectCoupon:
      state.coupon.selectedCouponCode = action.payload.code;
      return saveItem({
        ...state,
        charges: calculateCharges(state),
        cartItems: {
          ...getCouponCheckedCartList(
            state.coupon.allCoupons[state.coupon.selectedCouponCode],
            state.cartItems
          ),
        },
      });
    case actionNames.updateContactInfo:
      return saveItem({
        ...state,
        contactInfo: { ...state.contactInfo, ...action.payload },
      });
    case actionNames.updateOrderNote:
      return saveItem({
        ...state,
        orderNote: action.payload.text,
      });

    case actionNames.createPurchaseOrder.pending:
      return saveItem({
        ...state,
        actions: {
          ...state.actions,
          createPurchaseOrder: {
            ...state.actions.createPurchaseOrder,
            loading: true,
            currentPurchase: { ...action.payload.purchaseOrderData },
          },
        },
      });
    case actionNames.createPurchaseOrder.fail:
      return saveItem({
        ...state,
        actions: {
          ...state.actions,
          createPurchaseOrder: {
            ...state.actions.createPurchaseOrder,
            loading: false,
            currentPurchase: { ...action.payload.purchaseOrderData },
          },
        },
      });
    case actionNames.createPurchaseOrder.success:
      newState = {
        ...state,
        cartItems: {},
        cartItemCount: 0,
        actions: {
          ...state.actions,
          createPurchaseOrder: {
            ...state.actions.createPurchaseOrder,
            loading: false,
            currentPurchase: { ...action.payload.purchase },
          },
        },
      };

      return saveItem({
        ...newState,

        charges: calculateCharges(newState),
        cartItems: {
          ...getCouponCheckedCartList(
            newState.coupon.allCoupons[newState.coupon.selectedCouponCode],
            newState.cartItems
          ),
        },
        coupon: {
          ...newState.coupon,
          allCoupons: discountCheckedCouponItems(newState),
        },
      });
    default:
      return {
        ...state,
      };
  }
}
