import { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../../../env";
import { useSelector } from "react-redux";

export default function () {
  const [user, setUser] = useState();
  const [backupUser, setBackupUser] = useState();

  useEffect(() => {
    getData();
  }, []);

  const accessToken = useSelector((e) => e.user.accessToken);

  const getData = async () => {
    const res = await axios.get(`${apiUrl}/api/v1/user/me`, {
      validateStatus: false,
      headers: { Authorization: accessToken },
    });

    if (res.data) {
      setUser(res.data.user);
      setBackupUser(res.data.user);
    }
  };
  const [edit, setEdit] = useState(false);
  return (
    <div className="">
      {user && (
        <div className="container mx-auto border-x border-b mb-3 pb-3">
          <div className="bg-white py-3 w-full gap-3 flex flex-col md:flex-row">
            <div className="md:w-1/3">
              <img
                className="w-full h-auto rounded-md"
                src={
                  user?.contactInfo?.imageUrl ||
                  "https://res.cloudinary.com/dvz9ssr9t/image/upload/v1726381843/Screenshot_2024-09-15_122918_xw0smh.png"
                }
                alt="Profile"
              />
              <button
                className="rounded-md w-full mt-3 py-2 px-4 text-theme font-semibold border-2"
                onClick={() => setEdit(!edit)}
              >
                {edit ? "Save Changes" : "Edit Image"}
              </button>
            </div>

            <div className="md:w-2/3 shadow-sm px-3 rounded-md flex flex-col">
              <div className="py-2">
                <label className="block font-medium text-gray-600">
                  Email:
                </label>
                <input
                  type="email"
                  name="userEmail"
                  placeholder={user.email.primary}
                  className="border rounded-md w-full p-2 text-gray-700"
                  disabled={!edit}
                  value={user.email.primary}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      email: { ...user.email, primary: e.target.value },
                    });
                  }}
                />
              </div>

              <div className="py-2">
                <label className="block font-medium text-gray-600">
                  Fullname:
                </label>
                <input
                  type="text"
                  name="userName"
                  placeholder={user.fullname}
                  className="border rounded-md w-full p-2 text-gray-700"
                  disabled={!edit}
                />
              </div>

              <div className="py-2">
                <label className="block font-medium text-gray-600">
                  Phone:
                </label>
                <input
                  type="number"
                  name="userPhone"
                  placeholder={user.primaryPhone.number}
                  className="border rounded-md w-full p-2 text-gray-700"
                  disabled={!edit}
                />
              </div>

              {/* <div className="py-2">
           <label className="block font-medium text-gray-600">Address:</label>
           <input
             type="text"
             name="location"
             placeholder={`${user.location_meta.division} ${user.location_meta.city} /postcode: ${user.location_meta.postcode} /${user.location_meta.suburb} ${user.location_meta.serial_no}`}
             // placeholder={`${user.location_meta.division}`} have to map
             className="border rounded-md w-full p-2 text-gray-700"
             disabled={!edit}
           />
         </div> */}

              <div className="py-2">
                <label className="block font-medium text-gray-600">
                  Password:
                </label>
                <input
                  type="password"
                  name="password"
                  placeholder={"password"}
                  className="border rounded-md w-full p-2 text-gray-700"
                  disabled={!edit}
                />
              </div>
            </div>
          </div>

          <button
            className="w-full bg-theme text-white py-2 px-4 rounded shadow-lg"
            onClick={() => setEdit(!edit)}
          >
            {edit ? "Save Changes" : "Edit Profile"}
          </button>
          {edit && (
            <button
              className="w-full bg-theme text-white py-2 px-4 rounded shadow-lg"
              onClick={() => setUser(backupUser)}
            >
              Reset
            </button>
          )}
        </div>
      )}
    </div>
  );
}
