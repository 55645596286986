import React from "react";

import { Link } from "react-router-dom";

import NavItem from "./NavItem";

import {
  Fire,
  MenuButtonFill,
  ClockHistory,
  Bezier,
  Person,
} from "react-bootstrap-icons";

import NavPopup from "../NavPopup";

export default function BottomNav() {
  return (
    <div
      style={{
        position: "fixed",
        // position: "sticky",
        // overflowY: "scroll",
        overflow: "hidden",

        zIndex: 1000,
        bottom: 0,
        width: "100%",
        boxShadow: "0 1px 12px rgb(0 0 0/8%)",
      }}
      id="bottom-nav"
    >
      <div className="d-md-none w-100" style={{ backgroundColor: "#fff" }}>
        <NavItems />
      </div>
    </div>
  );
}

const NavItems = () => {
  return (
    <div className="d-flex flex-column 1" style={{ position: "relative" }}>
      <div className="2" style={{ width: "100%" }}>
        <NavPopup />
      </div>
      {/* <div className="d-none d-md-block">
          <NavPopup />
        </div> */}
      <div className="d-flex flex-direction-column nav-list">
        <NavItem Icon={Fire} target="hot-products" type="link">
          Hot Products
        </NavItem>

        <NavItem Icon={Bezier} target="gearskin" type="link">
          Gearskin
        </NavItem>

        {/* <NavItem Icon={TagsFill} type="popup" target="brands">
            Brands
          </NavItem> */}

        <NavItem Icon={MenuButtonFill} type="popup" target="categories">
          Categories
        </NavItem>
        {/* 
          <NavItem Icon={ClockHistory} type="popup" target="visited-items">
            Recently Visited
          </NavItem> */}

        <NavItem Icon={Person} target="profile" type="link">
          Profile
        </NavItem>
      </div>
    </div>
  );
};
