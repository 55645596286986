import React from "react";
import { Cart3, PersonFill } from "react-bootstrap-icons";
import style from "../index.module.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import actions from "./../../../../store/actions";
import * as selectors from "../../../../store/selectors";
import CartPopup from "./CartPopup";

export default function () {
  const dispatch = useDispatch();

  const cartModalVisible = useSelector(
    (state) => state.navbar.isCartModalVisible
  );

  function setCartModalVisiability() {
    if (cartModalVisible) {
      dispatch(actions.nabvar.hideCartModal());
    } else {
      dispatch(actions.nabvar.showCartModal());
    }
  }

  // const [cartModalVisible, setCartModalVisiability] = React.useState(false);

  const { netTotal } = useSelector((s) => s.order.charges);

  const { cartItemCount } = useSelector((state) => state.order);
  return (
    <div
      className="d-flex align-items-center justify-content-center p-1 border rounded border-dark cart"
      // style={{ border: "1px solid black", height: "30px" }}
      style={{
        // position: "relative",
        cursor: "pointer",
      }}
      onClick={(e) => {
        if (e.target == e.currentTarget) {
          setCartModalVisiability(!cartModalVisible);
        }
      }}
    >
      <p
        className="p-0 m-0 fw-bold"
        onClick={(e) => {
          if (e.target == e.currentTarget) {
            setCartModalVisiability(!cartModalVisible);
          }
        }}
      >
        {cartItemCount > 0 ? netTotal.toLocaleString() : 0} &#2547;
      </p>
      <div className="" style={{ position: "relative" }}>
        <Cart3
          className=""
          style={{ marginLeft: "10px", position: "relative" }}
          fill=""
          onClick={(e) => {
            if (e.target == e.currentTarget) {
              setCartModalVisiability(!cartModalVisible);
            }
          }}
        />

        {
          <div
            style={{
              position: "absolute",
              top: "-5px",
              right: "1px",
              fontSize: "60%",
            }}
          >
            {cartItemCount > 0 && cartItemCount}
          </div>
        }
      </div>

      {cartModalVisible && (
        <>
          <div
            className="modal-overlay"
            onClick={() => setCartModalVisiability(false)}
          ></div>
          <CartPopup handleModalClose={() => setCartModalVisiability(false)} />
        </>
      )}
    </div>
  );
}
