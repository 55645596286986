import { useState } from "react";
import actions from "./../../../store/actions";
import { useDispatch } from "react-redux";

export default function () {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  const [phoneCountryCode, setPhoneCountryCode] = useState("");

  const [password, setPassword] = useState("");

  // const [passwordField, setPasswordField] = useState({
  //   value: "",
  //   allConditionsValid: false,
  //   validation: {
  //     min_char: {
  //       name: "min_char",
  //       messages: {
  //         success: "",
  //         fail: "",
  //         info: "",
  //       },
  //       validate: (input) => /.{5,}/.test(input),
  //       isValid: false,
  //     },
  //   },
  // });

  const [identityInfoMethod, setIndentityInfoMethod] = useState("email");

  const dispatch = useDispatch();

  const handleLogin = () => {
    if (identityInfoMethod == "email") {
      if (isEmailValid) {
        dispatch(actions.user.login(email, null, password));
      }
    } else if (identityInfoMethod == "phone") {
      if (isPhoneValid) {
        dispatch(actions.user.login(null, phone, password));
      }
    }
  };

  return (
    <div className="my-2">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleLogin();
        }}
      >
        <div className="form-group my-2">
          <IdentityInfoSelectorItem
            label="Email"
            selectedIdentityInfoMethod={identityInfoMethod}
            setIndentityInfoMethod={setIndentityInfoMethod}
            id="email"
          />
          <IdentityInfoSelectorItem
            label="Phone"
            selectedIdentityInfoMethod={identityInfoMethod}
            setIndentityInfoMethod={setIndentityInfoMethod}
            id="phone"
          />

          {identityInfoMethod == "email" ? (
            <>
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setIsEmailValid(isValidEmail(e.target.value));
                }}
                type="email"
                className={`form-control my-2 ${
                  !isEmailValid && email.length && "is-invalid"
                }`}
                id="identityInfo"
                aria-describedby="emailHelp"
                placeholder="Enter email"
              />

              <div class="invalid-feedback">Please provide a valid state.</div>
            </>
          ) : (
            <>
              {/* <input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                type="number"
                className="form-control"
                id="identityInfo"
                aria-describedby="phoneHelp"
                placeholder="yourname"
              /> */}

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroupPrepend2">
                    +880
                  </span>
                </div>
                <input
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setIsPhoneValid(checkValidBDphone(e.target.value));
                  }}
                  type="number"
                  className={`form-control my-2 ${
                    !isPhoneValid && phone.length && "is-invalid"
                  }`}
                  id="identityInfo"
                  placeholder="Username"
                  aria-describedby="inputGroupPrepend2"
                  required
                />
              </div>
              <div class="invalid-feedback">Please provide a valid state.</div>
            </>
          )}

          <small id="emailHelp" className="form-text text-muted">
            We'll never share your info with anyone else.
          </small>
        </div>
        <div className="form-group my-2">
          <label for="exampleInputPassword1">Password</label>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            // type="password"
            type="text"
            className="form-control"
            id="exampleInputPassword1"
            placeholder="Password"
          />
        </div>
        <button type="submit" className="btn btn-theme text-white">
          Submit
        </button>
      </form>
    </div>
  );
}

function isValidEmail(email) {
  // Regular expression for validating an email address
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the input against the regular expression
  return emailRegex.test(email);
}

function checkValidBDphone(phoneNumber) {
  // Regular expression to match the pattern 01Wxxxxxxxxx, where W is 3-9
  const regex = /^01[1-9]\d{8}$/;
  return regex.test(phoneNumber);
}

const IdentityInfoSelectorItem = ({
  label,
  id,
  selectedIdentityInfoMethod,
  setIndentityInfoMethod,
}) => {
  return (
    <label
      for="identityInfo"
      style={{ border: "" }}
      className={`border rounded px-2 me-1 py-1  ${
        id == selectedIdentityInfoMethod ? "bg-theme text-white" : ""
      }   
      `}
      onClick={() => {
        setIndentityInfoMethod(id);
      }}
    >
      {label}
    </label>
  );
};
