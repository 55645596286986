import React from "react";
import { IoIosStar, IoIosStarHalf, IoIosStarOutline } from "react-icons/io";

const RattingStart = ({ aveRating, rating }) => {
  const renderStars = (average_rating) => {
    const totalStars = 5;
    const fullStars = Math.floor(average_rating);
    const halfStar = average_rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = totalStars - fullStars - halfStar;

    const stars = [];

    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <span key={`full-${i}`}>
          <IoIosStar />
        </span>
      );
    }

    if (halfStar) {
      stars.push(
        <span key="half">
          <IoIosStarHalf />
        </span>
      );
    }

    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <span key={`empty-${i}`}>
          <IoIosStarOutline />
        </span>
      );
    }

    return stars;
  };

  return (
    <div>
      {rating === 0 ? (
        <div className="mb-3 flex items-center gap-1">
          <span className="text-lg font-medium">Rating: </span>
          <span>0 people rated </span>
          <div className="flex text-amber-400">
            {[...Array(5)].map((_, i) => (
              <IoIosStarOutline key={i} />
            ))}
          </div>
        </div>
      ) : (
        <div className="mb-3 flex items-center gap-1">
          <span className="text-lg font-medium">Rating: </span>
          <span className="flex text-amber-400">{renderStars(aveRating)}</span>
          <span className="ml-2">({rating} people rated)</span>
        </div>
      )}
    </div>
  );
};

export default RattingStart;
