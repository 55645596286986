import React from "react";

const Policies = () => {
  return (
    <div style={{}}>
      <p className="h5">Return Policy</p>
      <ol>
        <li>
          No returns on sold goods. Exchanges within 7 days with a 20% fee.
        </li>
        <li>
          <strong>Change of Mind:</strong> Notify within 3 days.
        </li>
        <li>
          <strong>Misplaced Order:</strong> Check products against the invoice,
          and notify us immediately.
        </li>
        <li>
          <strong>Manufacturing Defect:</strong> Report within 7 days. Product
          quality is the manufacturer’s responsibility. No returns for
          dissatisfaction.
        </li>
        <li>The seal, packaging & invoice must be intact for 2.1 and 2.2</li>
      </ol>

      <p className="h5">Warranty Policy</p>
      <ol>
        <li>
          Our Limited warranty details are marked with the{" "}
          <span role="img" aria-label="shield">
            🛡️
          </span>{" "}
          icon for each product. Warranty excludes LCD & physical damage.
        </li>
        <li>
          <strong>Brand Warranty:</strong> Report issues within the warranty.
          Claims take 2 weeks for inspection.
        </li>
        <li>
          <strong>Gearmade Warranty:</strong> 7-day limited warranty. Notify
          within 7 days.
        </li>
      </ol>

      <p>
        Regards, Gearmade{" "}
        <a href="http://www.gearmadebd.com">www.gearmadebd.com</a>
      </p>
    </div>
  );
};

export default Policies;
