import {
  addToVisitedProduct,
  getProductWithSlug,
  getRelatedProducts,
} from "./actionNames";

const initialState = {
  visitedProducts: {},
  relatedProducts: {
    itemsByProduct: {
      "<slug>": [],
    },
  },
  action_getProductWithSlug: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case addToVisitedProduct:
      return {
        ...state,

        visitedProducts: {
          ...state.visitedProducts,
          [action.payload.slug]: { ...action.payload.productData },
        },
      };
    case getProductWithSlug.pending:
      return {
        ...state,

        action_getProductWithSlug: {
          ...state.action_getProductWithSlug,
          [action.payload.slug]: {
            loading: true,
          },
        },
      };
    case getProductWithSlug.success:
      return {
        ...state,

        visitedProducts: {
          ...state.visitedProducts,
          [action.payload.slug]: { ...action.payload.productData },
        },

        action_getProductWithSlug: {
          ...state.action_getProductWithSlug,
          [action.payload.slug]: {
            loading: false,
          },
        },
      };

    case getRelatedProducts.success:
      return {
        ...state,
        relatedProducts: {
          ...state.relatedProducts,
          itemsByProduct: {
            ...state.relatedProducts.itemsByProduct,
            [action.payload.slug]: [...action.payload.products],
          },
        },
      };

    default:
      return { ...state };
  }
}
