export default function Price({ product, size = 1 }) {
  const discounted = product.sale_price != (null | undefined) ? true : false;

  return (
    <div
      style={{
        // display: "flex",
        // justifyContent: "space-between",
        padding: "5px",
      }}
      className=""
    >
      <div className="d-flex align-items-center justify-content-center">
        <p
          className="text"
          style={{ color: "black", fontSize: `${size * 1.5}rem` }}
        >
          &#2547;
        </p>
        <p
          className="text"
          style={{
            color: "black",
            fontSize: `${size * 1.2}rem`,
            fontWeight: "600",
          }}
        >
          {discounted ? product.sale_price : product.regular_price}
        </p>
        {discounted && (
          <p
            className="text m-1"
            style={{
              // color: "blue",
              color: "red",
              fontSize: "80%",
              textDecoration: "line-through",
              fontSize: `${size}rem`,
            }}
          >
            {product.regular_price}
          </p>
        )}
      </div>
    </div>
  );
}
