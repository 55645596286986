export default function versionChange() {
  const currentAppVersion = require("../../package.json").version;
  const stateAppVersion = localStorage.getItem("appVersion");

  if (currentAppVersion != stateAppVersion) {
    localStorage.removeItem("Token");
    localStorage.removeItem("UserInfo");
    localStorage.removeItem("appVersion");
    localStorage.removeItem("store.categories");
    localStorage.removeItem("store.homePage");
    localStorage.removeItem("store.order");
    localStorage.removeItem("store.user");
    localStorage.removeItem("store__categoryPage");
    localStorage.removeItem("store__searchProducts");

    localStorage.setItem("appVersion", currentAppVersion);
  }
}
