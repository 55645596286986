import axios from "axios";

import {
  checkCouponCompatibility,
  getCouponCheckedCartList,
  checkCouponValidity,
} from "./coupon";
export {
  checkCouponCompatibility,
  getCouponCheckedCartList,
  checkCouponValidity,
} from "./coupon";

export const fetchShippingData = async () => {
  const sheetId = "1TDecL6xn2CiRGmvX758SvdDl2ieYv8YPTVmP2qn_yLI";
  const base = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?`;
  const sheetName = "shipping-area-districts";
  const query = encodeURIComponent("Select *");
  const url = `${base}&sheet=${sheetName}&tq=${query}`;

  const res = await axios.get(url);

  // fs.appendFile("fuck", res.data);

  const colz = [];
  const data = [];
  const jsonData = JSON.parse(res.data.split("setResponse")[1].slice(1, -2));

  jsonData.table.cols.forEach((heading) => {
    if (heading.label) {
      let column = heading.label;
      colz.push(column);
    }
  });
  jsonData.table.rows.forEach((rowData) => {
    const row = {};
    colz.forEach((ele, ind) => {
      row[ele] = rowData.c[ind] != null ? rowData.c[ind].v : "";
    });
    data.push(row);
  });
  return data;
};

export const arrayToObj = (arr, key) => {
  const obj = {};

  arr.forEach((e) => {
    if (e.id) {
      obj[e[key]] = { ...e };
    }
  });
  return obj;
};

export const getGrossTotal = (state) => {
  // console.log("--getGrossTotal--");
  const newCartItems = {
    ...getCouponCheckedCartList(
      state.coupon.allCoupons[state.coupon.selectedCouponCode],
      state.cartItems
    ),
  };

  let total = 0;

  const cartObjs = Object.values(newCartItems);
  cartObjs.forEach((obj) => {
    total = total + obj.grossTotal;
  });

  // const shippingCost = state.shippingAddress.allAddresses[
  //   state.shippingAddress.selectedAddressId
  // ]
  //   ? state.shippingAddress.allAddresses[
  //       state.shippingAddress.selectedAddressId
  //     ].flat_charge
  //   : 0;

  return total;
};

export const getNetTotal = (state) => {
  // console.log("--getGrossTotal--");

  const selectedCoupon =
    state.coupon.allCoupons[state.coupon.selectedCouponCode];

  const newCartItems = {
    ...getCouponCheckedCartList(selectedCoupon, state.cartItems),
  };

  const shippingCost = state.shippingAddress.allAddresses[
    state.shippingAddress.selectedAddressId
  ]
    ? state.shippingAddress.allAddresses[
        state.shippingAddress.selectedAddressId
      ].deliveryCharge
    : 0;

  let total = 0;
  const cartObjs = Object.values(newCartItems);
  cartObjs.forEach((obj) => {
    total = total + obj.netTotal;
  });
  if (
    selectedCoupon &&
    selectedCoupon.couponData.discount_type == "percentage"
  ) {
    // logic for percentage was written in the module utils
  } else if (
    selectedCoupon &&
    selectedCoupon.couponData.discount_type == "fixed_cart" &&
    !selectedCoupon.couponData.free_shipping
  ) {
    const amount = selectedCoupon.couponData.amount;
    total = total > amount ? total - amount : 0;
  }

  total = total + shippingCost;

  return total;
};

export const getShippingCost = (state) => {
  return state.shippingAddress.allAddresses[
    state.shippingAddress.selectedAddressId
  ]
    ? state.shippingAddress.allAddresses[
        state.shippingAddress.selectedAddressId
      ].deliveryCharge
    : 0;
};

export const calculateTotalCouponDiscount = (state) => {
  // console.log("-calculateTotalCouponDiscount-");
  const selectedCoupon =
    state.coupon.allCoupons[state.coupon.selectedCouponCode];

  const cartItems = getCouponCheckedCartList(
    state.coupon.allCoupons[state.coupon.selectedCouponCode],
    state.cartItems
  );

  if (!selectedCoupon) {
    // console.log("no coupon");
    return 0;
  } else if (selectedCoupon.couponData.discount_type == "percent") {
    // console.log("percent");
    // console.log(cartItems);
    let total = 0;
    Object.values(cartItems).forEach((e) => {
      total = total + Number(e.totalCouponDiscount | 0);
    });
    return total;
  } else if (selectedCoupon.couponData.discount_type == "fixed_cart") {
    // console.log("fixed_cart");
    if (selectedCoupon.couponData.free_shipping) {
      return getShippingCost(state);
    } else {
      // make another coupon checked cart list

      let totalCostOfCouponApliedProducts = 0;
      Object.values(state.cartItems).forEach((item) => {
        if (item.isCouponApplied) {
          totalCostOfCouponApliedProducts =
            totalCostOfCouponApliedProducts + item.netTotal;
        }
      });

      // discount according to amount
      const amount = Number(selectedCoupon.couponData.amount);
      // const grossTotal = getGrossTotal(state);

      return amount < totalCostOfCouponApliedProducts
        ? amount
        : totalCostOfCouponApliedProducts;
    }
  }
};

export const calculateTotalSaleDiscount = (state) => {
  // console.log("-calculateTotalCouponDiscount-");
  const selectedCoupon =
    state.coupon.allCoupons[state.coupon.selectedCouponCode];

  const cartItems = getCouponCheckedCartList(
    state.coupon.allCoupons[state.coupon.selectedCouponCode],
    state.cartItems
  );

  let total = 0;
  Object.values(cartItems).forEach((e) => {
    total = total + Number(e.totalSaleDiscount | 0);
  });
  return total;
};

export const calculateCharges = (state) => {
  // console.log("-calculateCharges-");
  // console.log({
  //   calculateTotalCouponDiscount: calculateTotalCouponDiscount(state),
  // });

  return {
    totalSaleDiscount: 0,
    grossTotal: getGrossTotal(state),
    totalCouponDiscount: calculateTotalCouponDiscount(state), // ---
    totalSaleDiscount: calculateTotalSaleDiscount(state),
    shippingCharge: getShippingCost(state),
    netTotal: getNetTotal(state), // ---
  };
};

export const discountCheckedCouponItems = (state) => {
  // console.log("--discountCheckedCouponItems--");

  const newCouponItems = {};

  const couponItemsObj = state.coupon.allCoupons;

  Object.values(couponItemsObj).forEach((couponItem) => {
    let newState = { ...state };
    newState.coupon.selectedCouponCode = couponItem.couponData.code;
    newState = {
      ...state,
      charges: calculateCharges(state),
      cartItems: {
        ...getCouponCheckedCartList(
          state.coupon.allCoupons[state.coupon.selectedCouponCode],
          state.cartItems
        ),
      },
    };
    const totalDiscount = calculateTotalCouponDiscount(newState);

    newCouponItems[couponItem.couponData.code] = {
      ...couponItem,
      totalDiscount,
    };
  });
  return { ...newCouponItems };
};

export const calculateCartQuantity = (cartItemObj) => {
  const cartItems = Object.values(cartItemObj);
  let quantity = 0;
  cartItems.forEach((e) => {
    quantity += e.quantity;
  });
  return quantity;
};

export default {
  arrayToObj,
  fetchShippingData,
  getGrossTotal,
  getNetTotal,
  getCouponCheckedCartList,
  checkCouponCompatibility,
  checkCouponValidity,
  getShippingCost,
  calculateTotalCouponDiscount,
  discountCheckedCouponItems,
  calculateCharges,
  calculateCartQuantity,
};
