export default function getTimeInGMT(time, gmtOffsetHours = 6) {
  // Create a new Date object
  // const date = new Date();
  const date = new Date(time);

  // Get UTC time in milliseconds and adjust for the offset
  const utc = date.getTime() + date.getTimezoneOffset() * 60000;

  // Create a new date object for the specified GMT offset
  const gmtDate = new Date(utc + 3600000 * gmtOffsetHours);

  // Format the date and time as "YYYY-MMM-DD HH:mm"
  const formattedDate = gmtDate.toLocaleString("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    // hour12: false,
    hour12: true,
  });
  // console.log({ formattedDate });

  // Return the formatted date
  // return formattedDate.replace(",", "").replace(/ /g, "-").toLowerCase();
  return formattedDate;
}
