import React, { useState, useRef, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
// import "./styles.css";
import s from "./index.module.css";
import Policies from "../Profile/Orders/OrerItem/Policies";
function StickyTab({ product }) {
  const [sticky, setSticky] = useState(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const stickyTabRef = useRef(null);

  useEffect(() => {
    const mainHeader = document.querySelector("#header");
    // console.log(mainHeader?.offsetHeight);

    // scroll to top when tab changed
    let tabButtons = document
      .querySelector("#sticky-tab")
      .querySelectorAll("li.nav-item");

    tabButtons = [...tabButtons];
    tabButtons = tabButtons.map((li) => li.querySelector("button"));
    console.log(tabButtons);

    const scrollOffset =
      stickyTabRef.current.offsetTop - mainHeader.offsetHeight;
    console.log({
      mainHeader_offsetHeight: mainHeader.offsetHeight,
      stickyTabRef_offsetTop: stickyTabRef.current.offsetTop,
    });
    tabButtons.forEach((b) => {
      b.addEventListener("click", () => {
        if (stickyTabRef.current) {
          window.scrollTo({
            top: scrollOffset,
            // top: scrollOffset,
            behavior: "smooth",
          });
        }
      });
    });
    // stickey tab top margin adjust with the header height

    const stickyTabElement = document
      .querySelector(".product-page")
      ?.querySelector("ul.nav.nav-tabs");

    console.log(stickyTabElement.style);

    stickyTabElement.style.top = mainHeader?.offsetHeight - 1 + "px";
    // stickyTabElement.style.border = "1px solid red";

    // keep sticky tab sticky until at the end of the tab body
    const handleScroll = (e) => {
      // console.log(e);
      if (ref1.current && ref2.current) {
        const { top: top1 } = ref1.current.getBoundingClientRect();
        const { top: top2 } = ref2.current.getBoundingClientRect();
        if (top1 <= 0 && top2 >= 0) {
          setSticky(true);
        } else {
          setSticky(false);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useEffect(() => {}, []);

  return (
    <div id="sticky-tab" className="sticky-tab pt-3" ref={stickyTabRef}>
      <Tabs defaultActiveKey="tab-description" className="text small py-4">
        <Tab
          eventKey="tab-description"
          title="Description"
          ref={ref1}
          className={sticky ? "sticky" : ""}
        >
          {/* <h2 className="h2 mt-3">Description</h2> */}
          <div
            className={s.dangerouslySetInnerHTML}
            style={{ maxWidth: "100%" }}
            dangerouslySetInnerHTML={{
              __html: product.description,
            }}
          ></div>
        </Tab>
        <Tab eventKey="tab-deliver-info" title="Delivery Process" ref={ref2}>
          <div>1 Delivery Informatino bla bla bla</div>
          <div>2 Delivery Informatino bla bla bla</div>
          <div>3 Delivery Informatino bla bla bla</div>
          <div>4 Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
          <div>Delivery Informatino bla bla bla</div>
        </Tab>
        <Tab eventKey="tab-return" title="Return Plicy">
          <Policies />
        </Tab>
      </Tabs>
    </div>
  );
}

export default StickyTab;
