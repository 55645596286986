import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import * as actions from "../../../store/actions";
import * as selectors from "../../../store/selectors";
import StickeyTab from "./StickyTab";
import { CartButton } from "../../common/Cart";
import Loading from "../../common/Loading";
import Category from "../../common/Category";
import ProductComponents from "../../common/Product";
import ProductItem from "../../common/ProductItem";
import ProductContainer from "../../common/ProductContainer";
import pageTransition from "../pageTranstion";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import RattingStart from "./RattingStart";
import ShereLinkWithSocalMediaIcons from "./ShereLinkWithSocalMediaIcons";
import { TbTruckDelivery } from "react-icons/tb";
import { GiCardboardBoxClosed } from "react-icons/gi";
import ProductImage from "./ProductImage";

function Product() {
  const productUrl = window.location.href;
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeTab, setActiveTab] = useState("tab-description");

  const allCategories = useSelector(
    (state) => state.categories.allCategories.data
  );
  const slug = useParams().slug;
  const loading = useSelector(
    (store) => store.productPage.action_getProductWithSlug[slug]?.loading
  );

  const currentProduct = selectors.productPage.getProductWithSlug(
    useSelector((e) => e),
    slug
  );
  const relatedProducts = useSelector(
    (state) =>
      state.productPage.relatedProducts.itemsByProduct[currentProduct?.slug]
  );
  const addedToCart = useSelector(
    (state) => state.order.cartItems[slug]?.quantity > 0
  );

  const id = currentProduct?.id;

  useEffect(() => {
    if (currentProduct) {
      dispatch(
        actions.productPage.getRelatedProducts(
          currentProduct.related_ids,
          currentProduct.slug
        )
      );
    } else {
      if (!loading && slug) {
        dispatch(actions.productPage.getProductWithSlug(slug));
      }
    }
  }, [currentProduct, slug]);

  // console.log({ selectedProductImg });

  if (!currentProduct && loading) {
    return (
      <div className="container">
        <Loading size="100px" />
      </div>
    );
  }

  return (
    <div className="mx-auto flex justify-center m-3 product-page">
      {currentProduct && (
        <div>
          <ScrollToTop />

          <div className="flex flex-col md:flex-row lg:flex-row gap-3">
            <div
              className={`md:w-1/2 bg-gray-100 rounded-lg shadow-lg  flex flex-col-reverse lg:flex-row lg:max-h-[600px]`}
              style={
                {
                  // maxHeight: "700px",
                }
              }
            >
              <div
                className="gap-2 scrollBar overflow-y-scroll BB flex flex-row lg:block"
                style={{ maxHeight: "100%" }}
              >
                {currentProduct?.images?.map((imageObj) => (
                  <ProductImage
                    imageObj={imageObj}
                    onSelectImage={() => setSelectedImage(imageObj)}
                  />
                ))}
                {currentProduct?.images?.map((imageObj) => (
                  <ProductImage
                    imageObj={imageObj}
                    onSelectImage={() => setSelectedImage(imageObj)}
                  />
                ))}
                {currentProduct?.images?.map((imageObj) => (
                  <ProductImage
                    imageObj={imageObj}
                    onSelectImage={() => setSelectedImage(imageObj)}
                  />
                ))}
              </div>
              <div className="BB flex-1">
                <img
                  ref={selectedImage}
                  src={selectedImage?.src || currentProduct?.images[0]?.src}
                  alt="Selected Product"
                  className="object-cover rounded-lg w-full"
                  style={{ aspectRatio: "1/1" }}
                  id="selectedProductImg"
                />
              </div>
            </div>

            <div className="w-full md:w-1/2 bg-white p-6 rounded-lg shadow-lg ">
              <h2 className="text-2xl font-bold mb-4">{currentProduct.name}</h2>
              {currentProduct.short_description && (
                <div
                  className="text-gray-700 text-lg mb-4"
                  dangerouslySetInnerHTML={{
                    __html: currentProduct.short_description,
                  }}
                ></div>
              )}
              <div className="mb-3">
                <div className="text-xl font-semibold mb-2">
                  price:{" "}
                  {currentProduct.regular_price && (
                    <span className="text-theme line-through">
                      {currentProduct.regular_price} TK
                    </span>
                  )}
                  <span className="text-theme px-2">
                    {currentProduct.price} TK
                  </span>
                </div>
              </div>
              <div className="mb-3">
                <label className="text-xl font-medium">Categories:</label>
                <div className="d-flex flex-wrap">
                  {currentProduct?.categories
                    ?.map((e) =>
                      allCategories?.find((catObj) => catObj.id == e.id)
                    )
                    ?.map((cat) => (
                      <Category.Item
                        category={cat}
                        key={cat.id}
                      ></Category.Item>
                    ))}
                </div>
              </div>
              {/* <RattingStart rating={"3.00"} aveRating={3.5} /> */}
              <RattingStart
                rating={currentProduct.rating_count}
                aveRating={currentProduct.average_rating}
              />
              <div className="flex items-center space-x-4 flex-coll ">
                <CartButton
                  product={currentProduct}
                  remove={{ label: "x" }}
                  increment={{ label: "+" }}
                  reduction={{ label: "-" }}
                  textSize="1.1"
                  disabled={currentProduct.stock_status == "outofstock"}
                  className="w-full py-3 px-4 text-xl"
                />
                <button
                  onClick={() => {
                    if (!addedToCart) {
                      dispatch(
                        actions.order.addToCart(id, slug, currentProduct)
                      );
                    }

                    dispatch(actions.nabvar.showCartModal());
                  }}
                  style={{ borderRadius: "4px" }}
                  disabled={currentProduct.stock_status == "outofstock"}
                  className="bg-black px-6 py-3 rounded-sm border text-white hover:bg-gray-200 w-full m-3 text-xl"
                >
                  Buy Now
                </button>
              </div>

              <hr />
              <div className="flex justify-between text-lg mb-4">
                <div className="flex items-center gap-1">
                  <p>
                    <FaRegHeart />
                  </p>
                  {/* <p>
                    <FaHeart />
                  </p> */}
                  <p>Add to wishlist</p>
                </div>
                <div className="flex gap-2 items-center">
                  <ShereLinkWithSocalMediaIcons productUrl={productUrl} />
                </div>
              </div>
              <ABC />
            </div>
          </div>
          <div className="container">
            <StickeyTab product={currentProduct} />

            <h2 className="h1 my-3">Related Products</h2>
            <ProductContainer className="">
              {relatedProducts?.map((product, i) => (
                <ProductItem product={product} key={i} />
              ))}
            </ProductContainer>
          </div>
        </div>
      )}
    </div>
  );
}

const ABC = () => {
  return (
    <div className="mb-4 w-full flex flex-wrap justify-between">
      <div className="w-full sm:w-1/2 md:w-1/4 h-auto p-2 text-center flex flex-col items-center">
        <h3 className="font-semibold text-lg text-theme">100%</h3>
        <h5 className="text-sm">100% Authentic</h5>
        <p className="text-xs">
          Ensuring you only get Genuine & original items.
        </p>
      </div>
      <div className="w-full sm:w-1/2 md:w-1/4 h-auto p-2 text-center flex flex-col items-center">
        <div className="font-semibold text-4xl text-theme">
          <TbTruckDelivery />
        </div>

        <h5 className="text-sm">QC Passed</h5>
        <p className="text-xs">
          Strict Quality Control Guaranteed in each product.
        </p>
      </div>
      <div className="w-full sm:w-1/2 md:w-1/4 h-auto p-2 text-center flex flex-col items-center">
        <div className="font-semibold text-4xl text-theme">
          <TbTruckDelivery />
        </div>

        <h5 className="text-sm">Fast Delivery</h5>
        <p className="text-xs">Inside Dhaka 1-2 days</p>
        <p className="text-xs">Outside Dhaka 2-3 days</p>
      </div>
      <div className="w-full sm:w-1/2 md:w-1/4 h-auto p-2 text-center flex flex-col items-center">
        <div className="font-semibold text-4xl text-theme">
          <GiCardboardBoxClosed />
        </div>

        <h5 className="text-sm">Easy Return</h5>
        <p className="text-xs">Hassle-free and seamless return process</p>
      </div>
    </div>
  );
};

export default pageTransition(Product);
