const moduleName = "searchProducts";

export const selectFilter = `${moduleName}/selectFilter`;
export const selectOrder = `${moduleName}/selectOrder`;
export const selectPage = `${moduleName}/selectPage`;

export const search = {
  pending: `${moduleName}/search/pending`,
  success: `${moduleName}/search/success`,
  fail: `${moduleName}/search/fail`,
};
