import React from "react";
import { params } from "./../../../utils";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Brands from "./Brands";
import Categories from "./Categories";
import RecentlyVisited from "./RecentlyVisited";
const params___ = {
  categories: Categories,
};

const config = {
  categories: {
    Element: <Categories />,
    isActive: false,
    name: "categories",
  },
  // brands: {
  //   Element: <Brands />,
  //   isActive: false,
  //   name: "brands",
  // },
  "visited-items": {
    Element: <RecentlyVisited />,
    isActive: false,
    name: "visited-items",
  },
};

export default function () {
  const { search: currentQueryString, pathname } = useLocation();
  const navPopupContainer = React.useRef(null);

  //   const activeElement = "categories";
  const [activeNavElement, setActiveNavElement] = React.useState("");

  React.useEffect(() => {
    setActiveNavElement(
      params.qStringToObj(currentQueryString).selected_nav_popup_element
    );

    if (
      Object.keys(config).includes(
        params.qStringToObj(currentQueryString).selected_nav_popup_element
      )
    ) {
      //
      navPopupContainer.current.style.setProperty("height", "400px");
    } else {
      navPopupContainer.current.style.setProperty("height", "0px");
    }
  }, [currentQueryString]);
  return (
    <div className="pt-1" style={{ border: "1px solid dark" }}>
      <div
        id="nav-popup"
        className="nav-popup scrollBar border rounded shadow"
        ref={navPopupContainer}
        style={{
          overflowX: "hidden",
          overflowY: "scroll",
          // border: "1px solid red",
        }}
      >
        {config[activeNavElement]?.Element}
      </div>
    </div>
  );
}
