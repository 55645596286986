const baseName = "categories";

export const fetchAllCategories = {
  pending: `${baseName}/fetchAllCategories/pending`,
  success: `${baseName}/fetchAllCategories/success`,
  fail: `${baseName}/fetchAllCategories/fail`,
};

export const getOrderedCategories = {
  pending: `${baseName}/getOrderedCategories/pending`,
  success: `${baseName}/getOrderedCategories/success`,
  fail: `${baseName}/getOrderedCategories/fail`,
};

export default { fetchAllCategories, getOrderedCategories };
